/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as organisationsApi from '../../api/organisations';

export const fetchOrganisations = createAsyncThunk(
  'organisations/fetchOrganisations',
  async (params = {}) => {
    const response = await organisationsApi.getOrganisationList(params);
    return response.data;
  },
);

export const fetchOrganisationById = createAsyncThunk(
  'organisations/fetchOrganisation',
  async (params = {}) => {
    const response = await organisationsApi.getOrganisation(params);
    return response.data;
  },
);

export const organisationsAdapter = createEntityAdapter();

export const organisationsSlice = createSlice({
  name: 'organisations',
  initialState: organisationsAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {
    updateOneOrganisation: organisationsAdapter.setOne,
    deleteOneOrganisation: organisationsAdapter.removeOne,
    deleteManyOrganisation: organisationsAdapter.removeMany,
    updateManyOrganisations: organisationsAdapter.setMany,
    upsertManyOrganisations: organisationsAdapter.upsertMany,
  },
  extraReducers: {
    [fetchOrganisations.pending](state) {
      state.pending = true;
    },
    [fetchOrganisations.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      organisationsAdapter.setAll(state, action.payload.organisations);
    },
    [fetchOrganisations.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [fetchOrganisationById.pending](state) {
      state.pending = true;
    },
    [fetchOrganisationById.fulfilled](state, action) {
      state.pending = false;
      organisationsAdapter.setOne(state, action.payload.organisation);
    },
    [fetchOrganisationById.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const {
  updateOneOrganisation,
  updateManyOrganisations,
  upsertManyOrganisations,
  deleteOneOrganisation,
  deleteManyOrganisation,
} = organisationsSlice.actions;

export const organisationsSelectors = organisationsAdapter.getSelectors();

export default organisationsSlice.reducer;
