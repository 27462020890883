import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HStack, Input, StackItem } from '@chakra-ui/react';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'qs';
import { unwrapResult } from '@reduxjs/toolkit';
import { DataTable } from '../../../common/components/DataTable';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { columns } from './columns';
import { fetchPeople, peopleSelectors } from '../peopleSlice';
import { useBulkActions } from './useBulkActions.hook';
import { categoriesSelectors, fetchCategories } from '../../categories/categoriesSlice';
import Select from '../../../common/components/Select';
import { capitalizeFirstLetter } from '../../../common/utils';
import { Metadata } from '../../../Metadata';

const initialValues = {
  page: 1,
  perPage: 50,
};

export const PeopleIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const {
    meta: { total },
    pending,
  } = useSelector((state) => state.people);
  const people = useSelector((state) => peopleSelectors.selectAll(state.people));

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location.search],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters,
  });

  const updatePersonsList = () => {
    return dispatch(fetchPeople(filters)).then(unwrapResult);
  };

  const [
    toggledClearRows,
    ContextActionsMenu,
    DeduplicationModal,
    CreationModal,
    bulkActionsHandlers,
  ] = useBulkActions([], { updatePersonsList });

  const categories = useSelector((state) => {
    return categoriesSelectors.selectAll(state.categories);
  });

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPeople(filters));
  }, [filters, dispatch]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true }),
    });
  }, [filters, history, location.pathname]);

  const debounced = useDebouncedCallback((value) => {
    setFilters(value);
  }, 500);

  const handlePageChange = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setFilters({ ...filters, perPage: newRowsPerPage });
  };

  const handleCategoryChange = (newCategory) => {
    setFilters({
      ...filters,
      category: newCategory.map((cat) => cat.value).join(','),
    });
  };

  const handleTextFiltering = (event) => {
    debounced({ ...filters, query: event.target.value, page: 1 });
  };

  const handleSelectedRowsChange = ({ selectedRows: rows }) => {
    bulkActionsHandlers.setSelectedRows(rows);
  };

  const memoizedColumns = useMemo(
    () => columns({ handlers: bulkActionsHandlers, location }),
    [bulkActionsHandlers, location],
  );

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Personnes</PageTitle>
        <Metadata titleSuffix="Personnes" descriptionSuffix="Liste des presonnes référencées" />
      </PageHeader>
      <PageBody p="page.gutter">
        <HStack align="stretch" spacing={8} mb={5}>
          <Input
            placeholder="Rechercher"
            defaultValue={filters.query}
            onInput={handleTextFiltering}
            width="auto"
            flexGrow="1"
          />
          <StackItem flex="0 0 calc(50% - 2rem)">
            <Select
              isMulti
              colorScheme="cyan"
              variant="solid"
              options={categories} // Options to display in the dropdown
              value={categories.filter(
                (cat) => filters.category?.split(',').indexOf(cat.value) >= 0,
              )} // Preselected value to persist in dropdown
              getOptionLabel={(option) => capitalizeFirstLetter(option.label)}
              onChange={handleCategoryChange} // Function will trigger on select event
              placeholder="Filtrer par catégorie"
            />
          </StackItem>
        </HStack>
        <DataTable
          subHeader
          subHeaderComponent={ContextActionsMenu}
          columns={memoizedColumns}
          data={people}
          selectableRows
          pagination
          paginationDefaultPage={Number(filters.page)}
          paginationPerPage={filters.perPage}
          paginationServer
          paginationTotalRows={total}
          progressPending={pending}
          onSelectedRowsChange={handleSelectedRowsChange}
          clearSelectedRows={toggledClearRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onRowClicked={(user) =>
            history.push(`${match.url} /${user.id}`, {
              from: location.pathname,
              search: qs.stringify(filters, { skipNulls: true }),
            })
          }
        />
        <DeduplicationModal />
        {CreationModal}
      </PageBody>
    </PageContainer>
  );
};
