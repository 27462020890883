/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as categoriesAPI from '../../api/categories';

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  const response = await categoriesAPI.getCategories();
  return response.data.categories;
});

export const categoriesAdapter = createEntityAdapter();

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {},
  extraReducers: {
    [fetchCategories.pending](state) {
      state.pending = true;
    },
    [fetchCategories.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      categoriesAdapter.setAll(state, action.payload);
    },
    [fetchCategories.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const categoriesSelectors = categoriesAdapter.getSelectors();

export default categoriesSlice.reducer;
