import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TiEqualsOutline } from 'react-icons/all';
import {
  Badge,
  Heading,
  HStack,
  Link,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  VStack,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { Metadata } from '../../../Metadata';
import { routes } from '../../../common/routes/routes';
import { fetchStreams, streamsSelectors } from '../streamsSlice';

const tabName = 'Flux de données';
export const StreamsIndex = () => {
  const dispatch = useDispatch();
  const streams = useSelector((state) => streamsSelectors.selectAll(state.streams));

  useEffect(() => {
    dispatch(fetchStreams());
  }, [dispatch]);
  useEffect(() => {
    console.log(streams);
  }, [streams]);

  const lastDirectoryStructureSync = useMemo(
    () => streams.filter((s) => s.streamType === 'directory_structures').slice(0, 1)[0],
    [streams],
  );
  const previousDirectoryStructureSync = useMemo(
    () => streams.filter((s) => s.streamType === 'directory_structures').slice(1, 2)[0],
    [streams],
  );

  const lastDirectorySync = useMemo(() => streams.slice(0, 1)[0], [streams]);

  const lastDirectoryPeopleSync = useMemo(
    () => streams.filter((s) => s.streamType === 'directory_people').slice(0, 1)[0],
    [streams],
  );
  const lastHalPublicationsSync = useMemo(
    () => streams.filter((s) => s.streamType === 'hal_publications').slice(0, 1)[0],
    [streams],
  );
  const previousDirectoryPeopleSync = useMemo(
    () => streams.filter((s) => s.streamType === 'directory_people').slice(1, 2)[0],
    [streams],
  );
  const previousHalPublicationsSync = useMemo(
    () => streams.filter((s) => s.streamType === 'hal_publications').slice(1, 2)[0],
    [streams],
  );
  const directoryPeopleSyncConflictDelta = useMemo(
    () =>
      lastDirectoryPeopleSync
        ? parseInt(lastDirectoryPeopleSync?.conflicted, 10) -
          parseInt(previousDirectoryPeopleSync?.conflicted, 10)
        : 0,
    [streams],
  );
  const halPublicationsSyncDelta = useMemo(
    () =>
      lastHalPublicationsSync
        ? parseInt(lastHalPublicationsSync?.processed, 10) -
          parseInt(previousHalPublicationsSync?.processed, 10)
        : 0,
    [streams],
  );
  const directoryStructuresSyncConflictDelta = useMemo(
    () =>
      lastDirectoryStructureSync
        ? parseInt(lastDirectoryStructureSync?.conflicted, 10) -
          parseInt(previousDirectoryStructureSync?.conflicted, 10)
        : 0,
    [streams],
  );

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>{tabName}</PageTitle>
      </PageHeader>
      <Metadata titleSuffix={tabName} descriptionSuffix="Flux de données" />
      <PageBody p="page.gutter">
        <VStack>
          <VStack alignItems="start" width="100%">
            <Link
              as={NavLink}
              to={`${routes.streams}/directory`}
              d="flex"
              alignItems="center"
              px={2}
              py={2}
              w="100%"
              borderRadius="md"
              fontSize="sm"
              borderColor="gray.200"
              borderWidth="thin"
              borderStyle="solid"
              _hover={{ textDecoration: 'none', bgColor: 'blackAlpha.300' }}
            >
              <HStack mr={5}>
                <Heading mr={2} w={100}>
                  LDAP
                </Heading>
                <Badge color="gray" fontSize="xs">
                  Dernière synchronisation : {lastDirectorySync?.startDate}
                </Badge>
              </HStack>
              <StatGroup flexDir="row" width="50%">
                {lastDirectoryStructureSync && (
                  <Stat flexGrow={2}>
                    <StatLabel>Structures</StatLabel>
                    <StatNumber>{lastDirectoryStructureSync.processed}</StatNumber>
                    <StatHelpText>
                      {directoryStructuresSyncConflictDelta > 0 && (
                        <StatArrow type="increase" color="red" />
                      )}
                      {directoryStructuresSyncConflictDelta < 0 && (
                        <StatArrow type="decrease" color="green" />
                      )}
                      {directoryStructuresSyncConflictDelta === 0 && (
                        <TiEqualsOutline style={{ display: 'inline', verticalAlign: 'middle' }} />
                      )}
                      Écarts : {lastDirectoryStructureSync.conflicted}
                    </StatHelpText>
                  </Stat>
                )}

                <Stat flexGrow={2}>
                  <StatLabel>Personnes</StatLabel>
                  <StatNumber>{lastDirectoryPeopleSync?.processed}</StatNumber>
                  <StatHelpText>
                    {directoryPeopleSyncConflictDelta > 0 && (
                      <StatArrow type="increase" color="red" />
                    )}
                    {directoryPeopleSyncConflictDelta < 0 && (
                      <StatArrow type="decrease" color="green" />
                    )}
                    {directoryPeopleSyncConflictDelta === 0 && (
                      <TiEqualsOutline style={{ display: 'inline', verticalAlign: 'middle' }} />
                    )}
                    Écarts : {lastDirectoryPeopleSync?.conflicted}
                  </StatHelpText>
                </Stat>
              </StatGroup>
            </Link>
            <Link
              as={NavLink}
              to={`${routes.streams}/publications`}
              d="flex"
              alignItems="center"
              px={2}
              py={2}
              w="100%"
              borderRadius="md"
              fontSize="sm"
              borderColor="gray.200"
              borderWidth="thin"
              borderStyle="solid"
              _hover={{ textDecoration: 'none', bgColor: 'blackAlpha.300' }}
            >
              <HStack mr={5}>
                <Heading mr={2} w={100}>
                  HAL
                </Heading>
                <Badge color="gray" fontSize="xs">
                  Dernière synchronisation : {lastDirectorySync?.startDate}
                </Badge>
              </HStack>
              <StatGroup flexDir="row" width="50%">
                <Stat flexGrow={2}>
                  <StatLabel>Publications</StatLabel>
                  <StatNumber>{lastHalPublicationsSync?.processed}</StatNumber>
                  <StatHelpText>
                    {halPublicationsSyncDelta > 0 && <StatArrow type="increase" color="red" />}
                    {halPublicationsSyncDelta < 0 && <StatArrow type="decrease" color="green" />}
                    {halPublicationsSyncDelta === 0 && (
                      <TiEqualsOutline style={{ display: 'inline', verticalAlign: 'middle' }} />
                    )}
                    Total : {lastHalPublicationsSync?.processed}
                  </StatHelpText>
                </Stat>
              </StatGroup>
            </Link>
          </VStack>
        </VStack>
      </PageBody>
    </PageContainer>
  );
};
