export const routes = {
  home: '/',
  login: '/login',
  logout: '/logout',
  organisations: '/organisations',
  people: '/people',
  contracts: '/contracts',
  instances: '/instances',
  users: '/users',
  extractions: '/extractions',
  messages: '/messages',
  streams: '/streams',
  profile: '/profile',
  informations: '/mentions-legales',
};
