import React from 'react';
import {
  Avatar,
  Button,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import { routes } from '../../../common/routes/routes';
import { capitalizeFirstLetter } from '../../../common/utils';
import { ConfirmDialog } from '../../../common/components/ConfirmDialog';

function displayFirstFunction(person) {
  const relationships = person.functions?.filter((fn) => !fn.past && !fn.future);
  return relationships.length === 0
    ? ''
    : capitalizeFirstLetter(
        `${relationships[0].longTitleFr} ${relationships[0].institution.acronymFr}`,
      );
}

export const columns = ({ handlers, location, loading }) => [
  {
    name: 'Utilisateur',
    cell: (person) => {
      const firstFunction = displayFirstFunction(person);
      return (
        <div data-tag="allowRowEvents">
          <HStack align="stretch">
            <Avatar size="xs" src={`${process.env.REACT_APP_API_URL}/${person.photoPath}`} />
            <VStack spacing={1} align="flex-start" justify="center">
              <Link
                as={NavLink}
                to={{
                  pathname: `${routes.people}/${person.id}`,
                  state: { from: location.pathname, search: location.search },
                }}
              >
                {person.displayName}
              </Link>
              {firstFunction ? <Text as="small">{firstFunction}</Text> : null}
            </VStack>
          </HStack>
        </div>
      );
    },
    sortable: true,
  },
  {
    cell: (person) => (
      <>
        <Tag colorScheme={person.validated ? 'blue' : 'red'}>
          <TagLabel>{person.validated ? 'Validée' : 'Non validée'}</TagLabel>
        </Tag>
        {person.directoryAssociated && (
          <Tag colorScheme="green" ml={2}>
            <TagLabel>Annuaire</TagLabel>
          </Tag>
        )}
        {person.status === 'guest' && (
          <Tag colorScheme="gray" ml={2}>
            <TagLabel>Invité(e)</TagLabel>
          </Tag>
        )}
      </>
    ),
  },
  {
    name: <VisuallyHidden>Actions</VisuallyHidden>,
    right: true,
    cell: (person) => (
      <div data-tag="allowRowEvents">
        <Menu>
          <MenuButton as={Button} size="sm" rightIcon={<HiChevronDown />}>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handlers.handleClickValidation(!person.validated, person)}>
              {person.validated ? 'Invalider' : 'Valider'}
            </MenuItem>
            <ConfirmDialog
              control={<MenuItem>Supprimer</MenuItem>}
              actionText="Supprimer"
              headerText="Suppression d'une personne"
              mainText={`Voulez-vous vraiment supprimer "${person.displayName}" ?`}
              callback={(e, { onClose }) => handlers.handleClickDeletion(person).then(onClose)}
              isLoading={loading}
              loadingText="Suppression en cours"
            />
            <MenuDivider />
            <MenuItem>Exporter vers Vivo</MenuItem>
          </MenuList>
        </Menu>
      </div>
    ),
    sortable: true,
  },
];
