/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  description: '',
  type: null,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    400: (state) => state,
    401: (state, action) => {
      state.title = 'Authentification';
      state.description = 'Vous devez vous connecter pour continuer.';
      state.type = action.payload.status;
    },
    403: (state, action) => {
      state.title = 'Droits insuffisants';
      state.description = "Vous n'avez pas la permission d'effectuer cette action.";
      state.type = action.payload.status;
    },
    409: (state) => state,
    422: (state) => state,
    500: (state, action) => {
      state.title = 'Incident technique';
      state.description = 'Nous nous employons à corriger ce problème.';
      state.type = action.payload.status;
    },
    undefined: (state) => {
      state.title = 'Erreur';
      state.description = 'Accès aux données interrompu.';
      state.type = 'error';
    },
    clear: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.match(/^errors\/((?!400|401|403|409|422|500|undefined|clear).*)/gm),
      (state, action) => {
        state.title = 'Erreur';
        state.description = 'Une erreur est survenue.';
        state.type = action.payload.status;
      },
    );
  },
});

export const { clear: clearErrors } = errorsSlice.actions;

export default errorsSlice.reducer;
