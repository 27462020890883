/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as organisationsApi from '../../api/organisations';

export const fetchOrganisationsTree = createAsyncThunk(
  'organisations/fetchOrganisationsTree',
  async (params = {}) => {
    const modifiedParams = {
      ...params,
      page: 1,
      perPage: Number.MAX_SAFE_INTEGER,
      format: 'tree',
      short: true,
    };
    const response = await organisationsApi.getOrganisationList(modifiedParams);
    return response.data;
  },
);

export const organisationsTreeAdapter = createEntityAdapter();

export const organisationsTreeSlice = createSlice({
  name: 'organisationsTree',
  initialState: organisationsTreeAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {},
  extraReducers: {
    [fetchOrganisationsTree.pending](state) {
      state.pending = true;
    },
    [fetchOrganisationsTree.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      organisationsTreeAdapter.setAll(state, action.payload.organisations);
    },
    [fetchOrganisationsTree.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const organisationsTreeSelectors = organisationsTreeAdapter.getSelectors();

export default organisationsTreeSlice.reducer;
