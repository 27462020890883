import { toCamelCase } from '../../common/utils/string-utils';

const rules = {
  all: {
    dynamic: {
      'app:autoedit': ({ user, object: obj }) => {
        const isEditingNothing = !obj;
        const isEditingHimself = obj && user && obj?.id === user?.id;

        const hasUserPermission =
          user.roles.filter((role) => role.roleType.code === 'user').length > 0;
        const hasGuestPermission =
          user.roles.filter((role) => role.roleType.code === 'guest').length > 0;
        return (hasUserPermission || hasGuestPermission) && (isEditingHimself || isEditingNothing);
      },
      'app:use': ({ user }) => {
        // has a permission other than user
        return (
          user.roles.filter(
            (role) => role.roleType.code !== 'user' && role.roleType.code !== 'guest',
          ).length > 0
        );
      },
      'app:role': ({ user, role }) => {
        // has a specific permission
        return user.roles.map((r) => r.roleType.code).indexOf(role) >= 0;
      },
      'app:perimeter': ({ user, object }) => {
        // has a structure of the person he wants to edit in his perimeter
        const membershipStructures = object.memberships.map((m) => m.structure.id);
        const perimeterStructures = user.roles.map((r) => r.organisation?.id);
        return (
          membershipStructures.filter((struct) => perimeterStructures.includes(struct)).length > 0
        );
      },
      'command:exec': ({ code, user }) => {
        return (
          user.commandsPermissions &&
          Object.keys(user.commandsPermissions).indexOf(toCamelCase(code)) >= 0
        );
      },
    },
  },
};

export default rules;
