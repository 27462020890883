import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  StackItem,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';
import qs from 'qs';
import { GiBallPyramid, MdToday } from 'react-icons/all';
import { DataTable } from '../../../../../common/components/DataTable';
import { DetailGroupTitle } from '../../../../../common/components/Detail';
import { columns } from './columns';
import { createCommand } from '../../../../commands/commandsSlice';
import { commandSuccess } from '../../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../../common/utils/toasts-utils';
import { fetchOrganisationById } from '../../../organisationsSlice';
import { Metadata } from '../../../../../Metadata';
import { sortByKey } from '../../../../../common/utils/array-utils';

const initialValues = {
  page: 1,
  perPage: 25,
  query: '',
  state: 'actual',
  institution: 'all',
  indirect: true,
};

const tabName = 'Membres';

export const Members = ({ organisation }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const membershipsType = useMemo(
    () => (organisation.type === 'Structure' ? 'memberships' : 'functions'),
    [organisation],
  );

  const [filteredMembers, setFilteredMembers] = useState([]);

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location.search],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters,
  });

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true }),
    });

    let filtered = organisation[membershipsType]
      .filter(
        (relationship) =>
          relationship.userDisplayName &&
          relationship?.userDisplayName?.toLowerCase()?.includes(filters.query.toLowerCase()),
      )
      .filter((relationship) => {
        return !(filters.state === 'actual' && (relationship?.past || relationship.future));
      })
      .filter((relationship) => {
        return filters.indirect || relationship.structureId === organisation.id;
      });
    const deduplicated = {};
    filtered = JSON.parse(JSON.stringify(filtered));

    filtered.forEach((currentValue) => {
      const registered = deduplicated[currentValue.userId];
      if (registered) {
        if (new Date(currentValue.startDate) < new Date(registered.startDate)) {
          registered.startDate = currentValue.startDate;
        }
        if (new Date(currentValue.endDate) > new Date(registered.endDate)) {
          registered.endDate = currentValue.endDate;
        }
        registered.longTitleFr = [registered.longTitleFr, currentValue.longTitleFr].flat();
        registered.longTitleEn = [registered.longTitleEn, currentValue.longTitleEn].flat();
      }
      if (membershipsType === 'memberships') {
        if (registered) {
          if (currentValue.structure) {
            registered.structure = [registered.structure, currentValue.structure].flat();
            registered.membershipType = [
              registered.membershipType,
              currentValue.membershipType,
            ].flat();
          }
        } else {
          deduplicated[currentValue.user.id] = {
            ...currentValue,
            structure: Array.of(currentValue.structure),
            membershipType: Array.of(currentValue.membershipType),
            longTitleFr: Array.of(currentValue.longTitleFr),
            longTitleEn: Array.of(currentValue.longTitleEn),
          };
        }
      }
      if (membershipsType === 'functions') {
        if (registered) {
          registered.functionType = [registered.functionType, currentValue.functionType].flat();
        } else {
          deduplicated[currentValue.userId] = {
            ...currentValue,
            functionType: Array.of(currentValue.functionType),
            longTitleFr: Array.of(currentValue.longTitleFr),
            longTitleEn: Array.of(currentValue.longTitleEn),
          };
        }
      }
    });

    setFilteredMembers(sortByKey(Object.values(deduplicated), 'userDisplayName'));
  }, [filters, history, location.pathname, organisation, membershipsType]);

  const debounced = useDebouncedCallback((value) => {
    setFilters(value);
  }, 500);

  const handlePageChange = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setFilters({ ...filters, perPage: newRowsPerPage });
  };

  const handleTextFiltering = (event) => {
    debounced({ ...filters, query: event.target.value, page: 1 });
  };

  const handleRelationShipChange = (code, payload) => {
    return dispatch(
      createCommand({
        code,
        ...payload,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(fetchOrganisationById(organisation.id));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const memoizedColumns = useMemo(
    () =>
      columns({
        location,
        membershipsType,
        handleRelationShipChange,
        indirect: filters.indirect,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, membershipsType],
  );
  const conditionalRowStyles = [
    {
      when: (row) => row.past,
      style: {
        color: 'var(--chakra-colors-gray-900)',
        opacity: '0.5',
      },
    },
  ];

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${organisation.titleFr}`}
        descriptionSuffix={`Liste des membres de l'organisation ${organisation.titleFr}`}
      />
      <HStack spacing={5}>
        <DetailGroupTitle fontSize="2xl">{tabName}</DetailGroupTitle>
      </HStack>
      <HStack align="stretch" spacing={8} mt={5}>
        <Input
          placeholder="Rechercher"
          defaultValue={filters.query}
          onInput={handleTextFiltering}
          mb={5}
        />
        <StackItem pt={0} mb={0} flex="0 0 calc(5% - 1rem)">
          <Tooltip
            hasArrow
            label="Seulement les appartenances actuelles"
            bg="gray.600"
            placement="left-start"
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="state-filter" mb="0">
                <Icon as={MdToday} mb={1} mr={0} />
              </FormLabel>
              <Switch
                id="state-filter"
                isChecked={filters.state === 'actual'}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    state: e.target.checked ? 'actual' : 'all',
                  })
                }
              />
            </FormControl>
          </Tooltip>
        </StackItem>
        {organisation.type === 'Structure' && (
          <StackItem pt={0} mt={0} flex="0 0 calc(5% - 1rem)">
            <Tooltip
              hasArrow
              label="Inclure les appartenances aux sous-structures"
              bg="gray.600"
              placement="left-start"
            >
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="indirect-filter" mb="0">
                  <Icon as={GiBallPyramid} mb={1} mr={0} />
                </FormLabel>
                <Switch
                  id="indirect-filter"
                  isChecked={filters.indirect}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      indirect: e.target.checked,
                    });
                  }}
                />
              </FormControl>
            </Tooltip>
          </StackItem>
        )}
      </HStack>
      <DataTable
        subHeader
        columns={memoizedColumns}
        data={filteredMembers}
        pagination
        paginationDefaultPage={Number(filters.page)}
        paginationPerPage={filters.perPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={
          <Box p={8}>Aucun {membershipsType === 'functions' ? 'employé' : 'membre'}</Box>
        }
      />
    </>
  );
};
