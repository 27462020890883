import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import ReactCrop from 'react-image-crop';

export const PhotoCropModal = ({ isOpen, onClose, newPhoto, onSubmit }) => {
  const [crop, setCrop] = useState({
    aspect: 1,
  });
  const [uploadedImage, setUploadedImage] = useState();
  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} cropObject - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  const getCroppedImg = (image, cropObject, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropObject.width;
    canvas.height = cropObject.height;
    const ctx = canvas.getContext('2d');

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = cropObject.width * pixelRatio;
    canvas.height = cropObject.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      cropObject.x * scaleX,
      cropObject.y * scaleY,
      cropObject.width * scaleX,
      cropObject.height * scaleY,
      0,
      0,
      cropObject.width,
      cropObject.height,
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          /* eslint-disable no-param-reassign */
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1,
      );
    });
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Redimensionner la nouvelle photo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReactCrop
              src={newPhoto}
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onImageLoaded={(image) => {
                setUploadedImage(image);
                setCrop({ height: image.height * 0.9, aspect: 1 });
                return false;
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                getCroppedImg(uploadedImage, crop, 'new_photo.jpeg').then((croppedImg) =>
                  onSubmit(croppedImg),
                );
              }}
            >
              Sauvegarder la photo
            </Button>

            <Button variant="ghost" onClick={onClose}>
              Abandonner
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
