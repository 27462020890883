import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Spinner,
  StackItem,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from '@chakra-ui/react';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'qs';
import { unwrapResult } from '@reduxjs/toolkit';
import Tree from 'rc-tree';
import '../../../../node_modules/rc-tree/assets/index.css';
import { FaUniversity } from 'react-icons/fa';
import { MdToday } from 'react-icons/all';
import { DataTable } from '../../../common/components/DataTable';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { columns } from './columns';
import { fetchOrganisations, organisationsSelectors } from '../organisationsSlice';
import { fetchOrganisationsTree, organisationsTreeSelectors } from '../organisationsTreeSlice';
import { useBulkActions } from './useBulkActions.hook';
import { organisationIcon, organisationTypeLabel } from '../../../common/utils/organisations-utils';
import { Metadata } from '../../../Metadata';
import { distinct } from '../../../common/utils/array-utils';
import Select from '../../../common/components/Select';
import { capitalizeFirstLetter } from '../../../common/utils';
import {
  fetchStructureTypes,
  structureTypesSelectors,
} from '../../structureTypes/structureTypesSlice';
import { isEmpty } from '../../../common/utils/string-utils';

const initialValues = {
  page: 1,
  perPage: 50,
  short: true,
  format: 'list',
  root: 'institution',
  state: 'actual',
};

export const OrganisationsIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { pending: commandLoading } = useSelector((state) => state.commands);
  const {
    meta: { total },
    pending,
  } = useSelector((state) => state.organisations);
  const organisations = useSelector((state) =>
    organisationsSelectors.selectAll(state.organisations),
  );
  const organisationsTree = useSelector((state) =>
    organisationsTreeSelectors.selectAll(state.organisationsTree),
  );
  const structureTypes = useSelector((state) => {
    return structureTypesSelectors.selectAll(state.structureTypes);
  });

  useEffect(() => {
    dispatch(fetchStructureTypes());
  }, [dispatch]);

  const deduplicatedStructureTypes = useMemo(() => {
    // Merge structure types of same name but belonging to different levels
    const deduplicated = {};

    structureTypes.forEach((structureType) => {
      const registered = deduplicated[structureType.longNameFr];
      if (registered) {
        deduplicated[structureType.longNameFr].code.push(structureType.code);
        deduplicated[structureType.longNameFr].parent.push(structureType.parent);
        deduplicated[structureType.longNameFr].level.push(structureType.level);
      } else {
        deduplicated[structureType.longNameFr] = {
          ...structureType,
          code: Array.of(structureType.code),
          parent: Array.of(structureType.parent),
          level: Array.of(structureType.level),
        };
      }
    });
    return Object.values(deduplicated).map((st) => {
      return { ...st, code: st.code.join('|') };
    });
  }, [structureTypes]);

  const [treeProperties, setTreeProperties] = useState({ treeData: [] });
  const [treeLoading, setTreeLoading] = useState(true);

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location.search],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters,
  });

  useEffect(() => {
    const forceOpenTree = !isEmpty(filters.query) || !isEmpty(filters.type);
    const instutionRoot = filters.root && filters.root === 'institution';
    const keysToOpen = [];
    if (filters.format === 'tree') {
      const tree = [].concat(
        organisationsTree
          .filter((o) => o.type === 'Institution')
          .flatMap((inst) => {
            const instChildren = distinct(
              organisationsTree.filter(
                (o) => o.type !== 'Institution' && o.supervisorIds.indexOf(inst.id) >= 0,
              ),
              ['id'],
            );
            if (instChildren.length === 0) return [];
            if (forceOpenTree) {
              keysToOpen.push(inst.id);
            }
            return [
              {
                key: inst.id,
                title: inst.titleFr,
                parentId: null,
                children: instChildren.map((child1) => {
                  const structChildren = distinct(
                    organisationsTree.filter(
                      (o) => o.type !== 'Institution' && o.parentStructureId === child1.id,
                    ),
                    ['id'],
                  );
                  const key1 = `${inst.id}-${child1.id}`;
                  if (forceOpenTree) {
                    keysToOpen.push(key1);
                  }
                  return {
                    key: key1,
                    title: child1.titleFr,
                    children: structChildren.map((child2) => {
                      const structGrandChildren = distinct(
                        organisationsTree.filter(
                          (o) => o.type !== 'Institution' && o.parentStructureId === child2.id,
                        ),
                        ['id'],
                      );
                      const key2 = `${inst.id}-${child1.id}-${child2.id}`;
                      if (forceOpenTree) {
                        keysToOpen.push(key2);
                      }
                      return {
                        key: key2,
                        title: child2.titleFr,
                        children: structGrandChildren.map((child3) => {
                          const structGrandGrandChildren = distinct(
                            organisationsTree.filter(
                              (o) => o.type !== 'Institution' && o.parentStructureId === child3.id,
                            ),
                            ['id'],
                          );
                          const key3 = `${inst.id}-${child1.id}-${child2.id}-${child3.id}`;
                          if (forceOpenTree) {
                            keysToOpen.push(key3);
                          }
                          return {
                            key: key3,
                            title: child3.titleFr,
                            parentId: inst.id,
                            children: structGrandGrandChildren.map((o) => {
                              return { key: o.id, title: o.titleFr, parentId: inst.id };
                            }),
                          };
                        }),
                      };
                    }),
                  };
                }),
              },
            ];
          }),
      );

      const props = { treeData: instutionRoot ? tree[0]?.children : tree };
      if (forceOpenTree) {
        props.expandedKeys = keysToOpen;
      } else {
        props.expandedKeys = [];
      }
      setTreeLoading(false);
      setTreeProperties(props);
    }
  }, [organisationsTree]);

  const updateOrganisationsList = () => {
    return dispatch(fetchOrganisations(filters)).then(unwrapResult);
  };

  const [
    toggledClearRows,
    ContextActionsMenu,
    DeduplicationModal,
    CreationModal,
    HeteregeneousTypesAlert,
    bulkActionsHandlers,
  ] = useBulkActions([], structureTypes, { updateOrganisationsList });

  useEffect(() => {
    if (filters.format === 'list') {
      dispatch(fetchOrganisations(filters));
    } else {
      setTreeProperties([]);
      setTreeLoading(true);
      dispatch(fetchOrganisationsTree(filters));
    }
  }, [filters, dispatch]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true }),
    });
  }, [filters, history, location.pathname]);

  const debounced = useDebouncedCallback((value) => {
    setFilters(value);
  }, 500);

  const handlePageChange = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setFilters({ ...filters, perPage: newRowsPerPage });
  };

  const handleStructureTypesChange = (newStructureTypes) => {
    setFilters({
      ...filters,
      type: newStructureTypes
        .map((st) => st.value.split('|'))
        .flat()
        .join(','),
    });
  };

  const handleTextFiltering = (event) => {
    debounced({ ...filters, query: event.target.value, page: 1 });
  };

  const handleSelectedRowsChange = ({ selectedRows: rows }) => {
    bulkActionsHandlers.setSelectedRows(rows);
  };

  const memoizedColumns = useMemo(
    () =>
      columns({
        handlers: bulkActionsHandlers,
        location,
        icon: organisationIcon,
        typeLabel: organisationTypeLabel,
        loading: commandLoading,
      }),
    [bulkActionsHandlers, location, commandLoading],
  );

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Organisations</PageTitle>
        <Metadata
          titleSuffix="Organisations"
          descriptionSuffix="Liste des organisations référencées"
        />
      </PageHeader>
      <PageBody p="page.gutter">
        <HStack align="stretch" spacing={4} mb={5}>
          <Input
            placeholder="Rechercher"
            defaultValue={filters.query}
            onInput={handleTextFiltering}
            mb={5}
          />
          <StackItem pt={0} flex="0 0 calc(15% - 1rem)">
            <Tooltip
              hasArrow
              label="Seulement les structures de l'école"
              bg="gray.600"
              placement="left-start"
            >
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="institution-only" mb="0">
                  <Icon as={FaUniversity} mb={1} mr={1} />
                </FormLabel>
                <Switch
                  id="institution-only"
                  isChecked={filters.root === 'institution'}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      root: e.target.checked ? 'institution' : 'all',
                    })
                  }
                />
              </FormControl>
            </Tooltip>
            <Tooltip
              hasArrow
              label="Seulement les structures actuelles"
              bg="gray.600"
              placement="left-start"
            >
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="state-filter" mb="0">
                  <Icon as={MdToday} mb={1} mr={1} />
                </FormLabel>
                <Switch
                  id="state-filter"
                  isChecked={filters.state === 'actual'}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      state: e.target.checked ? 'actual' : 'all',
                    })
                  }
                />
              </FormControl>
            </Tooltip>
          </StackItem>

          <StackItem flex="0 0 calc(40% - 1rem)">
            <Select
              isMulti
              colorScheme="cyan"
              variant="solid"
              options={deduplicatedStructureTypes.map((st) => {
                return { id: st.id, value: st.code, label: st.longNameFr };
              })} // Options to display in the dropdown
              value={deduplicatedStructureTypes
                .filter((st) => filters.type?.split(',').indexOf(st.code.split('|')[0]) >= 0)
                .map((st) => {
                  return { id: st.id, value: st.code, label: st.longNameFr };
                })} // Preselected value to persist in dropdown
              getOptionLabel={(option) => capitalizeFirstLetter(option.label)}
              onChange={handleStructureTypesChange} // Function will trigger on select event
              placeholder="Filtrer par catégorie"
            />
          </StackItem>
        </HStack>
        <HeteregeneousTypesAlert />
        <Tabs
          variant="enclosed"
          index={filters.format === 'tree' ? 1 : 0}
          onChange={(index) => setFilters({ ...filters, format: index === 0 ? 'list' : 'tree' })}
        >
          <TabList>
            <Tab>Liste</Tab>
            <Tab>Hiérarchie</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {filters.format === 'list' && (
                <DataTable
                  subHeader
                  subHeaderComponent={ContextActionsMenu}
                  columns={memoizedColumns}
                  data={organisations}
                  selectableRows
                  pagination
                  paginationDefaultPage={Number(filters.page)}
                  paginationPerPage={filters.perPage}
                  paginationServer
                  paginationTotalRows={total}
                  progressPending={pending}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  clearSelectedRows={toggledClearRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onRowClicked={(organisation) =>
                    history.push(`${match.url}/${organisation.id}/overview`, {
                      from: location.pathname,
                      search: qs.stringify(filters, { skipNulls: true }),
                    })
                  }
                />
              )}
            </TabPanel>
            <TabPanel>
              {filters.format === 'tree' && treeLoading && <Spinner />}
              {filters.format === 'tree' && !treeLoading && (
                <Tree
                  className="myCls"
                  showLine
                  checkable={false}
                  selectable
                  onExpand={(keys) => {
                    setTreeProperties({ ...treeProperties, expandedKeys: keys });
                  }}
                  onSelect={(keys) => {
                    const orgId = `${keys[0]}`.match(/\d+-\d+/)
                      ? keys[0].split('-').pop()
                      : keys[0];
                    history.push(`${match.url}/${orgId}/overview`, {
                      from: location.pathname,
                      search: qs.stringify(filters, { skipNulls: true }),
                    });
                  }}
                  {...treeProperties}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageBody>
      {CreationModal}
      <DeduplicationModal />
    </PageContainer>
  );
};
