import { css } from '@chakra-ui/react';

export const conditionalRowStyles = (theme) => [
  {
    when: (row) => row.commandStep?.type === 'Validation',
    style: (row) =>
      css({
        backgroundColor: row.read || row.archived ? 'gray.50' : 'blue.50',
        color: row.read || row.archived ? null : 'blue.600',
      })(theme),
  },
  {
    when: (row) => row.commandStep?.type === 'Notification',
    style: (row) =>
      css({
        backgroundColor: row.read || row.archived ? 'gray.50' : null,
        color: row.read || row.archived ? 'gray.600' : null,
      })(theme),
  },
];
