import { apiV1 } from './api';

/**
 *
 * @param {('function'|'membership'|'responsibility')} relationshipClass the type of relationship to query
 * @returns {Promise}
 */
const getRelationshipTypes = (relationshipClass) => apiV1.get(`/${relationshipClass}_types`);

export { getRelationshipTypes };
