import { displayToast } from './toasts-utils';
import { stripHtml } from './string-utils';

export const commandSuccess = (command) => {
  const prefix = command.finished ? 'Action effectuée' : "Demande en cours d'examen";
  const status = command.finished ? 'success' : 'info';

  displayToast({
    status,
    title: `${prefix} : ${command?.message?.title}`,
    description: stripHtml(command?.message?.text),
  });
};
