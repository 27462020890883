import React from 'react';
import { Badge, Grid, GridItem, HStack, Image, StackItem, Text } from '@chakra-ui/react';

export const ExpandedComponent = ({ data }) => {
  const abstracts = () =>
    data.abstractsFr?.map((abstractFr) => {
      return (
        <Text fontSize="sm" color="gray.500">
          {abstractFr}
        </Text>
      );
    }) || '';
  const keywords = () => (
    <Text display="inline" fontSize="xs" color="gray.500">
      {data.keywordsFr?.join(', ')}{' '}
    </Text>
  );
  const subtitles = () => data.subtitlesFr?.join(', ');

  return (
    <HStack>
      {data.thumbnailPath && (
        <StackItem>
          {' '}
          <Image
            boxSize="250px"
            objectFit="contain"
            src={`${process.env.REACT_APP_API_URL}/${data.thumbnailPath}`}
          />
        </StackItem>
      )}
      <Grid templateColumns="repeat(12, 1fr)" gap={1}>
        <GridItem>
          <Badge variant="outline" w="100%" textAlign="center">
            Sous-titre(s)
          </Badge>
        </GridItem>
        <GridItem colSpan={11} fontSize="xs" dangerouslySetInnerHTML={{ __html: subtitles() }} />
        <GridItem>
          <Badge variant="outline" w="100%" textAlign="center">
            Mots-clés
          </Badge>
        </GridItem>
        <GridItem colSpan={4}>{keywords()}</GridItem>
        <GridItem colStart={1}>
          <Badge variant="outline" w="100%" textAlign="center">
            Résumé(s)
          </Badge>
        </GridItem>
        <GridItem colSpan={11}>{abstracts()}</GridItem>
        <GridItem>
          <Badge variant="outline" w="100%" textAlign="center">
            Citation
          </Badge>
        </GridItem>
        <GridItem
          fontSize="xs"
          colSpan={11}
          dangerouslySetInnerHTML={{ __html: data.citation[0] }}
        />
      </Grid>
    </HStack>
  );
};
