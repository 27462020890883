/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as roleTypesAPI from '../../api/roleTypes';

export const fetchRoleTypes = createAsyncThunk('roleTypes/fetchRoleTypes', async () => {
  const response = await roleTypesAPI.getRoleTypes();
  return response.data.roleTypes;
});

export const roleTypesAdapter = createEntityAdapter();

export const roleTypesSlice = createSlice({
  name: 'roles',
  initialState: roleTypesAdapter.getInitialState({ pending: false, meta: {}, error: null }),

  reducers: {},
  extraReducers: {
    [fetchRoleTypes.pending](state) {
      state.pending = true;
    },
    [fetchRoleTypes.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      roleTypesAdapter.setAll(state, action.payload);
    },
    [fetchRoleTypes.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const roleTypesSelectors = roleTypesAdapter.getSelectors();

export default roleTypesSlice.reducer;
