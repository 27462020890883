import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Input,
  Radio,
  RadioGroup,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import qs from 'qs';
import { AiFillEye, BsArchiveFill, GiChoice, GiInfo } from 'react-icons/all';
import { useDebouncedCallback } from 'use-debounce';
import { DataTable } from '../../../common/components/DataTable';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { columns } from './columns';
import { fetchMessages, messagesSelectors } from '../messagesSlice';
import { useBulkActions } from './useBulkActions.hook';
import { conditionalRowStyles } from './conditionalRowStyles';
import { Metadata } from '../../../Metadata';
import { omit } from '../../../common/utils/hash-utils';
import { DirectValidationModal } from './components/DirectValidationModal';

const initialValues = {
  perPage: 50,
  page: 1,
  query: '',
  includeRead: true,
  includeArchived: false,
  includeMessageType: 'all',
};

export const MessagesIndex = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    meta: { total },
    pending,
  } = useSelector((state) => state.messages);
  const messages = useSelector((state) => messagesSelectors.selectAll(state.messages));
  const notifications = useSelector((state) => state.notifications);
  const [directValidationAction, setDirectValidationAction] = useState();
  const [directValidationMessageId, setDirectValidationMessageId] = useState();
  const [directValidationCommandStepId, setDirectValidationCommandStepId] = useState();
  const [directValidationPerformed, setDirectValidationPerformed] = useState(false);
  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...omit(queryParameters, 'directValidation'),
  });

  const [toggledClearRows, validationQueue, CheckBoxWithBulkActionsMenu, bulkActionsHandlers] =
    useBulkActions([]);

  useEffect(() => {
    dispatch(fetchMessages(filters)).then(bulkActionsHandlers.processValidationQueue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, validationQueue, notifications]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true }),
    });
  }, [filters, history, location.pathname]);

  const debounced = useDebouncedCallback((value) => {
    bulkActionsHandlers.clearSelectedRows();
    setFilters(value);
  }, 500);

  const handlePageChange = (newPage) => {
    bulkActionsHandlers.clearSelectedRows();
    setFilters({ ...filters, page: newPage });
  };

  const handleTextFiltering = (event) => {
    debounced({ ...filters, query: event.target.value, page: 1 });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    bulkActionsHandlers.clearSelectedRows();
    setFilters({ ...filters, perPage: newRowsPerPage });
  };

  const handleClickOnIncludeRead = () => {
    bulkActionsHandlers.clearSelectedRows();
    setFilters({ ...filters, includeRead: !filters.includeRead, page: 1 });
  };

  const handleClickOnIncludeArchived = () => {
    bulkActionsHandlers.clearSelectedRows();
    setFilters({ ...filters, includeArchived: !filters.includeArchived, page: 1 });
  };

  const handleSelectedRowsChange = (datatableState) => {
    bulkActionsHandlers.setSelectedRows(datatableState.selectedRows);
  };

  const memoizedColumns = useMemo(
    () =>
      columns({
        handleClickMessageRead: bulkActionsHandlers.handleClickMessageRead,
        handleClickMessageArchive: bulkActionsHandlers.handleClickMessageArchive,
        handleClickCommandStepAction: bulkActionsHandlers.handleClickCommandStepAction,
      }),
    [bulkActionsHandlers],
  );

  const includeReadTitle = filters.includeRead
    ? 'Les messages lus sont affichés'
    : 'Les messages lus sont masqués';
  const includeArchivedTitle = filters.includeArchived
    ? 'Les messages archivés sont affichés'
    : 'Les messages archivés sont masqués';
  const allTypesTitle = 'Tous les types de messages';
  const notificationsTypeTitle = 'Notifications seulement';
  const validationsTypeTitle = 'Validations seulement';
  useEffect(() => {
    if (queryParameters.directValidation?.match(/^(accept|reject),\d+,\d+$/)) {
      const [action, commandStepId, messageId] = queryParameters.directValidation?.split(',');
      setDirectValidationAction(action);
      setDirectValidationCommandStepId(commandStepId);
      setDirectValidationMessageId(messageId);
      onOpen();
    }
  }, []);

  const handleDirectValidationCancel = () => {
    setDirectValidationPerformed(true);
  };

  const handleDirectValidationConfirm = () => {
    bulkActionsHandlers.handleClickCommandStepAction(
      directValidationAction,
      directValidationCommandStepId,
      directValidationMessageId,
    );
    setDirectValidationPerformed(true);
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Messages</PageTitle>
      </PageHeader>
      <Metadata
        titleSuffix="Messages"
        descriptionSuffix="Messages reçus, demandes de validation ou notifications"
      />
      <PageBody p="page.gutter">
        <HStack
          spacing="3"
          direction="row"
          alignItems="center"
          mb={5}
          position="relative"
          zIndex="dropdown"
        >
          <Input placeholder="Filtrer" defaultValue={filters.query} onInput={handleTextFiltering} />
          <Box h="2rem">
            <Divider orientation="vertical" colorScheme="gray" />
          </Box>
          <RadioGroup
            defaultValue={filters.messageType}
            onChange={(messageType) => setFilters({ ...filters, messageType })}
          >
            <HStack spacing={5} direction="row">
              <Radio
                colorScheme="gray"
                value="all"
                title={allTypesTitle}
                aria-label={allTypesTitle}
              >
                <HStack alignItems="center">
                  <Icon title={allTypesTitle} as={GiChoice} />
                  <Box>+</Box>
                  <Icon as={GiInfo} />
                </HStack>
              </Radio>
              <Radio
                colorScheme="blue"
                value="validation"
                title={validationsTypeTitle}
                aria-label={validationsTypeTitle}
              >
                <Icon as={GiChoice} title={validationsTypeTitle} verticalAlign="text-bottom" />
              </Radio>
              <Radio
                colorScheme="gray"
                value="notification"
                title={notificationsTypeTitle}
                aria-label={notificationsTypeTitle}
              >
                <Icon as={GiInfo} title={notificationsTypeTitle} verticalAlign="text-bottom" />
              </Radio>
            </HStack>
          </RadioGroup>
          <Box h="2rem">
            <Divider orientation="vertical" colorScheme="gray" />
          </Box>
          <Checkbox
            title={includeReadTitle}
            aria-label={includeReadTitle}
            defaultIsChecked={filters.includeRead}
            onChange={(event) => handleClickOnIncludeRead(event.target.value)}
          >
            <Icon as={AiFillEye} verticalAlign="text-bottom" />
          </Checkbox>
          <Checkbox
            title={includeArchivedTitle}
            aria-label={includeArchivedTitle}
            defaultIsChecked={filters.includeArchived}
            onChange={(event) => handleClickOnIncludeArchived(event.target.value)}
          >
            <Icon as={BsArchiveFill} verticalAlign="text-bottom" />
          </Checkbox>
        </HStack>
        <DataTable
          columns={memoizedColumns}
          data={messages}
          selectableRows
          pagination
          paginationDefaultPage={Number(filters.page)}
          paginationPerPage={filters.perPage}
          paginationServer
          paginationTotalRows={total}
          progressPending={pending}
          hasFilters
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          conditionalRowStyles={conditionalRowStyles(theme)}
          onSelectedRowsChange={handleSelectedRowsChange}
          clearSelectedRows={toggledClearRows}
          selectableRowsComponent={CheckBoxWithBulkActionsMenu}
        />
      </PageBody>
      {!directValidationPerformed &&
        directValidationAction &&
        directValidationMessageId &&
        setDirectValidationCommandStepId && (
          <DirectValidationModal
            messages={messages}
            action={directValidationAction}
            messageId={directValidationMessageId}
            isOpen={isOpen}
            onClose={onClose}
            handleDirectValidationCancel={handleDirectValidationCancel}
            handleDirectValidationConfirm={handleDirectValidationConfirm}
          />
        )}
    </PageContainer>
  );
};
