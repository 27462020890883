import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { HStack, IconButton, StackItem } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi';
import { symetricDifference } from '../../utils/array-utils';

export const VocabPicker = ({ vocabularyName, handleTagChange, selectedOptions }) => {
  const [options, setOptions] = useState([]);
  const [changedOptions, setChangedOptions] = useState(null);

  const conceptHaveChanged = useMemo(() => {
    return (
      changedOptions != null &&
      symetricDifference(
        selectedOptions.map((o) => o.key),
        changedOptions,
      ).length > 0
    );
  }, [selectedOptions, changedOptions]);

  useEffect(() => {
    fetch(`/vocabularies/${vocabularyName}.json`, { mode: 'no-cors' })
      .then((response) => response.json())
      .then((data) => {
        setOptions(
          Object.keys(data.terms).map((key) => {
            return { value: key, label: data.terms[key] };
          }),
        );
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <HStack>
      <IconButton
        colorScheme="teal"
        aria-label="Valider"
        size="md"
        disabled={!conceptHaveChanged}
        icon={<HiCheck />}
        onClick={() => handleTagChange(vocabularyName, changedOptions)}
      />
      <StackItem flex="max-content">
        <Select
          autosize
          isMulti
          options={options}
          defaultValue={selectedOptions}
          onChange={(tags) => setChangedOptions(tags.map((tag) => tag.value))}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </StackItem>
    </HStack>
  );
};
