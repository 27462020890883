import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  AiOutlineMinusSquare,
  AiOutlinePlusSquare,
  BiErrorAlt,
  BsCheck,
  BsLightning,
  BsPencil,
  FiStar,
} from 'react-icons/all';
import { useDebouncedCallback } from 'use-debounce';
import { BiArrowBack } from 'react-icons/bi';
import qs from 'qs';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import ReactPaginate from 'react-paginate';
import 'rsuite-table/dist/css/rsuite-table.css';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { fetchStreamById, streamsSelectors } from '../streamsSlice';
import './rsuite-table-custom.css';
import './react-paginate-custom.css';
import { routes } from '../../../common/routes/routes';

const LONG_REFRESH_DELAY = 5000;
const SHORT_REFRESH_DELAY = 300;

const initialValues = {
  status: ['updated', 'conflicted', 'unchanged', 'created'],
  query: '',
};

export const StreamShow = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch('/streams/directory/:streamId');
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [refreshDelay, setRefreshDelay] = React.useState(LONG_REFRESH_DELAY);
  const [refresh, setRefresh] = React.useState(true);
  const [paginatePending, setPaginatePending] = React.useState(false);
  const [importIsRunning, setImportIsRunning] = React.useState(false);

  const {
    meta: { total },
  } = useSelector((state) => state.streams);
  const { pending, error } = useSelector((state) => state.streams);

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location.search],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters,
  });

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true }),
    });
  }, [filters, history, location.pathname]);

  useEffect(() => {
    setOffset(1);
  }, [filters]);

  const stream = useSelector((state) =>
    streamsSelectors.selectById(state.streams, match.params.streamId),
  );

  const hasStatusFilter = (criterium) => {
    return filters.status.indexOf(criterium) >= 0;
  };
  const updateStatusFilter = (criterium) => {
    let statusFilters = filters.status || [];
    if (statusFilters.indexOf(criterium) >= 0) {
      statusFilters = statusFilters.filter((item) => item !== criterium);
    } else {
      statusFilters.push(criterium);
    }
    setFilters({ ...filters, status: statusFilters });
  };
  const debouncedQuery = useDebouncedCallback((value) => {
    setFilters({ ...filters, query: value });
  }, 100);

  useEffect(() => {
    if (match.params.streamId)
      dispatch(
        fetchStreamById({
          streamId: match.params.streamId,
          limit,
          offset,
          filters,
        }),
      )
        .then(setPaginatePending(false))
        .then(importIsRunning && setTimeout(() => setRefresh(!refresh), refreshDelay));
  }, [dispatch, refresh, refreshDelay, match.params.streamId, offset, filters]);

  useEffect(() => {
    setImportIsRunning(stream?.state === 'running');
  }, [stream]);

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(stream?.streamItems)) {
      setItems(stream?.streamItems);
    }
  }, [stream]);

  useEffect(() => {
    setRefreshDelay(importIsRunning ? SHORT_REFRESH_DELAY : LONG_REFRESH_DELAY);
  }, [importIsRunning]);

  const StatusCell = ({ rowData, ...props }) => (
    <Cell className="icon-row" {...props}>
      <HStack>
        {rowData?.updated && <BsPencil />}
        {rowData?.conflicted && <BsLightning />}
        {rowData?.created && <FiStar />}
        {rowData?.unchanged && <BsCheck />}
      </HStack>
    </Cell>
  );
  const linkPathSegment = useMemo(() => {
    if (stream?.streamType === 'directory_people') {
      return routes.people;
    }
    if (stream?.streamType === 'directory_structures') {
      return routes.organisations;
    }
    return undefined;
  }, [stream]);
  const ObjectCell = ({ rowData, ...props }) => {
    if (rowData.objectId) {
      return (
        <Link
          as={NavLink}
          to={{
            pathname: `${linkPathSegment}/${rowData.objectId}`,
            state: { from: location.pathname, search: location.search },
          }}
        >
          <Cell rowData={rowData} {...props} />
        </Link>
      );
    }
    return (
      <Cell rowData={rowData} {...props}>
        <Text>Création de l'enregistrement impossible : {rowData?.report?.error}</Text>
      </Cell>
    );
  };

  const handlePageClick = (event) => {
    setPaginatePending(true);
    setOffset(event.selected + 1);
  };

  const rowKey = 'id';
  const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props}>
      <IconButton
        size="xs"
        variant="ghost"
        appearance="subtle"
        aria-label="Détails"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
            <AiOutlineMinusSquare />
          ) : (
            <AiOutlinePlusSquare />
          )
        }
      />
    </Cell>
  );
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const handleExpanded = (rowData) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };
  const renderRowExpanded = (rowData) => {
    return (
      <div>
        <div
          style={{
            width: 'auto',
            height: 'auto',
            float: 'left',
            marginRight: 10,
            background: '#eee',
            whiteSpace: 'pre-line',
          }}
        >
          {rowData?.report && Object.values(rowData?.report).join('\n')}
        </div>
      </div>
    );
  };
  const table = useMemo(() => {
    return (
      <Table
        autoHeight
        rowKey={rowKey}
        data={items}
        expandedRowKeys={expandedRowKeys}
        renderRowExpanded={renderRowExpanded}
        className={paginatePending ? 'ghost-table' : 'normal-table'}
        rowClassName={(rowData) => {
          if (rowData?.conflicted) {
            return 'row-red';
          }
          if (rowData?.created) {
            return 'row-blue';
          }
          return 'row-back';
        }}
      >
        <Column width={70} align="center">
          <HeaderCell>#</HeaderCell>
          <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
        </Column>
        <Column width={70} resizable>
          <HeaderCell />
          <StatusCell />
        </Column>
        <Column width="auto" fixed resizable>
          <HeaderCell>Objet</HeaderCell>
          <ObjectCell dataKey="object" />
        </Column>
      </Table>
    );
  }, [items, expandedRowKeys]);

  return (
    <PageContainer>
      <PageHeader
        pb={0}
        display="flex"
        height="auto"
        minH="page.header"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Button
          as={NavLink}
          to={{
            pathname: match.path.replace(/\/:.+$/, ''),
          }}
          leftIcon={<BiArrowBack />}
          variant="link"
        >
          Liste des synchronisations annuaire LDAP
        </Button>
        <PageTitle mt={2}>
          Synchronisation annuaire EHESS {stream?.startDate}{' '}
          {stream?.state === 'running' && <Spinner ml={2} />}{' '}
          {stream?.state === 'failed' && <Icon as={BiErrorAlt} verticalAlign="top" ml={2} />}
        </PageTitle>
      </PageHeader>
      <PageBody p="page.gutter">
        <HStack mb={3}>
          <Input
            placeholder="Rechercher"
            defaultValue={filters.query}
            onInput={() => debouncedQuery(event.target.value)}
            width="400"
            flexGrow="1"
          />
          {pending && <Spinner />}
          <ButtonGroup justifyContent="right" spacing="2" ml={1} size="md" width="full">
            <IconButton
              size="lg"
              variant="ghost"
              colorScheme="gray"
              isActive={hasStatusFilter('conflicted')}
              onClick={() => updateStatusFilter('conflicted')}
              icon={<BsLightning />}
            />
            <IconButton
              size="lg"
              variant="ghost"
              colorScheme="gray"
              isActive={hasStatusFilter('updated')}
              onClick={() => updateStatusFilter('updated')}
              icon={<BsPencil />}
            />
            <IconButton
              size="lg"
              variant="ghost"
              colorScheme="gray"
              isActive={hasStatusFilter('created')}
              onClick={() => updateStatusFilter('created')}
              icon={<FiStar />}
            />
            <IconButton
              size="lg"
              variant="outline"
              colorScheme="gray"
              isActive={hasStatusFilter('unchanged')}
              onClick={() => updateStatusFilter('unchanged')}
              icon={<BsCheck />}
            />
          </ButtonGroup>
        </HStack>
        {table}
        <Box textAlign="right">
          {stream && (
            <ReactPaginate
              breakLabel="..."
              forcePage={offset - 1}
              nextLabel="next >"
              onPageChange={handlePageClick}
              containerClassName="pagination" /* as this work same as bootstrap class */
              subContainerClassName="pages pagination" /* as this work same as bootstrap class */
              activeClassName="active" /* as this work same as bootstrap class */
              pageRangeDisplayed={20}
              pageCount={stream.streamItemsCount / limit}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          )}
        </Box>
      </PageBody>
      ;
    </PageContainer>
  );
};
