import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { BiCheckCircle, BiExport, BiGitMerge, BiTrash, BiXCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaPlus } from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import { displayErrorToast } from '../../../common/utils/toasts-utils';
import {
  deleteManyOrganisation,
  deleteOneOrganisation,
  updateManyOrganisations,
  updateOneOrganisation,
} from '../organisationsSlice';
import { commandSuccess } from '../../../common/utils/commands-utils';
import { createCommand } from '../../commands/commandsSlice';
import { DeduplicationModal as DeduplicationModalComponent } from '../../../common/components/DeduplicationModal';
import { CreationModalComponent } from '../../../common/components/CreationModal';
import { cleanEmptyEntries } from '../../../common/utils/hash-utils';

const attributesMap = {
  acronymFr: 'Acronyme français',
  acronymEn: 'Acronyme anglais',
  endDate: 'Date de fermeture',
  startDate: 'Date de création',
  future: 'Organisation à venir',
  genericEmailAddress: 'Adresse mail générique',
  past: 'Organisation passée',
  titleFr: 'Nom français',
  titleEn: 'Nom anglais',
  uid: 'Identifiant ancien Annuaire LDAP',
  newUid: 'Identifiant nouvel Annuaire LDAP',
  webSite: 'Adresse du site web',
  aurehalId: 'Identifiant Aurehal',
  cnrsId: 'Identifiant CNRS',
  idrefId: 'Identifiant Idref',
  rnsrId: 'Identifiant RNSR',
  technicalId: 'Identifiant technique',
  vivoId: 'Identifiant Vivo',
  structureTypes: 'Type de structure',
};

export const useBulkActions = (initialState, structureTypes, { updateOrganisationsList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const { pending: commandLoading } = useSelector((state) => state.commands);
  const [selectedRows, setSelectedRows] = useState(initialState);
  const [invalidMergeAlert, setInvalidMergeAlert] = useState(false);
  const [toggledClearRows, setToggledClearRows] = useState(false);

  useEffect(() => setInvalidMergeAlert(false), [selectedRows]);

  const clearSelectedRows = () => {
    setToggledClearRows(!toggledClearRows);
    setSelectedRows([]);
  };

  const openIfMergeable = () => {
    if (selectedRows[0].type !== selectedRows[1].type) {
      setInvalidMergeAlert(true);
    } else {
      setInvalidMergeAlert(false);
      onOpen();
    }
  };

  const handleMultipleOrganisationsUpdate = (action) => {
    clearSelectedRows();
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(updateManyOrganisations(data.payload?.structures));
        commandSuccess(data);
      })
      .catch(displayErrorToast);
  };

  const handleMultipleOrganisationsDelete = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(deleteManyOrganisation(data.payload?.organisations));
        commandSuccess(data);
      })
      .catch(displayErrorToast);
  };

  const handleStructureUpdate = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const handleStructureDelete = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(deleteOneOrganisation(data.command?.payload?.structure));
        commandSuccess(data.command);
      })
      .then(updateOrganisationsList)
      .catch(displayErrorToast);
  };

  const handleDeduplication = (commandParameters) => {
    return handleStructureUpdate(
      createCommand({
        code: 'merge_organisations',
        ...commandParameters,
      }),
    ).then(updateOrganisationsList);
  };

  const handleOrganisationCreate = (commandParameters) => {
    return dispatch(
      createCommand({
        code: 'create_organisation',
        ...cleanEmptyEntries(commandParameters),
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        commandSuccess(data.command);
        history.push({
          pathname: `/organisations/${data.command.payload.organisation.id}/overview`,
        });
      })
      .catch(displayErrorToast);
  };

  const handleValidation = (boolean, organisationId) => {
    handleStructureUpdate(
      createCommand({
        code: 'validate_organisation',
        newValue: boolean,
        organisationId,
      }),
    );
  };
  const handleDeletion = (organisationId) => {
    return handleStructureDelete(
      createCommand({
        code: 'delete_organisation',
        organisationId,
      }),
    );
  };

  const handleMultipleValidation = (boolean, organisationIds) => {
    organisationIds.forEach((organisationId) => {
      handleValidation(boolean, organisationId);
    });
  };

  const handleMultipleDeletion = (organisationIds) => {
    organisationIds.forEach((organisationId) => {
      handleDeletion(organisationId);
    });
  };

  const handleClickDeletion = (organisation) => {
    return handleDeletion(organisation.id);
  };

  const handleClickValidation = (boolean, organisation) => {
    if (boolean === organisation.validated) {
      return;
    }
    handleValidation(boolean, organisation.id);
  };

  const handleClickBulkValidation = (boolean) => {
    handleMultipleValidation(
      boolean,
      selectedRows.filter((row) => boolean !== row.validated).map((row) => row.id),
    );
  };
  const handleClickBulkDeletion = () => {
    handleMultipleDeletion(selectedRows.map((row) => row.id));
  };

  const ContextActionsMenu = useMemo(
    () => (
      <Flex
        alignItems="center"
        direction="row"
        position="absolute"
        zIndex={10}
        w="calc(100% - 3rem)"
        h="56px"
        bg="gray.200"
        pl={2}
        style={{
          marginLeft: '3rem',
          marginTop: '56px',
          paddingRight: '3em',
        }}
      >
        <Text color="gray.500">{selectedRows.length} sélectionné(s)</Text>
        <Box mx={4} alignSelf="stretch" py={2}>
          <Divider orientation="vertical" borderColor="gray.400" />
        </Box>
        <HStack>
          <Tooltip shouldWrapChildren hasArrow label="Valider" bg="gray.600">
            <IconButton
              isDisabled={selectedRows.length === 0}
              aria-label="Valider"
              variant="action"
              icon={<BiCheckCircle />}
              onClick={() => handleClickBulkValidation(true)}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Invalider" bg="gray.600">
            <IconButton
              isDisabled={selectedRows.length === 0}
              aria-label="Invalider"
              variant="action"
              icon={<BiXCircle />}
              onClick={() => handleClickBulkValidation(false)}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Supprimer" bg="red.600">
            <IconButton
              isDisabled={selectedRows.length === 0}
              aria-label="supprimer"
              variant="action"
              icon={<BiTrash />}
              onClick={handleClickBulkDeletion}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Fusionner" bg="gray.600">
            <IconButton
              isDisabled={selectedRows.length < 2}
              variant="action"
              aria-label="Fusionner"
              icon={<BiGitMerge />}
              onClick={openIfMergeable}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Exporter" bg="gray.600">
            <IconButton isDisabled aria-label="exporter" variant="action" icon={<BiExport />} />
          </Tooltip>
        </HStack>
        <Spacer />
        <Button
          variant="outline"
          leftIcon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpen2}
        >
          Créer une organisation
        </Button>
      </Flex>
    ),
    [selectedRows],
  );

  const DeduplicationModal = () => (
    <DeduplicationModalComponent
      entitiesToMerge={selectedRows}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleDeduplication}
      loading={commandLoading}
      entityName="organisations"
      titleAttribute="titleFr"
      attributesMap={attributesMap}
    />
  );

  const CreationModal = useMemo(
    () => (
      <CreationModalComponent
        isOpen={isOpen2}
        onClose={onClose2}
        onSubmit={handleOrganisationCreate}
        loading={commandLoading}
        entityType="organisation"
        structureTypes={structureTypes}
      />
    ),
    [isOpen2, onClose2, commandLoading],
  );

  const HeteregeneousTypesAlert = () =>
    invalidMergeAlert && (
      <Alert status="error">
        <AlertTitle mr={2}>Fusion d'organisations impossible :</AlertTitle>
        <AlertDescription>
          Vous ne pouvez pas fusionner une institution avec une structure : veuillez harmoniser les
          types au préalable..
        </AlertDescription>
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={() => setInvalidMergeAlert(false)}
        />
      </Alert>
    );

  return [
    toggledClearRows,
    ContextActionsMenu,
    DeduplicationModal,
    CreationModal,
    HeteregeneousTypesAlert,
    {
      setSelectedRows,
      clearSelectedRows,
      handleClickBulkValidation,
      handleClickValidation,
      handleClickDeletion,
    },
  ];
};
