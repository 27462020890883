/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as messagesAPI from '../../api/messages';

export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async (params = { page: 1, query: '' }) => {
    const response = await messagesAPI.getMessageList(params);
    return response.data;
  },
);
export const readMessages = createAsyncThunk(
  'messages/readMessages',
  async (params, { rejectWithValue }) => {
    try {
      const response = await messagesAPI.readMessages({ params });
      return response.data;
    } catch (err) {
      const error = err;
      return rejectWithValue(error.response?.data);
    }
  },
);
export const archiveMessages = createAsyncThunk(
  'messages/archiveMessages',
  async (params, { rejectWithValue }) => {
    try {
      const response = await messagesAPI.archiveMessages({ params });
      return response.data;
    } catch (err) {
      const error = err;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const messagesAdapter = createEntityAdapter();

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {},
  extraReducers: {
    [fetchMessages.pending](state) {
      state.pending = true;
    },
    [fetchMessages.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      messagesAdapter.setAll(state, action.payload.messages);
    },
    [fetchMessages.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [readMessages.pending](state) {
      state.pending = true;
    },
    [readMessages.fulfilled](state) {
      state.pending = false;
    },
    [readMessages.rejected](state, action) {
      state.error = action.payload?.errors || action.error;
      state.pending = false;
    },
    [archiveMessages.pending](state) {
      state.pending = true;
    },
    [archiveMessages.fulfilled](state) {
      state.pending = false;
    },
    [archiveMessages.rejected](state, action) {
      state.error = action.payload?.errors || action.error;
      state.pending = false;
    },
  },
});

export const messagesSelectors = messagesAdapter.getSelectors();

export default messagesSlice.reducer;
