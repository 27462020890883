import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from '@chakra-ui/react';
import { FieldChooser } from './FieldChooser';

export const DeduplicationModal = ({
  onClose,
  isOpen,
  entitiesToMerge,
  onSubmit,
  loading,
  entityName,
  titleAttribute,
  attributesMap,
}) => {
  const [entity1, entity2] = entitiesToMerge;
  const [choices, setChoices] = useState({});
  const [validAfterMerge, setValidAfterMerge] = useState(false);

  useEffect(() => setValidAfterMerge(entity1?.validated || entity2?.validated), [entity1, entity2]);

  const handleChoiceforAttribute = (attribute, choice) => {
    setChoices({ ...choices, [attribute]: choice });
  };

  const handleReset = () => {
    setChoices({});
    onClose();
  };

  const handleDeduplication = () => {
    onSubmit({
      entity1_id: entity1.id,
      entity2_id: entity2.id,
      choices,
      validAfterMerge,
    })
      // Reseting selected rows on success unrender the modal, and so reset it.
      // We just have to update closeness state
      .then(onClose);
  };

  if (entitiesToMerge.length !== 2) {
    return null;
  }

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Fusionner deux {entityName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <Box flex={1}>
              <Heading align="right">
                {entity1[titleAttribute]} ({entity1?.source})
              </Heading>
            </Box>
            <Center height="50px">
              <Divider orientation="vertical" variant="solid" />
            </Center>
            <Box flex={1}>
              <Heading align="left">
                {entity2[titleAttribute]} ({entity2?.source})
              </Heading>
            </Box>
          </HStack>
          <Center>
            <HStack>
              <Switch
                onChange={(event) => setValidAfterMerge(event.target.checked)}
                isChecked={validAfterMerge}
                defaultChecked={validAfterMerge}
              />
              <Text>Valide après la fusion</Text>
            </HStack>
          </Center>
          {Object.keys(attributesMap).map((attribute) => {
            return (
              <FieldChooser
                key={`field_chooser${attribute}`}
                entity1={entity1}
                entity2={entity2}
                fieldTitle={attributesMap[attribute]}
                attribute={attribute}
                onValueSelectedChange={handleChoiceforAttribute}
              />
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={handleReset} mr={3}>
            Annuler
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleDeduplication}
            isLoading={loading}
            loadingText="Fusion en cours"
          >
            Fusionner
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
