import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { createCommand } from '../../../../../commands/commandsSlice';
import { updateOneOrganisation, upsertManyOrganisations } from '../../../../organisationsSlice';
import { commandSuccess } from '../../../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../../../common/utils/toasts-utils';
import { OrganisationsAutocomplete } from '../../../../components/OrganisationsAutocomplete';
import { capitalizeFirstLetter } from '../../../../../../common/utils';

export const RelationshipAddingModal = ({
  onClose,
  isOpen,
  type,
  organisationType,
  organisationTypeLabel,
  title,
  relationType,
  organisation,
}) => {
  const dispatch = useDispatch();
  const [orgId, setOrgId] = useState(null);

  const { error: errorCommand } = useSelector((state) => state.commands);

  const handleReset = () => {
    onClose();
  };

  const onChange = (selectedOrgId) => {
    setOrgId(selectedOrgId);
  };

  const handleAddRelationShip = () => {
    const code = `add_${relationType}`;
    let params = {};
    switch (relationType) {
      case 'inclusion':
        switch (type) {
          case 'parent':
            params = {
              parent_structure_id: orgId,
              child_structure_id: organisation.id,
            };
            break;
          case 'child':
            params = {
              parent_structure_id: organisation.id,
              child_structure_id: orgId,
            };
            break;
          default:
            break;
        }
        break;
      case 'supervision':
        switch (type) {
          case 'parent':
            params = {
              supervisor_id: orgId,
              supervised_id: organisation.id,
            };
            break;
          case 'child':
            params = {
              supervisor_id: organisation.id,
              supervised_id: orgId,
            };
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    dispatch(
      createCommand({
        code,
        ...params,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(upsertManyOrganisations(data.command.payload.organisations));
        commandSuccess(data.command);
        handleReset();
      })
      .catch(displayErrorToast);
  };

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter {title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert
            status="error"
            mb={5}
            display={errorCommand && errorCommand?.base ? 'flex' : 'none'}
          >
            <AlertIcon />
            <AlertTitle mr={2}>Erreur</AlertTitle>
            <AlertDescription>{errorCommand?.base}</AlertDescription>
          </Alert>
          <VStack alignItems="flex-start" spacing={5}>
            <Text>{capitalizeFirstLetter(organisationTypeLabel)}</Text>
            <Box w="100%">
              <OrganisationsAutocomplete
                name={name}
                defaultValue={orgId}
                organisationType={organisationType}
                handleSelectedItemsChange={onChange}
                size="xl"
                placeholder={`Choisissez une ${organisationTypeLabel}`}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            // disabled={!formComplete}
            colorScheme="blue"
            mr={3}
            onClick={handleAddRelationShip}
          >
            Ajouter
          </Button>
          <Button variant="ghost" onClick={handleReset}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
