import React from 'react';
import { Avatar, HStack, IconButton, Link, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { HiPencil } from 'react-icons/hi';
import { BiTrash } from 'react-icons/bi';
import { routes } from '../../../../../common/routes/routes';
import { formatDate } from '../../../../../common/utils/date-utils';
import { ConfirmDialog } from '../../../../../common/components/ConfirmDialog';
import { capitalizeFirstLetter } from '../../../../../common/utils';
import { relationshipClassLabels } from '../../../../../common/utils/relationships-utils';
import { deduplicate, distinct } from '../../../../../common/utils/array-utils';

export const columns = ({ location, membershipsType, handleRelationShipChange, indirect }) => [
  {
    name: 'Nom(s), Prénom(s)',
    cell: (relationship) => {
      return (
        <div data-tag="allowRowEvents">
          <HStack align="stretch">
            <Avatar
              size="xs"
              src={`${process.env.REACT_APP_API_URL}/${relationship.userPhotoPath}`}
            />
            <VStack spacing={1} align="flex-start" justify="center">
              <Link
                as={NavLink}
                to={{
                  pathname: `${routes.people}/${relationship.userId}`,
                  state: { from: location.pathname, search: location.search },
                }}
              >
                {relationship.userDisplayName}
              </Link>
            </VStack>
          </HStack>
        </div>
      );
    },
    sortable: true,
    selector: 'userDisplayName',
    grow: 1.25,
  },
  {
    name: membershipsType === 'memberships' ? "Mode d'appartenance" : 'Statut',
    cell: (relationship) => {
      const relationshipType = membershipsType.slice(0, -1);

      return (
        <Text>
          {deduplicate(
            relationship[`${relationshipType}Type`]?.map((type) => type.longNameFr),
          ).join(', ')}
        </Text>
      );
    },
  },
  {
    name: membershipsType === 'memberships' ? "Titre de l'appartenance" : 'Titre du statut',
    cell: (relationship) => (
      <VStack spacing={1} align="flex-start">
        <Text d="flex">
          <Text
            as="span"
            fontSize="semibold"
            color="gray.500"
            whiteSpace="nowrap"
            fontFamily="mono"
          >
            fr&nbsp;:&nbsp;
          </Text>
          {deduplicate(relationship.longTitleFr).join(', ')}
        </Text>
        <Text d="flex">
          <Text
            as="span"
            fontSize="semibold"
            color="gray.500"
            whiteSpace="nowrap"
            fontFamily="mono"
          >
            en&nbsp;:&nbsp;
          </Text>
          {deduplicate(relationship.longTitleEn).join(', ')}
        </Text>
      </VStack>
    ),
  },
  {
    name: 'Classe',
    cell: (relationship) => {
      return <Text>{relationship.memberClass}</Text>;
    },
    omit: membershipsType === 'functions',
  },
  {
    name: 'Structure(s)',
    cell: (relationship) => {
      return deduplicate(
        distinct(relationship.structure, ['id'])?.map((structure) => {
          return (
            <Link
              as={NavLink}
              to={{
                pathname: `${routes.organisations}/${structure.id}/membres`,
                state: { from: location.pathname, search: location.search },
              }}
            >
              {structure.titleFr}
            </Link>
          );
        }),
      );
    },
    omit: membershipsType === 'functions' || !indirect,
  },
  {
    name: 'Début',
    cell: (relationship) => {
      if (relationship.startDate) return formatDate(relationship.startDate);

      return '?';
    },
    sortable: true,
    selector: 'startDate',
    grow: '10ch',
  },
  {
    name: 'Fin',
    cell: (relationship) => {
      if (relationship.endDate) return formatDate(relationship.endDate);
      if (!relationship.future && !relationship.past) return 'En cours';
      if (relationship.future && !relationship.startDate) return 'Future';
      if (relationship.past) return 'Passée';

      return '?';
    },
    sortable: true,
    selector: 'endDate',
    grow: '10ch',
  },
  {
    cell: (relationship) => {
      const relationshipType = membershipsType.slice(0, -1);
      const relationshipClassName = relationshipClassLabels[relationshipType];

      return (
        <HStack mx="auto">
          <IconButton
            aria-label="Editer"
            size="sm"
            variant="action"
            icon={<HiPencil />}
            as={NavLink}
            to={{
              pathname: `${routes.people}/${relationship.userId}/${membershipsType}`,
              state: { from: location.pathname, search: location.search },
            }}
          />
          <ConfirmDialog
            control={
              <IconButton
                size="sm"
                aria-label="Supprimer"
                title="Supprimer"
                variant="ghost"
                colorScheme="red"
                icon={<BiTrash />}
                rounded="full"
              />
            }
            actionText="Supprimer"
            headerText={`Suppression d'${capitalizeFirstLetter(relationshipClassName)}`}
            mainText={
              <p>
                Voulez-vous vraiment retirer <strong>{relationship.userDisplayName}</strong> (
                {relationship[`${relationshipType}Type`]?.longNameFr}),{' '}
                {relationshipType === 'function' ? 'employé·e' : 'membre'} de{' '}
                <strong>
                  {
                    relationship[`${relationshipType === 'function' ? 'institution' : 'structure'}`]
                      .titleFr
                  }
                </strong>
                ?
              </p>
            }
            callback={(_, { onClose }) => {
              handleRelationShipChange(`remove_${relationshipType}_from_user`, {
                id: relationship.id,
              }).then(onClose);
            }}
          />
        </HStack>
      );
    },
    grow: 'auto',
  },
];
