import React from 'react';
import { chakra, Heading, Box, Link, useMultiStyleConfig } from '@chakra-ui/react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

export const PageContainer = ({ children, ...props }) => {
  return (
    <Box d="flex" flexDir="column" minHeight="100%" {...props}>
      {children}
    </Box>
  );
};

export const PageHeader = ({ children, ...props }) => {
  return (
    <chakra.header bg="white" shadow="base" px="page.gutter" py={6} height="page.header" {...props}>
      {children}
    </chakra.header>
  );
};

export const PageTitle = ({ children, ...props }) => {
  return (
    <Heading as="h1" {...props}>
      {children}
    </Heading>
  );
};

export const PageBody = ({ children, ...props }) => {
  return (
    <Box flexGrow="1" m="page.gutter" bg="white" rounded="md" shadow="base" {...props}>
      {children}
    </Box>
  );
};

export const PageNav = ({ routes, size, variant, sx, ...props }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const styles = useMultiStyleConfig('Tabs', { size, variant });

  return (
    <chakra.nav
      sx={{
        ...styles.tablist,
        display: 'flex',
        borderColor: 'transparent',
        ...sx,
      }}
      {...props}
    >
      {routes.map((route) => (
        <Link
          key={route.path}
          as={NavLink}
          to={{ pathname: `${match.url}${route.path}`, state: location.state }}
          sx={styles.tab}
          _hover={{
            // eslint-disable-next-line no-underscore-dangle
            ...styles.tab._hover,
            color: 'gray.500',
            borderBottomColor: 'gray.500',
            textDecoration: 'none',
          }}
          // eslint-disable-next-line no-underscore-dangle
          _activeLink={styles.tab._selected}
        >
          {route.label}
        </Link>
      ))}
    </chakra.nav>
  );
};
