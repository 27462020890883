export const truncate = (input, length = 50) => {
  return input.length > length ? `${input.substring(0, length)}...` : input;
};

export const stripHtml = (str) => {
  // Remove some tags
  let output = str.replace(/<[^>]+>/gim, '');

  // Remove BB code
  output = output.replace(/\[(\w+)[^\]]*](.*?)\[\/\1]/g, '$2 ');

  // Remove html and line breaks
  const div = document.createElement('div');
  div.innerHTML = output;

  const input = document.createElement('input');
  input.value = div.textContent || div.innerText || '';

  return input.value;
};

export const isEmpty = (str) => {
  return !str || /^\s*$/.test(str);
};

export const toCamelCase = (str) => {
  return str
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/^\w/, (c) => c.toLowerCase());
};

export const validateIsni = (str) => {
  return str.match(/^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}[xX]?$/);
};
