/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { categoriesReducer } from '../features/categories';
import { currentUserReducer } from '../features/login';
import { errorsReducer } from '../features/errors';
import { usersReducer } from '../features/users';
import { roleTypesReducer } from '../features/roleTypes';
import { structureTypesReducer } from '../features/structureTypes';
import { organisationsReducer, organisationsTreeReducer } from '../features/organisations';
import { commandsReducer } from '../features/commands';
import { messagesReducer } from '../features/messages';
import { streamsReducer } from '../features/streams';
import { notificationsReducer } from '../features/notifications';
import { peopleReducer } from '../features/people';
import {
  functionsReducer,
  membershipsReducer,
  responsibilitiesReducer,
} from '../features/relationships';

export default configureStore({
  reducer: {
    categories: categoriesReducer,
    currentUser: currentUserReducer,
    errors: errorsReducer,
    users: usersReducer,
    roleTypes: roleTypesReducer,
    structureTypes: structureTypesReducer,
    functionTypes: functionsReducer,
    membershipTypes: membershipsReducer,
    responsibilityTypes: responsibilitiesReducer,
    organisations: organisationsReducer,
    organisationsTree: organisationsTreeReducer,
    commands: commandsReducer,
    messages: messagesReducer,
    streams: streamsReducer,
    notifications: notificationsReducer,
    people: peopleReducer,
  },
});
