/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as commandsAPI from '../../api/commands';

const initialState = {
  params: {},
  pending: false,
  error: null,
};

export const createCommand = createAsyncThunk(
  'commands/createCommand',
  async (params, { rejectWithValue }) => {
    try {
      const { code, ...otherParams } = params;
      const response = await commandsAPI.createCommand(code, otherParams);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

const commandsSlice = createSlice({
  name: 'commands',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    // Create commands
    builder
      .addCase(createCommand.pending, (state, action) => {
        state.params = action.payload;
        state.pending = true;
        state.error = null;
      })
      .addCase(createCommand.fulfilled, (state) => {
        state.params = null;
        state.pending = false;
        state.error = null;
      })
      .addCase(createCommand.rejected, (state, action) => {
        state.error = action.payload?.errors || action.payload || action.error;
        state.pending = false;
      });
  },
});

export const { clear } = commandsSlice.actions;

export default commandsSlice.reducer;
