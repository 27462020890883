import React, { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { BiArrowBack, BiStopCircle, BiTrash } from 'react-icons/bi';
import {
  AiOutlinePlus,
  BiErrorAlt,
  BsGear,
  FaRegHandPaper,
  FaRegThumbsUp,
  VscDebugStart,
} from 'react-icons/all';
import { Button, Icon, IconButton, Link, Spinner } from '@chakra-ui/react';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import {
  deleteStreams,
  fetchStreams,
  launchStream,
  stopStreams,
  streamsSelectors,
} from '../streamsSlice';
import { routes } from '../../../common/routes/routes';
import { displayErrorToast } from '../../../common/utils/toasts-utils';

const LONG_REFRESH_DELAY = 3000;
const SHORT_REFRESH_DELAY = 500;

export const Streams = ({ streamTypes, title }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [limit, setLimit] = React.useState(10);
  const [refreshDelay, setRefreshDelay] = React.useState(LONG_REFRESH_DELAY);
  const [refresh, setRefresh] = React.useState(true);
  const [importIsRunning, setImportIsRunning] = React.useState(false);

  const streams = useSelector((state) => streamsSelectors.selectAll(state.streams));
  const {
    meta: { total },
  } = useSelector((state) => state.streams);

  useEffect(() => {
    dispatch(
      fetchStreams({
        limit,
        streamTypes: Object.keys(streamTypes),
      }),
    ).then(setTimeout(() => setRefresh(!refresh), refreshDelay));
  }, [dispatch, refresh, limit]);

  useEffect(() => {
    setImportIsRunning(streams.some((stream) => stream.state === 'running'));
  }, [streams]);

  useEffect(() => {
    setRefreshDelay(importIsRunning ? SHORT_REFRESH_DELAY : LONG_REFRESH_DELAY);
  }, [importIsRunning]);

  const handleStreamLaunch = (type) => {
    dispatch(launchStream({ source: 'directory', type }))
      .then(unwrapResult)
      .then(() => {
        setRefresh(!refresh);
      });
  };
  const StateCell = ({ rowData, ...props }) => (
    <Cell {...props}>
      {rowData.state === 'running' && <Spinner style={{ display: 'block', margin: 'auto' }} />}
      {rowData.state === 'failed' && (
        <BiErrorAlt
          color="red"
          title={rowData.message}
          style={{ display: 'block', margin: 'auto' }}
          mx="auto"
        />
      )}
      {rowData.state === 'complete' && (
        <FaRegThumbsUp style={{ display: 'block', margin: 'auto' }} color="green" />
      )}
    </Cell>
  );

  const handleStreamsStopAction = (streamIds) => {
    dispatch(stopStreams({ streamIds }))
      .then(unwrapResult)
      .then(() => {
        setRefresh(!refresh);
      })
      .catch(displayErrorToast);
  };

  const handleStreamsDeleteAction = (streamIds) => {
    dispatch(deleteStreams({ streamIds }))
      .then(unwrapResult)
      .then(() => {
        setRefresh(!refresh);
      })
      .catch(displayErrorToast);
  };

  const ActionsCell = ({ rowData, ...props }) => {
    return (
      <Cell {...props}>
        {rowData.state === 'running' && (
          <IconButton
            size="md"
            aria-label="Supprimer"
            title="Supprimer"
            colorScheme="teal"
            onClick={() => handleStreamsStopAction([rowData.id])}
            variant="ghost"
            icon={<BiStopCircle />}
            rounded="full"
          />
        )}
        <IconButton
          size="md"
          ml={2}
          variant="ghost"
          aria-label="Supprimer"
          title="Supprimer"
          colorScheme="red"
          onClick={() => handleStreamsDeleteAction([rowData.id])}
          icon={<BiTrash />}
          rounded="full"
        />
      </Cell>
    );
  };
  const LaunchModeCell = ({ rowData, ...props }) => {
    return (
      <Cell {...props}>
        {rowData.launchMode === 'manual' && (
          <FaRegHandPaper size={18} aria-label="Manuel" title="Manuel" />
        )}
        {rowData.launchMode === 'auto' && <BsGear size={18} aria-label="Auto" title="Auto" />}
      </Cell>
    );
  };

  const LinkCell = ({ rowData, ...props }) => (
    <Link
      as={NavLink}
      to={{
        pathname: `${routes.streams}/directory/${rowData.id}`,
        state: { from: location.pathname, search: location.search },
      }}
    >
      <Cell rowData={rowData} {...props} />
    </Link>
  );
  return (
    <PageContainer>
      <PageHeader
        pb={0}
        display="flex"
        height="auto"
        minH="page.header"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Button
          as={NavLink}
          to={{
            pathname: match.path.replace(/\/[^/]+$/, ''),
          }}
          leftIcon={<BiArrowBack />}
          variant="link"
        >
          Liste des synchronisations
        </Button>
        <PageTitle>{title}</PageTitle>
      </PageHeader>
      <PageBody p="page.gutter">
        {Object.keys(streamTypes).map((streamType) => {
          return (
            <Button
              aria-label={streamTypes[streamType]}
              key={`launch${streamType}`}
              size="sm"
              mb={6}
              mr={1}
              variant="outline"
              disabled={importIsRunning}
              onClick={() => handleStreamLaunch(streamType)}
            >
              {' '}
              <Icon as={VscDebugStart} />
              {streamTypes[streamType]}
            </Button>
          );
        })}
        <Table autoHeight data={streams}>
          <Column width={200} fixed resizable>
            <HeaderCell>Début</HeaderCell>
            <LinkCell dataKey="startDate" />
          </Column>
          <Column width={200} fixed resizable>
            <HeaderCell>Fin</HeaderCell>
            <LinkCell dataKey="endDate" />
          </Column>

          <Column width={80} align="center">
            <HeaderCell>Résultat</HeaderCell>
            <StateCell />
          </Column>

          <Column width={100} align="center">
            <HeaderCell>Traités</HeaderCell>
            <Cell dataKey="processed" />
          </Column>

          <Column width={100} align="center">
            <HeaderCell>Créés</HeaderCell>
            <Cell dataKey="created" />
          </Column>

          <Column width={100} align="center">
            <HeaderCell>Modifiés</HeaderCell>
            <Cell dataKey="updated" />
          </Column>
          <Column width={100} align="center">
            <HeaderCell>En conflit</HeaderCell>
            <Cell dataKey="conflicted" />
          </Column>
          <Column width={100} align="center">
            <HeaderCell>Inchangés</HeaderCell>
            <Cell dataKey="unchanged" />
          </Column>
          <Column width={80} align="center">
            <HeaderCell>Lancement</HeaderCell>
            <LaunchModeCell />
          </Column>
          <Column width="auto" align="center">
            <HeaderCell>Actions</HeaderCell>
            <ActionsCell />
          </Column>
        </Table>
        {streams.length < total && (
          <Button
            width="100%"
            aria-label="Voir plus"
            size="sm"
            variant="solid"
            onClick={() => {
              setLimit(limit + 10);
            }}
          >
            {' '}
            <Icon as={AiOutlinePlus} />
            Voir plus
          </Button>
        )}
      </PageBody>
    </PageContainer>
  );
};
