import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  VStack,
} from '@chakra-ui/react';

import { fetchRoleTypes, roleTypesSelectors } from '../../../roleTypes/roleTypesSlice';
import { clear, createCommand } from '../../../commands/commandsSlice';
import { updateOneUser } from '../../usersSlice';
import { capitalizeFirstLetter } from '../../../../common/utils';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import { OrganisationsAutocomplete } from '../../../organisations/components/OrganisationsAutocomplete';

export const RoleAddingModal = ({ onClose, isOpen, editingUser }) => {
  const dispatch = useDispatch();
  const [roleTypeId, setRoleTypeId] = useState('');
  const [organisationId, setOrganisationId] = useState('');
  const roleTypes = useSelector((state) => roleTypesSelectors.selectAll(state.roleTypes));
  const currentRoleType = useSelector((state) =>
    roleTypesSelectors.selectById(state.roleTypes, roleTypeId),
  );
  const { pending, error } = useSelector((state) => state.roleTypes);
  const { error: errorCommand } = useSelector((state) => state.commands);

  useEffect(() => {
    dispatch(fetchRoleTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!roleTypeId || !currentRoleType?.hasPerimeter) {
      setOrganisationId('');
    }
  }, [roleTypeId, currentRoleType]);

  const handleReset = () => {
    setOrganisationId('');
    setRoleTypeId('');
    onClose();
  };

  const handleAddRole = () => {
    dispatch(
      createCommand({
        code: 'add_role_to_user',
        roleTypeId,
        organisationId,
        userId: editingUser.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOneUser(data.command?.payload?.user));
        commandSuccess(data.command);
        handleReset();
      })
      .catch((err) => {
        if (err.message) {
          displayErrorToast(error);
        }
      });
  };

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Erreur</AlertTitle>
        <AlertDescription>Une erreur est survenue</AlertDescription>
      </Alert>
    );
  }

  if (pending) {
    return <Spinner />;
  }

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={() => {
        dispatch(clear());
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter une permission à {editingUser?.displayName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert
            status="error"
            mb={5}
            display={errorCommand && errorCommand?.base ? 'flex' : 'none'}
          >
            <AlertIcon />
            <AlertTitle mr={2}>Erreur</AlertTitle>
            <AlertDescription>{errorCommand?.base}</AlertDescription>
          </Alert>
          <VStack spacing={4} align="stretch">
            <Select value={roleTypeId} onInput={(e) => setRoleTypeId(e.target.value)}>
              <option value="">Choisir un type de rôle</option>
              {roleTypes.map((roleType) => (
                <option key={roleType.id} value={roleType.id}>
                  {capitalizeFirstLetter(roleType.longNameFr)}
                </option>
              ))}
            </Select>
            <OrganisationsAutocomplete
              handleSelectedItemsChange={setOrganisationId}
              disabled={!currentRoleType?.hasPerimeter}
              organisationType="Structure"
              placeholder="Indiquez le périmètre de ce role"
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleReset}>
            Annuler
          </Button>
          <Button colorScheme="blue" onClick={handleAddRole}>
            Ajouter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
