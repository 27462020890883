import React, { useEffect, useState } from 'react';
import { Avatar, Center, Checkbox, Divider, HStack, Spacer, Text } from '@chakra-ui/react';
import { stripHtml, truncate } from '../../utils/string-utils';

const processValue = (attribute, value) => {
  if (attribute === 'photoPath' && value) {
    return <Avatar size="md" src={`${process.env.REACT_APP_API_URL}/${value}`} />;
  }
  if (attribute === 'structureTypes' && value) {
    return value.map((st) => st.longNameFr).join(', ');
  }
  if (typeof value === 'string') {
    return truncate(stripHtml(value), 200);
  }
  if (typeof value === 'boolean') {
    return value ? 'oui' : 'non';
  }
  return value;
};

export const FieldChooser = ({
  entity1,
  entity2,
  fieldTitle,
  attribute,
  onValueSelectedChange,
}) => {
  let value1 = entity1[attribute];
  let value2 = entity2[attribute];
  // Value 1 is default checked except if it is null and value 2 is not
  const [value1Checked, setvalue1Checked] = useState(!(value1 == null && value2 != null));

  useEffect(
    () => onValueSelectedChange(attribute, value1Checked ? '1' : '2'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value1Checked],
  );

  if (value1 == null && value2 == null) {
    return '';
  }

  value1 = processValue(attribute, value1);
  value2 = processValue(attribute, value2);
  const onChoiceChanged = (event) => {
    const selected = event.target.value;
    setvalue1Checked(selected === '1');
  };

  return (
    <>
      <Text fontSize="1xl" align="center" fontWeight="bold">
        {fieldTitle}
      </Text>
      <HStack>
        <HStack flex={1} align="left" spacing={4}>
          <Spacer />
          <Text>{value1}</Text>
          <Checkbox
            value={1}
            isReadOnly={value2 == null || value1 == null}
            isChecked={value1Checked}
            onChange={onChoiceChanged}
          />
        </HStack>
        <Center height="50px">
          <Divider orientation="vertical" />
        </Center>
        <HStack flex={1} align="left" spacing={4}>
          <Checkbox
            value={2}
            isReadOnly={value1 == null || value2 == null}
            isChecked={!value1Checked}
            onChange={onChoiceChanged}
          />
          <Text>{value2}</Text>
        </HStack>
      </HStack>
    </>
  );
};
