import React from 'react';
import { Button, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { FaPlus, BsBriefcaseFill } from 'react-icons/all';
import { DetailGroupTitle } from '../../../../common/components/Detail';
import { RelationshipItem } from '../../../../common/components/RelationshipItem';
import { RelationshipFormModal } from '../../../relationships/components/RelationshipFormModal';
import { sortRelations } from '../../../../common/utils/date-utils';
import { fetchFunctionTypes } from '../../../relationships/functionsSlice';
import { Metadata } from '../../../../Metadata';

const organisationMeta = { organisationType: 'Institution', organisationTypeLabel: 'institution' };

const tabName = 'Statut';

export const Functions = ({ person }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${person.displayName}`}
        descriptionSuffix={`${tabName} de ${person.displayName}`}
      />
      <HStack spacing={5}>
        <DetailGroupTitle fontSize="2xl">{tabName}</DetailGroupTitle>
        <Button
          onClick={onOpen}
          variant="outline"
          leftIcon={<FaPlus />}
          colorScheme="blue"
          size="sm"
        >
          Ajouter
        </Button>
      </HStack>
      <VStack alignItems="stretch" spacing={4} mt={5}>
        {sortRelations(person.functions).map((func) => (
          <RelationshipItem
            entity={func}
            key={`func-${func.id}`}
            entityName="function"
            organisation={func.institution}
            relationshipType={func.functionType}
            icon={BsBriefcaseFill}
            person={person}
            {...organisationMeta}
          />
        ))}
      </VStack>
      <RelationshipFormModal
        person={person}
        entityName="function"
        isOpen={isOpen}
        onClose={onClose}
        relationTypeFetchFunction={fetchFunctionTypes}
        {...organisationMeta}
      />
    </>
  );
};
