/**
 * @param {array} arr The array you want to filter for dublicates
 * @param {array<string>} indexedKeys The keys that form the compound key
 *     which is used to filter dublicates
 * @param {boolean} isPrioritizeFormer Set this to true, if you want to remove
 *     dublicates that occur later, false, if you want those to be removed
 *     that occur later.
 */
export const distinct = (arr, indexedKeys, isPrioritizeFormer = true) => {
  const lookup = new Map();
  const makeIndex = (el) => indexedKeys.reduce((index, key) => `${index};;${el[key]}`, '');
  arr.forEach((el) => {
    const index = makeIndex(el);
    if (lookup.has(index) && isPrioritizeFormer) {
      return;
    }
    lookup.set(index, el);
  });

  return Array.from(lookup.values());
};

export const deduplicate = (dupeArray) => {
  return dupeArray.filter((item, i, self) => self.lastIndexOf(item) === i);
};

export const symetricDifference = (arr1, arr2) => {
  return arr1.filter((x) => !arr2.includes(x)).concat(arr2.filter((x) => !arr1.includes(x)));
};

export const sortByKey = (array, key) =>
  array.sort((a, b) => {
    let x = a[key];
    let y = b[key];

    if (typeof x === 'string') {
      x = x?.toLowerCase();
    }
    if (typeof y === 'string') {
      y = y?.toLowerCase();
    }

    if (x < y) {
      return -1;
    }
    return x > y ? 1 : 0;
  });
