import React, { useEffect, useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Box, Button, HStack, Spinner } from '@chakra-ui/react';
import qs from 'qs';
import { FaSyncAlt } from 'react-icons/all';
import { Metadata } from '../../../../../Metadata';
import { DetailGroupTitle } from '../../../../../common/components/Detail';
import { columns } from './columns';
import { DataTable } from '../../../../../common/components/DataTable';
import { ExpandedComponent } from './expandable';
import { fetchStreamById, launchStream } from '../../../../streams/streamsSlice';
import { fetchPeopleById } from '../../../peopleSlice';

const tabName = 'Publications';
const initialValues = {
  page: 1,
  perPage: 25,
  query: '',
  state: 'actual',
  institution: 'all',
  indirect: true,
};

const REFRESH_DELAY = 3000;

export const Publications = ({ person }) => {
  const dispatch = useDispatch();

  const [refresh, setRefresh] = React.useState(false);
  const [stream, setStream] = React.useState(null);

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location.search],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters,
  });

  const handlePageChange = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setFilters({ ...filters, perPage: newRowsPerPage });
  };

  const memoizedColumns = useMemo(
    () =>
      columns({
        location,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  );

  useEffect(() => {
    if (!stream) return;
    dispatch(
      fetchStreamById({
        streamId: stream.id,
        limit: 0,
        offset: 0,
        filters: {},
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        if (data.stream.state === 'running') {
          setTimeout(() => setRefresh(!refresh), REFRESH_DELAY);
          setStream(data.stream);
        } else {
          setStream(data.stream);
          dispatch(fetchPeopleById(person.id));
        }
      });
  }, [dispatch, refresh]);

  const handleStreamLaunch = ({ personId }) => {
    dispatch(
      launchStream({
        source: 'hal',
        type: 'hal_publications_single_person',
        parameters: { personId },
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        setStream(data.stream);
        setRefresh(!refresh);
      });
  };

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${person.displayName}`}
        descriptionSuffix={`Publications de ${person.displayName}`}
      />
      <HStack spacing={5} mb={5}>
        <DetailGroupTitle fontSize="2xl" position="static">
          {tabName}
        </DetailGroupTitle>
        <Button
          variant="outline"
          leftIcon={stream && stream.state === 'running' ? <Spinner /> : <FaSyncAlt />}
          disabled={stream && stream.state === 'running'}
          colorScheme="blue"
          size="sm"
          onClick={() => handleStreamLaunch({ personId: person.id })}
        >
          Synchroniser
        </Button>
      </HStack>

      <DataTable
        subHeader
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        columns={memoizedColumns}
        data={person.publications}
        pagination
        paginationDefaultPage={Number(filters.page)}
        paginationPerPage={filters.perPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        noDataComponent={<Box p={8}>Aucune publication référencée</Box>}
      />
    </>
  );
};
