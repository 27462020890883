/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as relationshipsAPI from '../../api/relationships';

const initialState = {
  entities: [],
  pending: false,
  error: null,
};

export const fetchFunctionTypes = createAsyncThunk(`relationship/fetchFunctionTypes`, async () => {
  const response = await relationshipsAPI.getRelationshipTypes('function');
  return response.data.types;
});

export const functionsSlice = createSlice({
  name: 'functions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFunctionTypes.pending](state) {
      state.pending = true;
    },
    [fetchFunctionTypes.fulfilled](state, action) {
      state.pending = false;
      state.entities = action.payload;
    },
    [fetchFunctionTypes.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export default functionsSlice.reducer;
