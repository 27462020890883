import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { apiV1 } from './api/api';
import App from './App';
import { history } from './common/routes/history';
import store from './redux/store';

apiV1.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const error = err;

    store.dispatch({
      type: `errors/${err.response?.status}`,
      payload: {
        status: err.response?.status,
      },
    });

    return Promise.reject(error);
  },
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
