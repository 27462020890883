import React from 'react';
import { Button, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { TiGroup } from 'react-icons/ti';
import { FaPlus } from 'react-icons/all';
import { DetailGroupTitle } from '../../../../common/components/Detail';
import { RelationshipItem } from '../../../../common/components/RelationshipItem';
import { RelationshipFormModal } from '../../../relationships/components/RelationshipFormModal';
import { fetchMembershipTypes } from '../../../relationships/membershipsSlice';
import { Metadata } from '../../../../Metadata';

const organisationMeta = { organisationType: 'Structure', organisationTypeLabel: 'structure' };
const tabName = 'Appartenance(s)';

export const Memberships = ({ person }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${person.displayName}`}
        descriptionSuffix={`Apparteances(s) de ${person.displayName}`}
      />
      <HStack spacing={5}>
        <DetailGroupTitle fontSize="2xl">{tabName}</DetailGroupTitle>
        <Button
          onClick={onOpen}
          variant="outline"
          leftIcon={<FaPlus />}
          colorScheme="blue"
          size="sm"
        >
          Ajouter
        </Button>
      </HStack>
      <VStack alignItems="stretch" spacing={4} mt={5}>
        {person.memberships.map((memb) => (
          <RelationshipItem
            person={person}
            key={`membship-${memb.id}`}
            entity={memb}
            entityName="membership"
            organisation={memb.structure}
            relationshipType={memb.membershipType}
            icon={TiGroup}
            {...organisationMeta}
          />
        ))}
      </VStack>
      <RelationshipFormModal
        person={person}
        entityName="membership"
        isOpen={isOpen}
        onClose={onClose}
        relationTypeFetchFunction={fetchMembershipTypes}
        {...organisationMeta}
      />
    </>
  );
};
