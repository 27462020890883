import React from 'react';
import { Link, chakra, useMultiStyleConfig } from '@chakra-ui/react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

export const PeopleShowNav = ({ routes, size, variant, sx, ...props }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const styles = useMultiStyleConfig('Tabs', { size, variant });

  return (
    <chakra.nav
      sx={{
        ...styles.tablist,
        display: 'flex',
        borderColor: 'transparent',
        ...sx,
      }}
      {...props}
    >
      {routes.map((route) => (
        <Link
          key={route.path}
          as={NavLink}
          to={{ pathname: `${match.url}${route.path}`, state: location.state }}
          sx={styles.tab}
          _hover={{
            // eslint-disable-next-line no-underscore-dangle
            ...styles.tab._hover,
            color: 'gray.500',
            borderBottomColor: 'gray.500',
            textDecoration: 'none',
          }}
          // eslint-disable-next-line no-underscore-dangle
          _activeLink={styles.tab._selected}
        >
          {route.label}
        </Link>
      ))}
    </chakra.nav>
  );
};
