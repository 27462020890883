import { isAfter, isFuture } from 'date-fns';
import { isPast } from 'date-fns/esm';

export const convertUTCToLocalDate = (date) => {
  if (!date) {
    return date;
  }
  let converted = new Date(date);
  converted = new Date(converted.getUTCFullYear(), converted.getUTCMonth(), converted.getUTCDate());
  return converted;
};

export const convertLocalToUTCDate = (date) => {
  if (!date) {
    return date;
  }
  let converted = new Date(date);
  converted = new Date(
    Date.UTC(converted.getFullYear(), converted.getMonth(), converted.getDate()),
  );
  return converted;
};

export const isPastFlagFrozen = ({ startDate, endDate }) => {
  let enabled = startDate === null && endDate === null;
  enabled = enabled || (endDate === null && isPast(startDate));
  return !enabled;
};
export const isFutureFlagFrozen = ({ startDate, endDate }) => {
  let enabled = startDate === null && endDate === null;
  enabled = enabled || (startDate === null && isFuture(endDate));
  return !enabled;
};

export const futureFlag = ({ startDate, endDate }) => {
  if (startDate && isFuture(startDate)) {
    return true;
  }
  if (startDate && isPast(startDate)) {
    return false;
  }
  if (endDate && isPast(endDate)) {
    return false;
  }
  return undefined;
};

export const pastFlag = ({ startDate, endDate }) => {
  if (endDate && isFuture(endDate)) {
    return false;
  }
  if (endDate && isPast(endDate)) {
    return true;
  }
  if (!endDate && isFuture(startDate)) {
    return false;
  }
  return undefined;
};

export const sortRelations = (relations) => {
  const relationsForSort = relations.slice();
  return relationsForSort.sort((r1, r2) => {
    if (r1.future && !r2.future) {
      return -1;
    }
    if (r2.past && !r1.past) {
      return -1;
    }
    if (r1.past && !r2.past) {
      return 1;
    }
    if (r2.future && !r1.future) {
      return 1;
    }
    const d1 = r1.startDate || r1.endDate;
    const d2 = r2.startDate || r2.endDate;
    return isAfter(Date.parse(d1), Date.parse(d2)) ? -1 : 1;
  });
};

export const formatDate = (date) => {
  if (!date) {
    return date;
  }
  return new Date(date).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};
