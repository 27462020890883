import React, { useEffect, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Button,
  chakra,
  HStack,
  Skeleton,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink,
  Redirect,
  Route,
  Switch as RouteSwitch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { fetchPeopleById, peopleSelectors, updateOnePeople } from '../peopleSlice';
import { PeopleShowNav } from './components/PeopleShowNav';
import { peopleShowRoutes } from './routes';
import { createCommand } from '../../commands/commandsSlice';
import { commandSuccess } from '../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../common/utils/toasts-utils';
import { routes } from '../../../common/routes/routes';

export const PeopleShow = () => {
  const match = useRouteMatch('/people/:peopleId');
  const dispatch = useDispatch();
  const location = useLocation();

  const { pending, error } = useSelector((state) => state.people);
  const person = useSelector((state) =>
    peopleSelectors.selectById(state.people, match.params.peopleId),
  );

  useEffect(() => {
    if (match.params.peopleId) dispatch(fetchPeopleById(match.params.peopleId));
  }, [match.params.peopleId, dispatch]);

  const enabledPeopleShowRoutes = useMemo(
    () => peopleShowRoutes.filter((route) => route.status.indexOf(person?.status) >= 0),
    [person],
  );

  if (!person) {
    return null;
  }

  const handleEditInPlace = (code, payload = {}) => {
    dispatch(
      createCommand({
        code,
        ...payload,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  if (pending) {
    <PageContainer>
      <PageHeader>
        <PageTitle>
          Personnes /{' '}
          <Skeleton>
            <Text as="span" fontSize="80%" color="gray.500">
              Nom de la personne
            </Text>
          </Skeleton>
        </PageTitle>
      </PageHeader>
      <PageBody p="page.gutter">
        <Skeleton>Cette page n'est pas encore implémentée.</Skeleton>
      </PageBody>
    </PageContainer>;
  }

  if (error) {
    return (
      <PageContainer>
        <PageBody p="page.gutter">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreur</AlertTitle>
            <AlertDescription>Une erreur est survenue</AlertDescription>
          </Alert>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader
        pb={0}
        display="flex"
        height="auto"
        minH="page.header"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Button
          as={NavLink}
          to={{
            pathname: location.state?.from || routes.people,
            search: location.state?.search || '',
          }}
          pos="absolute"
          top={3}
          leftIcon={<BiArrowBack />}
          variant="link"
        >
          Liste des personnes
        </Button>
        <HStack mt={2} justifyContent="space-between" width="100%">
          <PageTitle>
            {person.displayName}{' '}
            {person.status === 'guest' && (
              <Badge variant="outline" color="navy" fontSize="xl" ml={4}>
                Invité
              </Badge>
            )}
          </PageTitle>
          <HStack
            mt={4}
            spacing={3}
            alignItems="center"
            rounded="full"
            bgColor="gray.100"
            px={4}
            py={2}
          >
            <Switch
              id="validated"
              isChecked={person.validated}
              onChange={(e) =>
                handleEditInPlace('validate_user', {
                  newValue: e.target.checked,
                })
              }
            />
            <chakra.label htmlFor="validated" maxW="80%">
              Validée
            </chakra.label>
          </HStack>
        </HStack>
        <PeopleShowNav routes={enabledPeopleShowRoutes} sx={{ mt: 'auto' }} />
      </PageHeader>
      <PageBody p="page.gutter">
        <RouteSwitch>
          <Redirect
            from={`${match.url}`}
            to={{ pathname: `${match.url}${peopleShowRoutes[0].path}`, state: location.state }}
            exact
          />
          {enabledPeopleShowRoutes.map((route) => {
            const Component = route.component;
            return (
              <Route
                key={route.path}
                path={`${match.url}${route.path}`}
                render={() => <Component person={person} />}
                exact={route.exact}
                props
              />
            );
          })}
        </RouteSwitch>
      </PageBody>
    </PageContainer>
  );
};
