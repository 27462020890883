import React from 'react';
import { Link, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { DetailRow, DetailRowLabel } from '../../../../common/components/Detail';
import { routes } from '../../../../common/routes/routes';
import { capitalizeFirstLetter } from '../../../../common/utils';

export const RelationshipRecap = ({ label, person, relationshipType, organisationType }) => {
  const relationships = person[relationshipType]?.filter((rt) => !rt.past && !rt.future);

  return (
    <DetailRow alignItems="flex-start">
      <DetailRowLabel>{label}</DetailRowLabel>
      <VStack alignItems="flex-start">
        {relationships.length > 0 ? (
          relationships.map((rt) => (
            <Text lineHeight="short">
              {capitalizeFirstLetter(rt.longTitleFr)}{' '}
              <Link
                as={NavLink}
                color="blue.600"
                textDecor="underline"
                wordBreak="break-all"
                to={`${routes.organisations}/${rt[organisationType].id}`}
              >
                {rt[organisationType].acronymFr}
              </Link>
            </Text>
          ))
        ) : (
          <Text>Aucune</Text>
        )}
      </VStack>
    </DetailRow>
  );
};
