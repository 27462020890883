import { Identification } from './components/Identification';
import { Functions } from './components/Functions';
import { Memberships } from './components/Memberships';
import { Responsibilities } from './components/Responsibilities';
import { Presentation } from './components/Presentation';
import { Publications } from './components/Publications/Publications';
import { Identifiers } from './components/Identifiers';

export const peopleShowRoutes = [
  {
    path: '/identity',
    label: 'Identité et récapitulatif',
    component: Identification,
    status: ['member', 'guest'],
  },
  {
    path: '/functions',
    label: 'Statut',
    component: Functions,
    status: ['member'],
  },
  {
    path: '/memberships',
    label: 'Appartenances',
    component: Memberships,
    status: ['member'],
  },
  {
    path: '/responsibilities',
    label: 'Responsabilités',
    component: Responsibilities,
    status: ['member'],
  },
  {
    path: '/presentation',
    label: 'Présentation',
    component: Presentation,
    status: ['member'],
  },
  {
    path: '/publications',
    label: 'Publications',
    component: Publications,
    status: ['member'],
  },
  {
    path: '/handles',
    label: 'Identifiants',
    component: Identifiers,
    status: ['member', 'guest'],
  },
];
