import React, { useEffect, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  HStack,
  Skeleton,
  Switch,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink,
  Redirect,
  Route,
  Switch as RouteSwitch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import {
  PageBody,
  PageContainer,
  PageHeader,
  PageNav,
  PageTitle,
} from '../../../common/components/Page';
import {
  fetchOrganisationById,
  organisationsSelectors,
  updateOneOrganisation,
} from '../organisationsSlice';
import { organisationsRoutes } from './routes';
import { createCommand } from '../../commands/commandsSlice';
import { commandSuccess } from '../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../common/utils/toasts-utils';
import { routes } from '../../../common/routes/routes';
import { organisationIcon, organisationTypeLabel } from '../../../common/utils/organisations-utils';
import { capitalizeFirstLetter } from '../../../common/utils';

export const OrganisationsShow = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();

  const { pending, error } = useSelector((state) => state.organisations);
  const organisation = useSelector((state) =>
    organisationsSelectors.selectById(state.organisations, match.params.organisationId),
  );

  useEffect(() => {
    if (match.params.organisationId) dispatch(fetchOrganisationById(match.params.organisationId));
  }, [match.params.organisationId, match.params.indirect, dispatch]);

  const handleEditInPlace = (code, payload = {}) => {
    dispatch(
      createCommand({
        code,
        ...payload,
        organisationId: organisation.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const orgIcon = useMemo(() => organisationIcon(organisation), [organisation]);
  const orgTypeLabel = useMemo(() => organisationTypeLabel(organisation), [organisation]);

  const ButtonBack = () => (
    <Button
      as={NavLink}
      to={{
        pathname: location.state?.from || routes.organisations,
        search: location.state?.search || '',
      }}
      pos="absolute"
      top={3}
      leftIcon={<BiArrowBack />}
      variant="link"
    >
      Liste des organisations
    </Button>
  );

  if (pending) {
    return (
      <PageContainer>
        <PageHeader>
          <PageTitle>
            <ButtonBack />
            <Skeleton mt={3} d="inline-block">
              <PageTitle color="gray.500">Nom de l'organisation</PageTitle>
            </Skeleton>
          </PageTitle>
        </PageHeader>
        <PageBody p="page.gutter">
          <Skeleton>Chargement...</Skeleton>
        </PageBody>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <PageBody p="page.gutter">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreur</AlertTitle>
            <AlertDescription>Une erreur est survenue</AlertDescription>
          </Alert>
        </PageBody>
      </PageContainer>
    );
  }

  if (!organisation) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeader
        pb={0}
        display="flex"
        height="auto"
        minH="page.header"
        alignItems="flex-start"
        flexDirection="column"
      >
        <ButtonBack />
        <HStack mt={2} justifyContent="space-between" width="100%">
          <PageTitle>
            {organisation?.titleFr}
            <Tag ml={3} size="lg" colorScheme="blue">
              <TagLeftIcon as={orgIcon} />{' '}
              <TagLabel>{capitalizeFirstLetter(orgTypeLabel)}</TagLabel>
            </Tag>
            {organisation.endDate ? (
              <Tag ml={3} size="lg" colorScheme="red">
                Fermée
              </Tag>
            ) : null}
          </PageTitle>
          <HStack
            mt={4}
            spacing={3}
            alignItems="center"
            rounded="full"
            bgColor="gray.100"
            px={4}
            py={2}
          >
            <Switch
              id="validated"
              isChecked={organisation?.validated}
              onChange={(e) =>
                handleEditInPlace('validate_organisation', {
                  newValue: e.target.checked,
                })
              }
            />
            <chakra.label htmlFor="validated" maxW="80%">
              Validée
            </chakra.label>
          </HStack>
        </HStack>
        <PageNav routes={organisationsRoutes} sx={{ mt: 'auto' }} />
      </PageHeader>
      <PageBody p="page.gutter">
        <RouteSwitch>
          <Redirect
            from={`${match.url}`}
            to={{ pathname: `${match.url}${organisationsRoutes[0].path}`, state: location.state }}
            exact
          />
          {organisationsRoutes.map((route) => {
            const Component = route.component;
            return (
              <Route
                key={route.path}
                path={`${match.url}${route.path}`}
                render={() => <Component organisation={organisation} />}
                exact={route.exact}
              />
            );
          })}
        </RouteSwitch>
      </PageBody>
    </PageContainer>
  );
};
