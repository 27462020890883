import React from 'react';
import { useSelector } from 'react-redux';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { routes } from '../../common/routes/routes';
import Can from '../roleBasedAccess/Can';
import { UsersIndex } from './UsersIndex/UsersIndex';

export const Users = () => {
  const match = useRouteMatch();
  const { user } = useSelector((state) => state.currentUser);
  return (
    <Can
      roles={user.roles}
      perform="app:use"
      data={{ user }}
      yes={() => (
        <Switch>
          <Route path={`${match.url}`} component={UsersIndex} exact />
        </Switch>
      )}
      no={() => <Redirect to={routes.home} />}
    />
  );
};
