import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '../../theme';

const toast = createStandaloneToast({ theme });

export const displayToast = (params) =>
  toast({
    position: 'top-right',
    isClosable: true,
    ...params,
  });

export const displayErrorToast = (err, params = {}) => {
  if (err?.message && err.message.match(/rejected/i)) {
    return;
  }

  if (err?.errors?.base || err?.message) {
    displayToast({
      status: 'error',
      title: 'Erreur',
      description: err?.errors?.base?.join('<br/>') || err?.message,
      ...params,
    });
  }
};
