/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as usersAPI from '../../api/users';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (params = { page: 1, query: '' }) => {
    const response = await usersAPI.getUsersList(params);
    return response.data;
  },
);

export const usersAdapter = createEntityAdapter();

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {
    updateOneUser: usersAdapter.setOne,
  },
  extraReducers: {
    [fetchUsers.pending](state) {
      state.pending = true;
    },
    [fetchUsers.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      usersAdapter.setAll(state, action.payload.users);
    },
    [fetchUsers.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const { updateOneUser } = usersSlice.actions;

export const usersSelectors = usersAdapter.getSelectors();

export default usersSlice.reducer;
