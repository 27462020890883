/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as commandStepsAPI from '../../api/commandSteps';

const initialState = {
  pending: false,
  error: null,
};
export const acceptCommandStep = createAsyncThunk(
  'commandStep/accept',
  async (params, { rejectWithValue }) => {
    try {
      const response = await commandStepsAPI.acceptCommandStep({ params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);
export const rejectCommandStep = createAsyncThunk(
  'commandStep/reject',
  async (params, { rejectWithValue }) => {
    try {
      const response = await commandStepsAPI.rejectCommandStep({ params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

export const commandStepsSlice = createSlice({
  name: 'commandSteps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch users
    builder
      .addCase(acceptCommandStep.pending, (state) => {
        state.pending = true;
      })
      .addCase(acceptCommandStep.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(acceptCommandStep.rejected, (state, action) => {
        state.error = action.payload?.errors || action.error;
        state.pending = false;
      })
      .addCase(rejectCommandStep.pending, (state) => {
        state.pending = true;
      })
      .addCase(rejectCommandStep.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(rejectCommandStep.rejected, (state, action) => {
        state.error = action.payload?.errors || action.error;
        state.pending = false;
      });
  },
});

export default commandStepsSlice.reducer;
