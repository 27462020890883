import React from 'react';
import { Badge, Link } from '@chakra-ui/react';
import { deduplicate } from '../../../../../common/utils/array-utils';

export const columns = () => [
  {
    name: 'Titre',
    cell: (publication) => {
      return deduplicate(publication.titlesFr.concat(publication.titlesEn)).join(', ');
    },
    sortable: true,
    selector: 'publicationTitlesFr',
    grow: 3,
  },
  {
    name: 'Type',
    cell: (publication) => {
      return (
        <Badge bgColor={publication.publicationTypeColor} color="white">
          {publication.publicationType}
        </Badge>
      );
    },
    sortable: true,
    selector: 'publicationType',
    grow: 1,
  },
  {
    name: 'Date',
    cell: (publication) => {
      return publication.producedDate;
    },
    sortable: true,
    selector: 'publicationPublicationDateFr',
    grow: 0.5,
  },
  {
    name: 'Mots clés',
    cell: (publication) => {
      return (
        <Link href={publication.uri} target="_blank">
          {publication.uri}
        </Link>
      );
    },
    sortable: true,
    selector: 'publicationTitlesEn',
    grow: 2,
  },
];
