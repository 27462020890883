import React, { useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import { FaPlus, MdHelpOutline } from 'react-icons/all';
import { RelationshipItem } from './RelationshipItem';
import { RelationshipAddingModal } from './RelationshipAddingModal';
import { isAdministrativeEntity } from '../../../../../../common/utils/organisations-utils';
import {
  relationshipOrganisation,
  relationshipType,
} from '../../../../../../common/utils/relationships-utils';

export const RelationshipsSection = ({
  relationships,
  type,
  title,
  newElementTitle,
  newElementType,
  newElementTypeLabel,
  relationType,
  organisation,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hasRelationships = relationships.length > 0;
  const relationshipsType = useMemo(() => {
    return relationshipType(relationships[0], type);
  }, [organisation, type, relationships]);
  const hasChildSupervisionFlags = useMemo(() => {
    return relationshipsType === 'child-supervision';
  }, [organisation, type, relationships]);
  const hasChildSupervisionFlagsForOrganisation = (childOrganisation) => {
    return hasChildSupervisionFlags && !isAdministrativeEntity(childOrganisation);
  };
  const hasParentSupervisionFlags = useMemo(() => {
    return relationshipsType === 'parent-supervision' && !isAdministrativeEntity(organisation);
  }, [organisation, type, relationships]);
  const addDisabled = useMemo(() => {
    return isAdministrativeEntity(organisation) && type === 'parent' && hasRelationships;
  }, [organisation, type, relationships]);
  const enhancedRelationships = useMemo(() => {
    return relationships.map((relationship) => {
      return { organisation: relationshipOrganisation(relationship, type), ...relationship };
    });
  }, [organisation, type, relationships]);
  return (
    <>
      <Box as="section" {...props}>
        <Heading
          as="h3"
          fontSize="lg"
          // eslint-disable-next-line no-nested-ternary
          mb={type === 'parent' ? (hasRelationships ? '1rem' : '3rem') : '3rem'}
        >
          <Icon as={type === 'parent' ? HiArrowUp : HiArrowDown} mr={3} />
          {title}
          {addDisabled || (
            <Button
              onClick={onOpen}
              variant="outline"
              leftIcon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              ml={3}
            >
              Ajouter
            </Button>
          )}
        </Heading>
        {hasParentSupervisionFlags || hasChildSupervisionFlags ? (
          <HStack spacing="2" pr="46.5ch" opacity="0.6" textAlign="center" mb="2" fontSize="sm">
            <Tooltip
              label="Tutelle hébergeante de la structure"
              aria-label="Tutelle hébergeante de la structure"
              fontSize="md"
              hasArrow
            >
              <Text ml="auto">
                <Icon as={MdHelpOutline} />
                Héb.
              </Text>
            </Tooltip>
            <Tooltip
              label="Tutelle assortie de la délégation générale de gestion"
              aria-label="Tutelle assortie de la délégation générale de gestion"
              fontSize="md"
              hasArrow
            >
              <Text ml="auto">
                <Icon as={MdHelpOutline} />
                DGG
              </Text>
            </Tooltip>
            <Tooltip
              label="Tutelle offrant le service de délégué·e à la protection des données"
              aria-label="Tutelle offrant le service de délégué·e à la protection des données"
              fontSize="md"
              hasArrow
            >
              <Text ml="auto">
                <Icon as={MdHelpOutline} />
                DPO
              </Text>
            </Tooltip>
          </HStack>
        ) : null}
        {hasRelationships ? (
          <VStack alignItems="stretch" spacing="2" fontSize="sm">
            {enhancedRelationships
              ?.sort((a, b) => a.organisation.titleFr.localeCompare(b.organisation.titleFr))
              .map((relationship) => (
                <RelationshipItem
                  key={relationship.id}
                  organisation={relationship.organisation}
                  type={type}
                  relationship={relationship}
                  hasParentSupervisionFlags={hasParentSupervisionFlags}
                  hasChildSupervisionFlags={hasChildSupervisionFlagsForOrganisation(
                    relationship.organisation,
                  )}
                />
              ))}
          </VStack>
        ) : (
          <Alert status="info">
            <AlertIcon />
            <AlertDescription>Néant</AlertDescription>
          </Alert>
        )}
      </Box>
      <RelationshipAddingModal
        onClose={onClose}
        isOpen={isOpen}
        title={newElementTitle}
        organisation={organisation}
        organisationTypeLabel={newElementTypeLabel}
        organisationType={newElementType}
        relationType={relationType}
        type={type}
      />
    </>
  );
};
