/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as relationshipsAPI from '../../api/relationships';

const initialState = {
  entities: [],
  pending: false,
  error: null,
};

export const fetchResponsibilityTypes = createAsyncThunk(
  `relationship/fetchResponsibilityTypes`,
  async () => {
    const response = await relationshipsAPI.getRelationshipTypes('responsibility');
    return response.data.types;
  },
);

export const responsibilitiesSlice = createSlice({
  name: 'responsibilities',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchResponsibilityTypes.pending](state) {
      state.pending = true;
    },
    [fetchResponsibilityTypes.fulfilled](state, action) {
      state.pending = false;
      state.entities = action.payload;
    },
    [fetchResponsibilityTypes.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export default responsibilitiesSlice.reducer;
