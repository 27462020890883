export const relationshipClassLabels = {
  membership: 'une appartenance',
  function: 'un statut',
  responsibility: 'une responsabilité',
};
export const relationshipTypelabels = {
  membership: "type d'appartenance",
  function: 'type de statut',
  responsibility: 'type de responsabilité',
};
export const relationshipType = (relationship, type) => {
  if (!relationship) {
    return null;
  }
  if (relationship.supervisor && type === 'parent') {
    return 'parent-supervision';
  }
  if (relationship.supervised && type === 'child') {
    return 'child-supervision';
  }
  if (relationship.parentStructure && type === 'parent') {
    return 'parent-inclusion';
  }
  if (relationship.childStructure) {
    return 'child-inclusion';
  }
  return null;
};
export const relationshipOrganisation = (relationship, type) => {
  if (relationshipType(relationship, type) === 'parent-supervision') {
    return relationship.supervisor;
  }
  if (relationshipType(relationship, type) === 'child-supervision') {
    return relationship.supervised;
  }
  if (relationshipType(relationship, type) === 'parent-inclusion') {
    return relationship.parentStructure;
  }
  if (relationshipType(relationship, type) === 'child-inclusion') {
    return relationship.childStructure;
  }
  return {};
};
