import rbacRules from './rules';

export const check = (rules, roles, action, data) => {
  const permissions = [...roles, 'all'].reduce((acc, role) => {
    const rolePermissions = rules[role?.roleType?.longNameFr || role];

    if (!rolePermissions) return acc;

    if (!acc) {
      return {
        static: rules[role].static || [],
        dynamic: rules[role].dynamic || {},
      };
    }

    return {
      static: [...acc.static, ...(rules[role].static || [])],
      dynamic: {
        ...acc.dynamic,
        ...(rules[role].dynamic || {}),
      },
    };
  }, null);

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }

  return false;
};

const Can = (props) =>
  check(rbacRules, props.roles, props.perform, props.data) ? props.yes() : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
