import React, { useMemo } from 'react';
import {
  VStack,
  IconButton,
  Box,
  Divider,
  Text,
  HStack,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { HiPencil } from 'react-icons/hi';
import {
  Detail,
  DetailGroup,
  DetailGroupCol,
  DetailGroupTitle,
  DetailRow,
  DetailRowLabel,
} from '../../../../common/components/Detail';
import { createCommand } from '../../../commands/commandsSlice';
import { updateOneOrganisation } from '../../organisationsSlice';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import { EditableWithControlButton } from '../../../../common/components/Editable';
import { capitalizeFirstLetter } from '../../../../common/utils';
import { organisationIcon } from '../../../../common/utils/organisations-utils';
import { StructureTypeModal } from './StructureTypeModal';
import { Metadata } from '../../../../Metadata';

const tabName = 'Identification';

export const Identification = ({ organisation }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const OrgIcon = useMemo(() => organisationIcon(organisation), [organisation]);

  const getTypeLabelByTerminology = (terminology) => {
    const structureType = organisation.structureTypes?.find(
      (structType) => structType.terminology === terminology,
    );

    if (!structureType) return null;

    return structureType.longNameFr;
  };

  const getTypeLabelByGenericity = (generic) => {
    const structureType = organisation.structureTypes?.find(
      (structType) => structType.generic === generic,
    );

    if (!structureType) return null;

    return structureType.longNameFr;
  };

  const isResearchStructure = useMemo(() => {
    return organisation.structureTypes?.some((structType) =>
      structType.code.match(/research-organization/g),
    );
  }, [organisation]);

  const handleEditInPlace = (code, payload = {}) => {
    dispatch(
      createCommand({
        code,
        ...payload,
        organisationId: organisation?.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        console.log(data);
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${organisation.titleFr}`}
        descriptionSuffix={`Caractérisation de l'organisation ${organisation.titleFr}`}
      />
      <VStack spacing={5} alignItems="stretch">
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Identité</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>
            <Detail>
              <DetailRow>
                <DetailRowLabel>Nom de l'organisation</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.titleFr}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_name', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Acronyme FR</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.acronymFr}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_acronym_fr', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Acronyme EN</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.acronymEn}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_acronym_en', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Site web</DetailRowLabel>
                <EditableWithControlButton
                  isLink
                  linkProps={{ isExternal: true }}
                  defaultValue={organisation?.webSite}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_website_url', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Implantation géographique</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.geoPosition}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_geo_position', { newValue })
                  }
                />
              </DetailRow>
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
        <Box>
          <Divider borderColor="gray.400" orientation="horizontal" />
        </Box>
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Type</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>
            <Detail bg="gray.50" pt={6} pb={3} px={5}>
              <DetailRow>
                <HStack as="Text" fontSize="lg" fontWeight="semibold">
                  <Icon as={OrgIcon} />
                  <Text as="span">{capitalizeFirstLetter(getTypeLabelByGenericity(true))}</Text>
                </HStack>
              </DetailRow>
              {isResearchStructure && (
                <>
                  <DetailRow>
                    <DetailRowLabel>Type national</DetailRowLabel>
                    <Text>
                      {capitalizeFirstLetter(getTypeLabelByTerminology('national')) || 'Néant'}
                    </Text>
                  </DetailRow>
                  <DetailRow>
                    <DetailRowLabel>Type établissement</DetailRowLabel>
                    <Text>
                      {capitalizeFirstLetter(getTypeLabelByTerminology('local')) || 'Néant'}
                    </Text>
                  </DetailRow>
                  <DetailRow>
                    <DetailRowLabel>Type spécifique</DetailRowLabel>
                    <Text>
                      {capitalizeFirstLetter(getTypeLabelByTerminology('specific')) || 'Néant'}
                    </Text>
                  </DetailRow>
                </>
              )}
              {!isResearchStructure && (
                <DetailRow>
                  <DetailRowLabel>Type précis</DetailRowLabel>
                  <Text>{capitalizeFirstLetter(getTypeLabelByGenericity(false)) || 'Néant'}</Text>
                </DetailRow>
              )}
              <IconButton
                aria-label="Éditer le type de structure"
                size="sm"
                variant="action"
                ml={1.5}
                my={1}
                icon={<HiPencil />}
                alignSelf="flex-end"
                onClick={onOpen}
              />
              <StructureTypeModal isOpen={isOpen} onClose={onClose} organisation={organisation} />
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
      </VStack>
    </>
  );
};
