import React from 'react';
import { Helmet } from 'react-helmet';

export const Metadata = ({ titleSuffix, descriptionSuffix }) => {
  const descriptionPrefix = process.env.REACT_APP_DESCRIPTION;
  return (
    <Helmet
      title={`${process.env.REACT_APP_TITLE} - ${titleSuffix}`}
      htmlAttributes={{ lang: 'fr' }}
      meta={[
        {
          name: `description`,
          content: `${descriptionPrefix} - ${descriptionSuffix}`,
        },
      ]}
    />
  );
};
