import React from 'react';
import {
  Button,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import { routes } from '../../../common/routes/routes';
import { ConfirmDialog } from '../../../common/components/ConfirmDialog';

export const columns = ({ handlers, location, icon, typeLabel, loading }) => [
  {
    name: 'Institution ou structure',
    conditionalCellStyles: [
      {
        when: (row) => row.past,
        style: {
          color: '#A0AEC0',
        },
      },
    ],
    cell: (organisation) => {
      const type = typeLabel(organisation);
      return (
        <div data-tag="allowRowEvents">
          <HStack alignItems="flex-start">
            <Icon mt={0.5} as={icon(organisation)} />
            <VStack alignItems="flex-start" spacing={1}>
              <Link
                as={NavLink}
                to={{
                  pathname: `${routes.organisations}/${organisation.id}`,
                  state: { from: location.pathname, search: location.search },
                }}
              >
                {organisation.titleFr}
              </Link>
              {type ? (
                <Text as="small" opacity="0.8">
                  {type}
                </Text>
              ) : null}
            </VStack>
          </HStack>
        </div>
      );
    },
    sortable: true,
  },
  {
    cell: (organisation) => (
      <>
        <Tag colorScheme={organisation.validated ? 'blue' : 'red'}>
          <TagLabel>{organisation.validated ? 'Validée' : 'Non validée'}</TagLabel>
        </Tag>
        {organisation.directoryAssociated && (
          <Tag colorScheme="green" ml={2}>
            <TagLabel>Annuaire</TagLabel>
          </Tag>
        )}
        {organisation.past && (
          <Tag colorScheme="blackAlpha" ml={1}>
            <TagLabel>Fermée</TagLabel>
          </Tag>
        )}
      </>
    ),
  },
  {
    name: <VisuallyHidden>Actions</VisuallyHidden>,
    right: true,
    cell: (organisation) => (
      <div data-tag="allowRowEvents">
        <Menu>
          <MenuButton as={Button} size="sm" rightIcon={<HiChevronDown />}>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handlers.handleClickValidation(!organisation.validated, organisation)}
            >
              {organisation.validated ? 'Invalider' : 'Valider'}
            </MenuItem>
            <ConfirmDialog
              control={<MenuItem>Supprimer</MenuItem>}
              actionText="Supprimer"
              headerText="Suppression d'une organisation"
              mainText={`Voulez-vous vraiment supprimer "${organisation.titleFr}" ?`}
              callback={(e, { onClose }) =>
                handlers.handleClickDeletion(organisation).then(onClose)
              }
              isLoading={loading}
              loadingText="Suppression en cours"
            />
            <MenuDivider />
            <MenuItem>Exporter vers Vivo</MenuItem>
          </MenuList>
        </Menu>
      </div>
    ),
    sortable: true,
  },
];
