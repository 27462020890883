import { BiNetworkChart, BiUnite } from 'react-icons/bi';
import { FaStamp, FaUniversity } from 'react-icons/fa';
import { GrGroup } from 'react-icons/gr';
import { IoGitNetwork } from 'react-icons/io5';

export const organisationIcon = (organisation) => {
  if (!organisation) return null;

  let icon;
  switch (organisation.type) {
    case 'Institution':
      icon = FaUniversity;
      break;
    case 'Structure':
      switch (organisation.structureTypes[0]?.level) {
        case 'level_1':
          icon = GrGroup;
          break;
        case 'level_2':
          icon = BiUnite;
          break;
        case 'level_3':
          icon = IoGitNetwork;
          break;
        case null:
          icon = FaStamp;
          break;
        default:
          icon = null;
      }
      break;
    default:
      icon = BiNetworkChart;
  }
  return icon;
};

export const isAdministrativeEntity = (organisation) => {
  return organisation.structureTypes.some((st) => st.code === 'administrative-entity');
};

export const organisationTypeLabel = (organisation) => {
  if (!organisation) return null;

  const firstNonGenericStructureType = organisation.structureTypes
    ? organisation.structureTypes?.filter((st) => !st.generic)[0]
    : null;
  const firstStructureType = organisation.structureTypes ? organisation.structureTypes[0] : null;
  let label;
  switch (organisation.type) {
    case 'Institution':
      label = 'Institution';
      break;
    case 'Structure':
      label = (firstNonGenericStructureType || firstStructureType)?.longNameFr;
      break;
    default:
      label = '';
  }
  return label;
};
