import { apiV1 } from './api';

const getStreamsList = (params = {}) => apiV1.get('/streams', { params });

const getStream = (params) => {
  const { streamId } = params;
  return apiV1.get(`/streams/${streamId}`, { params });
};

const createStream = (params = {}) => apiV1.post('/streams', { params });

const deleteStreams = ({ params }) => apiV1.put(`/streams/delete_multiple`, params);

const stopStreams = ({ params }) => apiV1.put(`/streams/stop`, params);

export { getStreamsList, getStream, createStream, deleteStreams, stopStreams };
