import React, { useMemo } from 'react';
import {
  Badge,
  Box,
  Circle,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineDelete, HiPencil } from 'react-icons/all';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils';
import { createCommand } from '../../../features/commands/commandsSlice';
import { updateOnePeople } from '../../../features/people/peopleSlice';
import { ConfirmDialog } from '../ConfirmDialog';
import { relationshipClassLabels } from '../../utils/relationships-utils';
import { commandSuccess } from '../../utils/commands-utils';
import { displayErrorToast } from '../../utils/toasts-utils';
import { RelationshipFormModal } from '../../../features/relationships/components/RelationshipFormModal';
import { fetchFunctionTypes } from '../../../features/relationships/functionsSlice';
import { routes } from '../../routes/routes';
import { formatDate } from '../../utils/date-utils';

export const RelationshipItem = ({
  person,
  entity,
  entityName,
  organisation,
  relationshipType,
  icon,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRelationShipChange = (code, newValue) => {
    dispatch(
      createCommand({
        code,
        newValue,
        id: entity.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const style = useMemo(() => {
    if (entity.past) {
      return { bgColor: 'gray.50', color: 'gray.500', borderColor: 'gray.200' };
    }

    if (entity.future) {
      return {
        bgColor: 'blue.50',
        borderColor: 'blue.100',
      };
    }

    return {
      borderColor: 'gray.200',
    };
  }, [entity]);

  const relationshipClassName = relationshipClassLabels[entityName];

  const status = useMemo(() => {
    if (entity.past) return 'Passée';
    if (entity.future) return 'Future';
    return 'En cours';
  }, [entity.past, entity.future]);

  const statusTagColor = useMemo(() => {
    if (entity.past) return null;
    if (entity.future) return 'blue';
    return 'green';
  }, [entity.past, entity.future]);

  return (
    <Grid
      templateColumns="auto repeat(7, 1fr)"
      gap={4}
      key={`key_${entity.id}`}
      border="1px"
      padding="5"
      rounded="md"
      pos="relative"
      {...style}
    >
      <GridItem rowStart={1} colStart={1}>
        <Circle
          // eslint-disable-next-line no-nested-ternary
          bg={entity.future ? 'blue.100' : entity.past ? 'gray.200' : 'gray.300'}
          padding="2"
          flexDirection="row"
          display="inline-flex"
        >
          <Icon
            as={icon}
            boxSize="1.75em"
            margin={1.5}
            // eslint-disable-next-line no-nested-ternary
            color={entity.future ? 'blue.500' : entity.past ? 'gray.500' : 'gray.700'}
            opacity="0.6"
          />
        </Circle>
      </GridItem>
      <GridItem rowStart={1} colStart={2} colSpan={3} pl={4}>
        <Heading as="h3" fontSize="xl">
          {capitalizeFirstLetter(relationshipType.longNameFr)}
        </Heading>
        <Text mt={0.5} opacity="0.8">
          <Link
            as={NavLink}
            to={{
              pathname: `${routes.organisations}/${organisation.id}`,
              state: { from: location.pathname, search: location.search },
            }}
          >
            {organisation.titleFr}
          </Link>
        </Text>
        <HStack mt={4} alignItems="baseline">
          <Badge
            size="sm"
            colorScheme={entity.future ? 'blue' : null}
            color="currentcolor"
            lineHeight="shorter"
          >
            Titre (fr) :
          </Badge>
          <Text>{entity.longTitleFr}</Text>
        </HStack>

        <HStack mt={1} alignItems="baseline">
          <Badge
            size="sm"
            colorScheme={entity.future ? 'blue' : null}
            color="currentcolor"
            lineHeight="shorter"
          >
            Titre (en) :
          </Badge>
          <Text>{entity.longTitleEn}</Text>
        </HStack>
      </GridItem>
      <GridItem colStart={5} colEnd={-1} rowStart={1} pr={10}>
        <Grid autoFlow="column" gap={4}>
          <GridItem gridColumn="1/span 2" rowStart={2}>
            <Tag colorScheme={statusTagColor} color="currentcolor">
              {status}
            </Tag>
          </GridItem>
          <HStack spacing={5}>
            <Text width="15ch">
              Du&nbsp;
              <strong>{entity.startDate ? formatDate(entity.startDate) : '-'}</strong>
            </Text>
            <Text width="15ch">
              Au&nbsp;
              <strong>{entity.endDate ? formatDate(entity.endDate) : '-'}</strong>
            </Text>
          </HStack>
        </Grid>
      </GridItem>
      {entityName === 'membership' && (
        <GridItem colStart={5} colEnd={-1} rowStart={2} pr={10}>
          <strong>Classe de membres :</strong> {entity.memberClass}
        </GridItem>
      )}
      <Box
        pos="absolute"
        top={2}
        right={2}
        width={9}
        p={1}
        borderWidth="1px"
        borderRadius="lg"
        backgroundColor="gray.100"
      >
        <IconButton
          size="md"
          aria-label="Editer"
          title="Editer"
          variant="action"
          icon={<HiPencil />}
          rounded="full"
          onClick={onOpen}
        />
        <ConfirmDialog
          control={
            <IconButton
              size="md"
              aria-label="Supprimer"
              title="Supprimer"
              variant="ghost"
              colorScheme="red"
              icon={<AiOutlineDelete />}
              rounded="full"
            />
          }
          actionText="Supprimer"
          headerText={`Suppression d'${relationshipClassName}`}
          mainText={`Voulez-vous vraiment retirer la ${relationshipClassName} "${relationshipType.longNameFr}"`}
          callback={() => {
            handleRelationShipChange(`remove_${entityName}_from_user`);
          }}
        />
        <RelationshipFormModal
          mode="edit"
          person={person}
          entity={entity}
          entityName={entityName}
          isOpen={isOpen}
          onClose={onClose}
          relationTypeFetchFunction={fetchFunctionTypes}
          organisation={organisation}
          {...props}
        />
      </Box>
    </Grid>
  );
};
