import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Link,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AiOutlineSearch, GoLinkExternal } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { HiCheck } from 'react-icons/hi';
import InputMask from 'react-input-mask';

import { Metadata } from '../../../../Metadata';
import {
  Detail,
  DetailGroup,
  DetailGroupCol,
  DetailGroupTitle,
  DetailRow,
  DetailRowLabel,
} from '../../../../common/components/Detail';
import { createCommand } from '../../../commands/commandsSlice';
import { updateOnePeople } from '../../peopleSlice';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import LogoIdHal from '../../../../assets/images/idhal.png';
import LogoIdref from '../../../../assets/images/idref.png';
import LogoLdap from '../../../../assets/images/ldap.png';
import LogoIsni from '../../../../assets/images/isni.png';
import LogoOrcid from '../../../../assets/images/orcid.png';
import LogoVivo from '../../../../assets/images/vivo.png';
import { check } from '../../../roleBasedAccess/Can';
import rules from '../../../roleBasedAccess/rules';
import { isEmpty } from '../../../../common/utils/string-utils';

const tabName = 'Identifiants';

const authModes = {
  cas_ldap: {
    value: 'cas_ldap',
    label: 'Annuaire EHESS',
    sentence: "[USER] s'authentifie avec son login [ID] sur l'annuaire de l'EHESS.",
    field: 'ldapUid',
    enabled: true,
  },
  shibboleth: {
    value: 'shibboleth',
    label: 'Compte universitaire',
    sentence: "[USER] s'authentifie avec son email [ID] sur l'annuaire de son établissement.",
    field: 'contactEmailAddress',
    enabled: true,
  },
  google_open_id: {
    value: 'google_open_id',
    label: 'Compte Google',
    sentence: "[USER] s'authentifie avec son compte Google [ID].",
    field: 'contactEmailAddress',
    enabled: true,
  },
  hal: {
    value: 'hal',
    label: 'Compte HAL',
    sentence: "[USER] s'authentifie avec son compte Hal [ID].",
    field: 'idhalS',
    enabled: false,
  },
  not_set: {
    value: 'not_set',
    label: 'Non défini',
    sentence:
      "[USER] ne peut s'authentifier ni sur Sangam ni sur aucune application du système d'information recherche de l'EHESS.",
    field: 'idhalS',
    enabled: true,
  },
  orcid: {
    value: 'orcid',
    label: 'ORCID',
    sentence: "[USER] s'authentifie avec son compte ORCID [ID]",
    field: 'orcid',
    enabled: false,
  },
};

const identifiers = {
  vivoId: {
    baseIdentifier: true,
    name: 'Identifiant EHESS',
    command: 'update_user_vivo_id',
    logo: LogoVivo,
    uri: 'https://vivo.ehess.fr/display/n[ID]',
    hint: 'La fin de votre URI sur Vivo après le "n", ex&nbsp;: https://vivo.ehess.fr/display/n<b>2pd3qf1oi</b>',
    suffix: 'https://vivo.ehess.fr/display/n',
    mask: '*********',
    maskPlaceholder: ' ',
  },
  ldapUid: {
    baseIdentifier: true,
    name: 'Login LDAP',
    command: 'update_user_ldap_uid',
    logo: LogoLdap,
    uri: 'https://pagesblanches.aria.ehess.fr/personne/[ID]',
    hint: 'Généralement la 1ère lettre du prénom et les 7 premières lettres du nom, ex.&nbsp;: <b>jdupont</b>',
    suffix: 'https://pagesblanches.aria.ehess.fr/personne/',
    mask: 'aaaaa*a*a*9',
    maskPlaceholder: ' ',
  },
  idrefId: {
    baseIdentifier: false,
    name: 'Identifiant Idref',
    command: 'update_user_idref_id',
    logo: LogoIdref,
    uri: 'https://www.idref.fr/[ID]',
    search: 'https://www.idref.fr/',
    hint: 'Identifiant numérique («PPN»), ex.&nbsp;:  <b>123456789</b>',
    valid: (v) => v,
    suffix: 'https://www.idref.fr/',
    mask: '999999999',
    maskPlaceholder: '_',
  },
  isniId: {
    baseIdentifier: false,
    name: 'Identifiant Isni',
    command: 'update_user_isni_id',
    logo: LogoIsni,
    uri: 'https://isni.org/isni/[ID]',
    search: 'https://isni.org/page/search-database/',
    hint: 'Identifiant numérique avec ou sans espaces, ex.&nbsp;: <b>0000 0001 1041 5486</b>',
    suffix: 'https://isni.org/isni/',
    mask: '9999 9999 9999 999*',
    maskPlaceholder: '_',
  },
  orcidId: {
    baseIdentifier: true,
    name: 'Identifiant ORCID',
    command: 'update_user_orcid_id',
    logo: LogoOrcid,
    uri: 'https://orcid.org/[ID]',
    search: 'https://orcid.org/orcid-search/search',
    hint: 'Identifiant numérique avec ou sans tirets, ex.&nbsp;: <b>0000-0001-5678-2345</b>',
    suffix: 'https://orcid.org/',
    mask: '9999-9999-9999-999*',
    maskPlaceholder: '_',
  },
  idhalI: {
    baseIdentifier: false,
    name: 'Identifiant HAL numérique',
    command: 'update_user_idhal_i',
    logo: LogoIdHal,
    uri: 'https://aurehal.archives-ouvertes.fr/author/read/id/[ID]',
    search: 'https://aurehal.archives-ouvertes.fr/author/index',
    hint: 'Identifiant de forme auteur, ex.&nbsp;:<b>11944716</b>',
    suffix: 'https://aurehal.archives-ouvertes.fr/author/read/id/',
    mask: '9999999999999999',
    maskPlaceholder: ' ',
  },
  idhalS: {
    baseIdentifier: false,
    name: 'Identifiant HAL alphanumérique',
    command: 'update_user_idhal_s',
    logo: LogoIdHal,
    uri: 'https://cv.archives-ouvertes.fr/[ID]',
    search: 'https://aurehal.archives-ouvertes.fr/author/index',
    hint: 'nom-prenom en minuscules sans accent, ex.&nbsp;: <b>jean-dupont</b>',
    suffix: 'https://cv.archives-ouvertes.fr/',
    // mask: [/[a-z]/, /-/, /[a-z]/],
    maskPlaceholder: ' ',
  },
};

export const Identifiers = ({ person }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.currentUser);

  const canEditBaseIdentifiers = useMemo(() => {
    return check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'admin' });
  }, [currentUser]);

  const [identifierErrors, setIdentifierErrors] = useState({});
  const [currentValues, setCurrentValues] = useState({
    vivoId: person.vivoId,
    ldapUid: person.ldapUid,
    idref: person.idref,
    idhalI: person.idHalI,
    idhalS: person.idHalS,
    orcidId: person.orcidId,
    isniId: person.isniId,
  });

  const authModeSentence = useMemo(() => {
    return authModes[person.authMode]
      ? authModes[person.authMode].sentence
          .replace('[ID]', person[authModes[person.authMode].field])
          .replace('[USER]', person.displayName)
      : '';
  }, [person]);

  const canEditOtherIdentifiers = useMemo(() => {
    return (
      check(rules, currentUser.roles, 'app:perimeter', { user: currentUser, object: person }) ||
      check(rules, currentUser.roles, 'app:autoedit', { user: currentUser, object: person }) ||
      check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'admin' }) ||
      check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'rdd-member' }) ||
      check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'rdd-direction' })
    );
  }, [currentUser, person]);

  const invalidField = useCallback(
    (key) => {
      if (isEmpty(person[key])) {
        return false;
      }
      if (currentValues[key] === person[key]) {
        return false;
      }
      return identifierErrors[key];
    },
    [identifierErrors, person],
  );

  const handleIdentifierUpdate = (key) => {
    dispatch(
      createCommand({
        code: identifiers[key].command,
        newValue: currentValues[key]?.replace(/[_ ]/g, ''),
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        setIdentifierErrors({ ...identifierErrors, [key]: false });
        setCurrentValues({ ...currentValues, [key]: data.command?.payload?.user[key] });
        commandSuccess(data.command);
      })
      .catch((err, params = {}) => {
        setIdentifierErrors({ ...identifierErrors, [key]: true });
        displayErrorToast(err, params);
      });
  };
  const handleAuthModeUpdate = (newValue) => {
    dispatch(
      createCommand({
        code: 'update_user_auth_mode',
        newValue,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
      });
  };

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${person.displayName}`}
        descriptionSuffix={`Identifiants de ${person.displayName}`}
      />
      <DetailGroup mb={25}>
        <DetailGroupCol>
          <DetailGroupTitle>Authentification</DetailGroupTitle>
        </DetailGroupCol>
        <DetailGroupCol>
          <Detail>
            <DetailRow gridTemplateColumns="20rem 35rem" py="7">
              <DetailRowLabel>Mode d'authentification </DetailRowLabel>
              <Select
                onChange={(e) => {
                  handleAuthModeUpdate(e.target.value);
                }}
                value={person.authMode}
              >
                {Object.entries(authModes).map((el) => {
                  return (
                    <option
                      key={el[0]}
                      value={el[0]}
                      disabled={!el[1].enabled}
                      selected={
                        el[0] === person.authMode || (!person.authMode && el[0] === 'not_set')
                      }
                    >
                      {el[1].label}
                    </option>
                  );
                })}
              </Select>
              <Text />
              <Text p={3} backgroundColor="gray.50" mt={2} rounded={10}>
                {authModeSentence}
              </Text>
            </DetailRow>
          </Detail>
        </DetailGroupCol>
      </DetailGroup>
      <VStack spacing={5} alignItems="stretch">
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>{tabName}</DetailGroupTitle>
          </DetailGroupCol>

          <DetailGroupCol>
            <Detail>
              {Object.keys(identifiers).map((key) => {
                const identifierData = identifiers[key];
                return (
                  <DetailRow gridTemplateColumns="15rem 5rem 35rem 2rem" py="7" key={`id-${key}`}>
                    <HStack mr={2}>
                      <Image
                        boxSize="50px"
                        src={identifierData.logo}
                        fit="contain"
                        mr="2"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center"
                      />
                      <VStack align="left">
                        <DetailRowLabel>{identifierData.name} </DetailRowLabel>
                        {identifierData.search && (
                          <Link href={identifierData.search} target="_blank" rel="noreferrer">
                            <Button
                              size="xs"
                              rightIcon={<AiOutlineSearch />}
                              colorScheme="teal"
                              variant="outline"
                            >
                              Rechercher
                            </Button>
                          </Link>
                        )}
                      </VStack>
                    </HStack>
                    <Link
                      href={
                        person[key]
                          ? identifierData.uri.replace('[ID]', person[key].replace(/\s/g, ''))
                          : null
                      }
                      isExternal
                      width="18rem"
                      ml={8}
                      disabled={person[key]}
                      style={{ visibility: person[key] ? 'visible' : 'hidden' }}
                    >
                      <GoLinkExternal mx="2px" variant={person[key] ? 'normal' : 'ghost'} />
                    </Link>

                    <VStack align="start">
                      <InputGroup>
                        <InputLeftAddon>{identifierData.suffix}</InputLeftAddon>
                        <InputMask
                          mask={identifierData.mask}
                          alwaysShowMask={
                            (identifierData.baseIdentifier && canEditBaseIdentifiers) ||
                            (!identifierData.baseIdentifier && canEditOtherIdentifiers)
                          }
                          maskPlaceholder={identifierData.maskPlaceholder}
                          formatChars={{
                            9: '[0-9]',
                            a: '[A-Za-z]',
                            '?': '[0-9xX]',
                            '*': '[A-Za-z0-9]',
                          }}
                          value={currentValues[key] || person[key]}
                          disabled={
                            (identifierData.baseIdentifier && !canEditBaseIdentifiers) ||
                            (!identifierData.baseIdentifier && !canEditOtherIdentifiers)
                          }
                          onChange={(e) => {
                            setCurrentValues({
                              ...currentValues,
                              [key]: e.target.value,
                            });
                          }}
                        >
                          <Input isInvalid={invalidField(key)} />
                        </InputMask>
                        <InputRightElement>
                          <IconButton
                            colorScheme="teal"
                            aria-label="Valider"
                            size="md"
                            icon={<HiCheck />}
                            disabled={currentValues[key] === person[key]}
                            onClick={() => handleIdentifierUpdate(key)}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <Text
                        color="gray.500"
                        fontSize="xs"
                        dangerouslySetInnerHTML={{
                          __html: identifierData.hint,
                        }}
                      />
                    </VStack>
                  </DetailRow>
                );
              })}
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
      </VStack>
    </>
  );
};
