import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import { DetailGroupTitle } from '../../../../common/components/Detail';
import { RichTextEditor } from '../../../../common/components/RichTextEditor';
import { createCommand } from '../../../commands/commandsSlice';
import { updateOnePeople } from '../../peopleSlice';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import { Metadata } from '../../../../Metadata';
import { VocabPicker } from '../../../../common/components/VocabPicker';

const tabName = 'Présentation';

export const Presentation = ({ person }) => {
  const dispatch = useDispatch();

  const [descSaveBtnEnabled, setDescSaveBtnEnabled] = useState(false);
  const [researchDescSaveBtnEnabled, setResearchDescSaveBtnEnabled] = useState(false);
  const [teachingDescSaveBtnEnabled, setTeachingDescSaveBtnEnabled] = useState(false);

  const handleSaveDescription = (code, newValue, disableSaveButton) => {
    dispatch(
      createCommand({
        code,
        newValue,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        disableSaveButton(false);
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch((err, params = {}) => {
        disableSaveButton(false);
        displayErrorToast(err, params);
      });
  };

  const handleTagChange = (vocab, newValue) => {
    dispatch(
      createCommand({
        code: 'update_user_tags',
        newValue,
        vocab,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
      });
  };

  const handleSaveOverallDescription = (newHTML) => {
    handleSaveDescription('update_user_description', newHTML, setDescSaveBtnEnabled);
  };

  const handleSaveResearchDescription = (newHTML) => {
    handleSaveDescription(
      'update_user_research_description',
      newHTML,
      setResearchDescSaveBtnEnabled,
    );
  };

  const handleSaveTeachingDescription = (newHTML) => {
    handleSaveDescription(
      'update_user_teaching_description',
      newHTML,
      setTeachingDescSaveBtnEnabled,
    );
  };

  const getConceptsForVocab = useCallback(
    (vocab) =>
      person.concepts
        .filter((concept) => concept.vocab === vocab)
        .map((concept) => {
          return { ...concept, value: concept.key };
        }),
    [person],
  );

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${person.displayName}`}
        descriptionSuffix={`Présentation de ${person.displayName}`}
      />
      <VStack spacing={5} alignItems="stretch">
        <DetailGroupTitle fontSize="2xl" position="static">
          Thèmes de recherche & enseignement
        </DetailGroupTitle>
        <Tabs>
          <TabList>
            <Tab>Domaine(s) EHESS</Tab>
            <Tab>Domaine(s) HCERES</Tab>
            <Tab>Aire(s) culturelle(s)</Tab>
            <Tab>Mots-clés</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <VocabPicker
                vocabularyName="domehess"
                handleTagChange={handleTagChange}
                selectedOptions={getConceptsForVocab('domehess')}
              />
            </TabPanel>
            <TabPanel>
              <VocabPicker
                vocabularyName="domhceres"
                handleTagChange={handleTagChange}
                selectedOptions={getConceptsForVocab('domhceres')}
              />
            </TabPanel>
            <TabPanel>
              <VocabPicker
                vocabularyName="airecult"
                handleTagChange={handleTagChange}
                selectedOptions={getConceptsForVocab('airecult')}
              />
            </TabPanel>
            <TabPanel>
              <VocabPicker
                vocabularyName="motehess"
                handleTagChange={handleTagChange}
                selectedOptions={getConceptsForVocab('motehess')}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      <VStack spacing={5} alignItems="stretch">
        <DetailGroupTitle fontSize="2xl" position="static">
          Description
        </DetailGroupTitle>
        <Tabs>
          <TabList>
            <Tab>{descSaveBtnEnabled && '* '}Présentation générale</Tab>
            <Tab>{researchDescSaveBtnEnabled && '* '}Aperçu des recherches</Tab>
            <Tab>{teachingDescSaveBtnEnabled && '* '}Aperçu des enseignements</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <RichTextEditor
                html={person.description || ''}
                onSave={handleSaveOverallDescription}
                saveButtonEnabled={descSaveBtnEnabled}
                onContentChange={() => setDescSaveBtnEnabled(true)}
              />
            </TabPanel>
            <TabPanel>
              <RichTextEditor
                html={person.researchDescription || ''}
                onSave={handleSaveResearchDescription}
                saveButtonEnabled={researchDescSaveBtnEnabled}
                onContentChange={() => setResearchDescSaveBtnEnabled(true)}
              />
            </TabPanel>
            <TabPanel>
              <RichTextEditor
                html={person.teachingDescription || ''}
                onSave={handleSaveTeachingDescription}
                saveButtonEnabled={teachingDescSaveBtnEnabled}
                onContentChange={() => setTeachingDescSaveBtnEnabled(true)}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
};
