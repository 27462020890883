import React from 'react';
import { Box, chakra, Flex, Grid, Heading, Image, Link, Spacer, Text } from '@chakra-ui/react';
import Logo from '../../assets/images/Logo_EHESS_2021_Blanc_210x210.png';
import { Metadata } from '../../Metadata';

const Informations = () => {
  return (
    <>
      <Metadata titleSuffix="Mentions légales" description="Mentions légales" />
      <Flex minH="100vh">
        <chakra.section flexGrow="1" color="white" backgroundColor="blue.800" p={10}>
          <Heading as="h1" textAlign="center" mb={10}>
            <Flex direction="row" alignItems="center">
              <Image
                boxSize={['80px', '150px']}
                objectFit="cover"
                src={Logo}
                alt="École de hautes études en sciences sociales"
              />
              <Spacer />
              <Heading size={['1xl', '1xl', '2xl']}>Mentions légales</Heading>
              <Spacer />
              <Box pt={5} pr={3} fontSize={['1xl', '2xl', '3xl']}>
                சங்கம் <br />
                caṅkam <br />
                sangam
              </Box>
            </Flex>
          </Heading>

          <Grid gap={10}>
            <Box experimental_spaceY={2}>
              <Heading fontSize="xl">Éditeur et hébergeur</Heading>
              <Heading fontSize="sm">EHESS - École des hautes études en sciences sociales</Heading>
              <Text>
                54, boulevard Raspail - 75006 Paris (siège)
                <br />
                Téléphone : +33 (0)1 49 54 25 25
              </Text>
            </Box>
            <Box experimental_spaceY={2}>
              <Heading fontSize="xl">Directeur de la publication</Heading>
              <Text>Christophe Prochasson, président de l'EHESS</Text>
            </Box>
            <Box experimental_spaceY={2}>
              <Heading fontSize="xl">Crédits</Heading>
              <Heading fontSize="sm">Développement</Heading>
              <Text>
                <a href="https://web.leikir.io/">Leikir Web</a> / Pôle numérique recherche -
                Directions des systèmes d'information de l'EHESS
              </Text>
              <Heading fontSize="sm">Photographies</Heading>
              <Text>Service de la communication de l'EHESS</Text>
            </Box>
            <Box experimental_spaceY={2}>
              {' '}
              <Heading fontSize="xl">Propriété intellectuelle</Heading>
              <Text align="justify">
                L’Éditeur est propriétaire des droits de propriété intellectuelle ou détient les
                droits d'utilisation et de diffusion sur tous les éléments accessibles sur ce Site,
                notamment son contenu, sa structure, et sur les technologies qu’elle met en œuvre
                pour son exploitation (textes, documents téléchargeables, images et icônes, vidéos,
                mise en forme, pour charte graphique et feuille de style, code). Toute reproduction,
                représentation, modification, publication, adaptation de tout ou partie des éléments
                du Site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf
                autorisation écrite préalable de l’Éditeur.
              </Text>
            </Box>
            <Box experimental_spaceY={2}>
              {' '}
              <Heading fontSize="xl">Responsabilité</Heading>
              <Text align="justify">
                {' '}
                L’Éditeur décline toute responsabilité concernant les informations disponibles sur
                son Site et l’utilisation qui peut être faite des informations contenues sur ce site
                ou obtenues en réponse à une question posée par le biais de ce site. En aucun cas
                l’Éditeur ne pourra être tenu pour responsable des dommages de toute nature, directs
                ou indirects, qui résulteraient de l’utilisation de ce site ou en relation avec ce
                site. Les liens hypertextes mis en place dans le cadre du présent site internet en
                direction d'autres ressources présentes sur le réseau internet ne sont proposés que
                pour des raisons de commodité et ni leur contenu ou les liens qu'ils contiennent, ni
                les changements ou mises à jour qui leur sont apportés ne sauraient engager la
                responsabilité de l’Éditeur.
              </Text>
              <Text align="justify">
                {' '}
                L’Éditeur ne garantit pas que le serveur accueillant le site soit exempt de virus et
                que les fichiers accessibles par téléchargement sur ce site web ou tout autre site
                tiers soient dépourvus de virus ou d’erreurs de fonctionnement. Dans la mesure où
                des virus peuvent être transmis via internet, nous recommandons aux utilisateurs de
                prendre les mesures nécessaires afin de protéger leur ordinateur contre toute
                intrusion, contre tout problème technique qui pourrait endommager les composants de
                l'ordinateur de l'Utilisateur ou des données qui pourraient y être stockées. En tout
                état de cause, l’Éditeur ne pourra être responsable d'un dommage quelconque pouvant
                se produire lors de la connexion et de la navigation sur le site.
              </Text>
              <Text align="justify">
                La responsabilité de l’Éditeur ne peut être engagée, même en cas d'erreur ou
                d'omission sur les données transcrites sur ce site internet, quelle qu'elles soient.
                L’Éditeur ne peut garantir que les informations du présent site sont complètes,
                précises, exactes, dépourvues de toute erreur et à jour. Toute information pourra
                être modifiée par l’Éditeur sans avertissement préalable. Les informations fournies
                par l’Éditeur le sont à titre indicatif et ne sauraient dispenser l’utilisateur
                d’une analyse complémentaire et personnalisée. L’Utilisateur reconnaît donc utiliser
                ces informations sous sa responsabilité exclusive.
              </Text>
            </Box>
            <Box experimental_spaceY={2}>
              {' '}
              <Heading fontSize="xl">Données personnelles et gestion des cookies</Heading>
              <Text align="justify">
                Un cookie désigne un fichier texte qu’un serveur web (le site web) dépose sur votre
                terminal (sur le disque dur de votre ordinateur ou smartphone, ou dans la mémoire de
                votre navigateur) lorsque vous visitez et utilisez des sites web et des services en
                ligne. Il contient plusieurs données : le nom du serveur qui l’a déposé, un
                identifiant sous forme de numéro unique, éventuellement une date d’expiration. Il
                permet de reconnaître votre terminal lorsque vous revenez sur un site web. En effet,
                ce n’est pas vous qui êtes reconnu mais le terminal depuis lequel vous visitez un
                site web. Vous disposez de différents moyens pour gérer les cookies. Vous pouvez à
                tout moment choisir de désactiver tout ou partie des cookies.{' '}
              </Text>
              <Text align="justify">
                Ce site dépose uniquement des cookies nécessaires au fonctionnement du site. Ces
                cookies permettent au site de fonctionner de manière optimale. Vous pouvez vous y
                opposer et les supprimer en utilisant les paramètres de votre navigateur, cependant
                votre expérience utilisateur risque d’être dégradée. Pour en savoir plus, vous
                pouvez consulter la fiche pratique sur le{' '}
                <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">
                  site de la CNIL : https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
                </a>
                .
              </Text>
            </Box>
            <Box experimental_spaceY={2}>
              <Heading fontSize="xl">Droit applicable</Heading>
              <Text align="justify">
                {' '}
                L’utilisation de ce site est régie par le droit français, quel que soit le lieu
                d’utilisation. En cas de contestation éventuelle, et après l’échec de toute
                tentative de recherche d’une solution amiable, les tribunaux français seront seuls
                compétents pour connaître de ce litige. Évolutions du site Les éléments présentés
                dans ce présent site sont susceptibles de modification sans préavis et sont mis à
                disposition sans aucune garantie, expresse ou implicite, d'aucune sorte et ne
                peuvent donner lieu à un quelconque droit à dédommagement. Dernière mise à jour de
                la page : 10/06/2022
              </Text>
            </Box>
            <Box alignItems="center">
              <Text color="grey.800">
                {' '}
                © École des Hautes Études en Sciences Sociales 2021 ·{' '}
                <Link href="/mentions-legales" target="_blank">
                  Mentions legales
                </Link>
              </Text>
            </Box>
          </Grid>
        </chakra.section>
      </Flex>
    </>
  );
};

export default Informations;
