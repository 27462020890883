import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Can from '../roleBasedAccess/Can';
import { PeopleIndex } from './PeopleIndex/PeopleIndex';
import { PeopleShow } from './PeopleShow/PeopleShow';

const People = () => {
  const { user } = useSelector((state) => state.currentUser);
  const match = useRouteMatch();

  return (
    <Can
      perform="app:use"
      roles={user.roles}
      data={{ user }}
      yes={() => (
        <Switch>
          <Route path={`${match.url}`} component={PeopleIndex} exact />
          <Route path={`${match.url}/:peopleId`} component={PeopleShow} />
          <Route path="*">Erreur 404 - Page introuvable</Route>
        </Switch>
      )}
      no={() => (
        <Switch>
          <Redirect path={`${match.url}`} to={`${match.url}/${user.id}`} exact />
          <Route path={`${match.url}/${user.id}`} component={PeopleShow} />
          <Route path="*">Erreur 404 - Page introuvable</Route>
        </Switch>
      )}
    />
  );
};

export default People;
