import React, { useEffect } from 'react';
import { Checkbox, Flex } from '@chakra-ui/react';
import { DateChooser } from '../Calendar';
import { CustomEditable } from '.';

export const CheckboxAndDate = ({ resource, checkboxName, dateName, onSubmit }) => {
  return (
    <CustomEditable
      hasMultiValue
      defaultValue={{
        [checkboxName]: resource[checkboxName] || false,
        [dateName]: resource[dateName],
      }}
      // Display only backend value in case there is a workflow
      previewDisplay={() =>
        resource[checkboxName] ? `Oui depuis le ${resource[dateName] || ''} ` : 'Non'
      }
      Input={(props) => (
        <CheckboxAndDateControls checkboxName={checkboxName} dateName={dateName} {...props} />
      )}
      onSubmit={onSubmit}
    />
  );
};

export const CheckboxAndDateControls = ({ value, onChange, checkboxName, dateName }) => {
  // Check checkbox when date is filled
  useEffect(() => {
    onChange({ name: checkboxName, newValue: !!value[dateName] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value[dateName]]);

  return (
    <Flex alignItems="center">
      <Checkbox
        id="retired"
        isChecked={value.retired}
        mr={3}
        onChange={(e) => onChange({ name: checkboxName, newValue: e.target.checked })}
      />
      <DateChooser
        defaultDate={value[dateName]}
        handleDateChange={(date) => {
          onChange({ name: dateName, newValue: date });
        }}
        wrapperClassName="react-datepicker-sm"
      />
    </Flex>
  );
};
