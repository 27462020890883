import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { fetchCurrentUserProfile } from '../../features/login/currentUserSlice';
import { PrivateLayout } from '../layouts';
import { routes } from './routes';

export const AppRoute = ({ component: Component, path, isPrivate, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.currentUser);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);

    if (isPrivate) {
      dispatch(fetchCurrentUserProfile());
    }
  }, [dispatch, isPrivate, location.pathname]);

  if (!loggedIn && isPrivate) {
    return <Redirect to={{ pathname: routes.login, state: { from: path } }} />;
  }

  return (
    <Route
      path={path}
      render={() =>
        isPrivate ? (
          <PrivateLayout>
            <Component />
          </PrivateLayout>
        ) : (
          <Component />
        )
      }
      {...rest}
    />
  );
};
