import React, { useMemo } from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { RelationshipsSection } from './components/RelationshipsSection';

export const Relationships = ({ organisation }) => {
  const structureTypeCodes = useMemo(() => {
    return organisation.structureTypes?.map((st) => st.code);
  }, [organisation]);
  return (
    <Grid gridTemplateColumns={{ base: '1fr', '2xl': '1fr 1fr' }} gap="3rem 1.5rem">
      {structureTypeCodes?.includes('administrative-entity') ? (
        <EARelationships organisation={organisation} />
      ) : null}
      {organisation.type === 'Institution' ? (
        <InstitutionRelationships organisation={organisation} />
      ) : null}
      {structureTypeCodes?.includes('level-1-research-organization') ? (
        <StructureLevel1Relationships organisation={organisation} />
      ) : null}
      {structureTypeCodes?.includes('level-2-research-organization') ? (
        <StructureLevel2Relationships organisation={organisation} />
      ) : null}
      {structureTypeCodes?.includes('level-3-research-organization') ? (
        <StructureLevel3Relationships organisation={organisation} />
      ) : null}
    </Grid>
  );
};

const InstitutionRelationships = ({ organisation }) => {
  return (
    <RelationshipsSection
      title="Tutelle de"
      relationships={organisation.supervisions}
      type="child"
      flexGrow="1"
      newElementTitle="une structure"
      newElementType="Structure"
      newElementTypeLabel={`Structure à rattacher à ${organisation.titleFr}`}
      relationType="supervision"
      organisation={organisation}
    />
  );
};

const EARelationships = ({ organisation }) => {
  const supervisionRelationships = Array.from(organisation.supervisions || []);
  const parentInclusionRelationships = organisation.parentInclusion
    ? Array.from([organisation.parentInclusion])
    : [];

  return (
    <>
      <Flex flexDirection="column">
        {parentInclusionRelationships.length === 0 && (
          <RelationshipsSection
            title="Rattachement à une institution"
            relationships={supervisionRelationships}
            type="parent"
            flexGrow="1"
            newElementTitle="une tutelle"
            newElementType="Institution"
            newElementTypeLabel={`Institution tutelle de ${organisation.titleFr}`}
            relationType="supervision"
            organisation={organisation}
          />
        )}
        {supervisionRelationships.length === 0 && (
          <RelationshipsSection
            mt={7}
            title="Rattachement à une entité administrative"
            relationships={parentInclusionRelationships}
            type="parent"
            flexGrow="1"
            newElementTitle="une appartenance"
            newElementType="Structure"
            newElementTypeLabel={`Entité administrative de rattachement de ${organisation.titleFr}`}
            relationType="inclusion"
            organisation={organisation}
          />
        )}
      </Flex>
      <RelationshipsSection
        title="Composantes"
        relationships={organisation.childInclusions}
        type="child"
        flexGrow="1"
        newElementTitle="une composante"
        newElementType="Structure"
        newElementTypeLabel={`Structure à rattacher à ${organisation.titleFr}`}
        relationType="inclusion"
        organisation={organisation}
      />
    </>
  );
};

const StructureLevel1Relationships = ({ organisation }) => {
  const supervisionRelationships = Array.from(organisation.supervisions || []);
  if (organisation.parentInclusion) supervisionRelationships.push(organisation.parentInclusion);

  return (
    <>
      <RelationshipsSection
        title="Rattachement"
        relationships={supervisionRelationships}
        type="parent"
        flexGrow="1"
        newElementTitle="une appartenance"
        newElementType="Structure"
        newElementTypeLabel={`Structure de rattachement de ${organisation.titleFr}`}
        relationType="inclusion"
        organisation={organisation}
      />
      <RelationshipsSection
        title="Composantes"
        relationships={organisation.childInclusions}
        type="child"
        flexGrow="1"
        newElementTitle="une composante"
        newElementType="Structure"
        newElementTypeLabel={`Structure à rattacher à ${organisation.titleFr}`}
        relationType="inclusion"
        organisation={organisation}
      />
    </>
  );
};

const StructureLevel2Relationships = ({ organisation }) => {
  const supervisionRelationships = organisation.supervisions || [];
  if (organisation.parentInclusion) supervisionRelationships.push(organisation.parentInclusion);

  return (
    <>
      <RelationshipsSection
        title="Tutelles"
        relationships={supervisionRelationships}
        type="parent"
        flexGrow="1"
        newElementTitle="une tutelle"
        newElementType="Institution"
        newElementTypeLabel={`Institution tutelle de ${organisation.titleFr}`}
        relationType="supervision"
        organisation={organisation}
      />
      <RelationshipsSection
        title="Composantes"
        relationships={organisation.childInclusions}
        type="child"
        flexGrow="1"
        newElementTitle="une composante"
        newElementType="Structure"
        newElementTypeLabel={`Structure à rattacher à ${organisation.titleFr}`}
        relationType="inclusion"
        organisation={organisation}
      />
    </>
  );
};

const StructureLevel3Relationships = ({ organisation }) => {
  return (
    <>
      <RelationshipsSection
        title="Tutelles"
        relationships={organisation.supervisions}
        type="parent"
        flexGrow="1"
        newElementTitle="une tutelle"
        newElementType="Institution"
        newElementTypeLabel={`Institution tutelle de ${organisation.titleFr}`}
        relationType="inclusion"
        organisation={organisation}
      />
      <RelationshipsSection
        title="Unités participantes"
        relationships={organisation.childInclusions}
        type="child"
        flexGrow="1"
        newElementTitle="une composante"
        newElementType="Structure"
        newElementTypeLabel={`Structure à rattacher à ${organisation.titleFr}`}
        relationType="inclusion"
        organisation={organisation}
      />
    </>
  );
};
