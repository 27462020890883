import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCurrentUserProfile } from './features/login/currentUserSlice';
import { addMessage } from './features/notifications/notificationsSlice';

export const WS = () => {
  const socketUrl = process.env.REACT_APP_WS_URL;
  const [title, setTitle] = useState('Zéro message');
  const [text, setText] = useState('');
  const dispatch = useDispatch();

  const onClose = () => {
    return console.log;
  };

  const onOpen = (socket) => {
    return () => {
      const connexionMessage = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'MessageChannel',
        }),
      };
      return socket.send(JSON.stringify(connexionMessage));
    };
  };

  useEffect(() => {
    const socket = new WebSocket(socketUrl);
    socket.onmessage = (event) => {
      let message = null;
      let parsedData = null;
      try {
        parsedData = JSON.parse(event.data);
      } catch (e) {
        console.log(e);
      }

      if (parsedData && typeof parsedData.message === 'object') {
        try {
          message = JSON.parse(parsedData.message.content);
          setTitle(message?.title_fr);
          setText(message?.text_fr);
          dispatch(addMessage(message));
          dispatch(fetchCurrentUserProfile());
        } catch (e) {
          console.log(e);
        }
      }
    };
    socket.onclose = onClose(socket);
    socket.onopen = onOpen(socket);
  }, [dispatch, title, text]);

  return null;
};
