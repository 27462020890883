import React, { useMemo } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Redirect, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { theme } from './theme';
import { routes } from './common/routes/routes';
import { AppRoute } from './common/routes/AppRoute';
import { Login } from './features/login';
import { Organisations } from './features/organisations';
import { Errors } from './features/errors';
import { Logout } from './features/logout';
import { Users } from './features/users';
import { check } from './features/roleBasedAccess/Can';
import rules from './features/roleBasedAccess/rules';
import { Messages } from './features/messages';
import { Notifications } from './features/notifications';
import { People } from './features/people';
import { Contracts } from './features/contracts';
import { Instances } from './features/instances';
import { Extractions } from './features/extractions';
import { Streams } from './features/streams';
import { logout } from './features/login/currentUserSlice';
import { Informations } from './features/informations';

function App() {
  const { loggedIn, user } = useSelector((state) => state.currentUser);
  const [cookies, setCookie, removeCookie] = useCookies(['redirect']);
  const dispatch = useDispatch();

  const homePath = useMemo(() => {
    if (!loggedIn && !cookies.redirect && window.location.pathname !== '/') {
      setCookie('redirect', window.location.pathname + window.location.search, {
        path: '/',
        maxAge: 90,
      });
      return routes.login;
    }
    if (loggedIn && check(rules, user.roles, 'app:autoedit', { user })) {
      const { redirect } = cookies;
      if (redirect) {
        removeCookie('redirect');
        return redirect;
      }
      if (check(rules, user.roles, 'app:use', { user })) {
        return routes.organisations;
      }
      return `${routes.people}/${user.id}`;
    }
    if (loggedIn && !user.id) {
      /*
      User is logged in but missing in database : data has changed
      */
      dispatch(logout());
    }
    return routes.login;
  }, [user, loggedIn]);

  return (
    <ChakraProvider theme={theme}>
      <Errors />
      <Notifications />
      <Switch>
        <AppRoute path={routes.login} component={Login} exact />
        <AppRoute path={routes.logout} component={Logout} exact />
        <AppRoute path={routes.organisations} component={Organisations} isPrivate />
        <AppRoute path={routes.people} component={People} isPrivate />
        <AppRoute path={routes.contracts} component={Contracts} isPrivate />
        <AppRoute path={routes.instances} component={Instances} isPrivate />
        <AppRoute path={routes.extractions} component={Extractions} isPrivate />
        <AppRoute path={routes.users} component={Users} isPrivate />
        <AppRoute path={routes.messages} component={Messages} isPrivate />
        <AppRoute path={routes.streams} component={Streams} isPrivate />
        <AppRoute path={routes.informations} component={Informations} exact />
        <AppRoute
          path={routes.home}
          render={() => <Redirect path={routes.home} to={homePath} exact />}
          exact
        />
        <AppRoute>Cette page n'est pas encore implémentée</AppRoute>
      </Switch>
    </ChakraProvider>
  );
}

export default App;
