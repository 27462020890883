import { isEmpty } from './string-utils';

export const omit = (obj, omitKey) => {
  return Object.keys(obj)
    .filter((key) => key !== omitKey)
    .reduce((result, key) => ({ ...result, [key]: obj[key] }), {});
};
export const cleanEmptyEntries = (obj) => {
  return Object.keys(obj).reduce(
    (acc, key) => (isEmpty(obj[key]) ? acc : { ...acc, [key]: obj[key] }),
    {},
  );
};
