import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  FormLabel,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import { clear, createCommand } from '../../../commands/commandsSlice';
import { updateOneOrganisation } from '../../organisationsSlice';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import {
  fetchStructureTypes,
  structureTypesSelectors,
} from '../../../structureTypes/structureTypesSlice';
import Select from '../../../../common/components/Select';

export const StructureTypeModal = ({ onClose, isOpen, organisation }) => {
  const dispatch = useDispatch();
  const { error: errorCommand, pending: commandPending } = useSelector((state) => state.commands);

  const { pending: pendingStructureTypes, error: errorStructureTypes } = useSelector(
    (state) => state.structureTypes,
  );

  const structureTypes = useSelector((state) =>
    structureTypesSelectors.selectAll(state.structureTypes),
  );

  useEffect(() => {
    dispatch(fetchStructureTypes());
  }, [dispatch]);

  const getDefaultValue = (terminology = null, generic = false) => {
    const structureType = organisation.structureTypes?.find((structType) =>
      terminology ? structType.terminology === terminology : structType.generic === generic,
    );

    if (!structureType) return null;

    return { label: structureType.longNameFr, value: structureType.id, data: structureType };
  };

  // Form

  const {
    watch,
    control,
    reset,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      genericType: getDefaultValue(null, true),
      nationalType: getDefaultValue('national') || getDefaultValue(null, false),
      localType: getDefaultValue('local'),
      specificType: getDefaultValue('spécifique'),
    },
  });

  const { genericType } = watch();

  const polymorphicTypingDisabled = useMemo(() => {
    if (genericType) {
      return genericType?.data.code.match(/administrative|institution/);
    }

    return true;
  }, [genericType]);

  useEffect(() => {
    if (!dirtyFields.genericType) return;
    setValue('nationalType', null);
    setValue('localType', null);
    setValue('specificType', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericType]);

  useEffect(() => {
    setValue('genericType', getDefaultValue(null, true));
    setValue('nationalType', getDefaultValue('national') || getDefaultValue(null, false));
    setValue('localType', getDefaultValue('local'));
    setValue('specificType', getDefaultValue('specific'));
  }, [isOpen]);

  const handleReset = () => {
    reset();
    dispatch(clear());
    onClose();
  };

  const handleSubmit = () => {
    const values = getValues();
    const payload = Object.entries(values).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return { [`${key}_id`]: value.data.id, ...acc };
    }, {});

    dispatch(
      createCommand({
        code: 'update_organisation_structure_type',
        ...payload,
        organisationId: organisation.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
        handleReset();
      })
      .catch((err) => {
        if (err.message) {
          displayErrorToast(err);
        }
      });
  };

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={() => {
        dispatch(clear());
        handleReset();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Éditer le type de structure de {organisation?.titleFr}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert
            status="error"
            mb={5}
            display={errorCommand && errorCommand?.base ? 'flex' : 'none'}
          >
            <AlertIcon />
            <AlertTitle mr={2}>Erreur</AlertTitle>
            <AlertDescription>{errorCommand?.base}</AlertDescription>
          </Alert>

          {errorStructureTypes ? (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Erreur</AlertTitle>
              <AlertDescription>Une erreur est survenue</AlertDescription>
            </Alert>
          ) : null}

          {pendingStructureTypes ? <Spinner /> : null}

          {!errorStructureTypes && !pendingStructureTypes ? (
            <VStack spacing={4} align="stretch">
              <Controller
                control={control}
                name="genericType"
                render={({ field: { onChange, ...field } }) => (
                  <StructureTypesSelect
                    id={field.name}
                    {...field}
                    structureTypes={structureTypes.filter((st) => st.generic)}
                    organisationStructureTypes={organisation.structureTypes}
                    handleSelectedItemsChange={onChange}
                    structureTypeTerminology={null}
                    allowEmpty={false}
                  />
                )}
              />
              <Grid gridTemplateColumns="15ch 1fr" alignItems="baseline">
                <FormLabel htmlFor="nationalType">
                  {polymorphicTypingDisabled ? 'Sous-type' : 'Terminologie nationale'}
                </FormLabel>
                <Controller
                  control={control}
                  name="nationalType"
                  render={({ field: { onChange, ...field } }) => (
                    <StructureTypesSelect
                      genericType={genericType}
                      id={field.name}
                      {...field}
                      handleSelectedItemsChange={onChange}
                      structureTypes={structureTypes}
                      structureTypeTerminology={polymorphicTypingDisabled ? null : 'national'}
                      placeholder={
                        polymorphicTypingDisabled
                          ? 'Sous-type'
                          : 'Type de structure, vocabulaire national'
                      }
                      allowEmpty
                    />
                  )}
                />
              </Grid>
              {!polymorphicTypingDisabled && (
                <Grid gridTemplateColumns="15ch 1fr" alignItems="baseline">
                  <FormLabel htmlFor="localType">Terminologie établissement</FormLabel>
                  <Controller
                    control={control}
                    name="localType"
                    render={({ field: { onChange, ...field } }) => (
                      <StructureTypesSelect
                        genericType={genericType}
                        id={field.name}
                        {...field}
                        handleSelectedItemsChange={onChange}
                        structureTypes={structureTypes}
                        structureTypeTerminology="local"
                        placeholder="Type de structure, vocabulaire établissement"
                        isDisabled={polymorphicTypingDisabled}
                        allowEmpty
                      />
                    )}
                  />
                </Grid>
              )}
              {!polymorphicTypingDisabled && (
                <Grid gridTemplateColumns="15ch 1fr" alignItems="baseline">
                  <FormLabel htmlFor="specificType">Terminologie spécifique</FormLabel>
                  <Controller
                    control={control}
                    name="specificType"
                    render={({ field: { onChange, ...field } }) => (
                      <StructureTypesSelect
                        genericType={genericType}
                        id={field.name}
                        {...field}
                        handleSelectedItemsChange={onChange}
                        organisationStructureTypes={organisation.structureTypes}
                        structureTypes={structureTypes}
                        structureTypeTerminology="spécifique"
                        placeholder="Type de structure, vocabulaire spécifique"
                        allowEmpty
                        isDisabled={
                          polymorphicTypingDisabled || genericType?.data.level !== 'level_1'
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            </VStack>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleReset}>
            Annuler
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={commandPending}>
            Sauvegarder
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StructureTypesSelect = ({
  genericType,
  structureTypeTerminology,
  handleSelectedItemsChange,
  placeholder,
  allowEmpty = false,
  structureTypes = [],
  ...props
}) => {
  const initialItems = structureTypes
    .filter(
      (structureType) =>
        !structureTypeTerminology || structureType.terminology === structureTypeTerminology,
    )
    .filter((structureType) => !genericType || structureType.parent === genericType?.data.code)
    .map((structureType) => ({
      label: structureType.longNameFr,
      value: structureType.id,
      data: structureType,
    }));

  return (
    <Select
      options={initialItems}
      placeholder={placeholder}
      onChange={handleSelectedItemsChange}
      isClearable={allowEmpty}
      isRequired={!allowEmpty}
      {...props}
    />
  );
};
