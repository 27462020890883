import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../../common/routes/routes';
import Can from '../roleBasedAccess/Can';
import { OrganisationsIndex } from './OrganisationsIndex/OrganisationsIndex';
import { OrganisationsShow } from './OrganisationsShow/OrganisationsShow';

const Organisations = () => {
  const match = useRouteMatch();
  const { user } = useSelector((state) => state.currentUser);

  return (
    <Can
      roles={user.roles}
      perform="app:use"
      data={{ user }}
      yes={() => (
        <Switch>
          <Route path={`${match.url}`} component={OrganisationsIndex} exact />
          <Route path={`${match.url}/:organisationId`} component={OrganisationsShow} />
        </Switch>
      )}
      no={() => <Redirect to={routes.home} />}
    />
  );
};

export default Organisations;
