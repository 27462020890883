/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as structureTypesAPI from '../../api/structureTypes';

export const fetchStructureTypes = createAsyncThunk(
  'structureTypes/fetchStructureTypes',
  async () => {
    const response = await structureTypesAPI.getStructureTypes();
    return response.data.structureTypes;
  },
);

export const structureTypesAdapter = createEntityAdapter();

export const structureTypesSlice = createSlice({
  name: 'structureTypes',
  initialState: structureTypesAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {},
  extraReducers: {
    [fetchStructureTypes.pending](state) {
      state.pending = true;
    },
    [fetchStructureTypes.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      structureTypesAdapter.setAll(state, action.payload);
    },
    [fetchStructureTypes.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const structureTypesSelectors = structureTypesAdapter.getSelectors();

export default structureTypesSlice.reducer;
