/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as usersAPI from '../../api/users';

export const fetchPeople = createAsyncThunk(
  'people/fetchPeople',
  async (params = { page: 1, query: '' }) => {
    const response = await usersAPI.getUsersList(params);
    return response.data;
  },
);

export const fetchPeopleById = createAsyncThunk('people/fetchPeopleById', async (peopleId) => {
  const response = await usersAPI.getUser(peopleId);
  return response.data;
});

export const uploadPhoto = createAsyncThunk(
  'people/uploadPhoto',
  async (params, { rejectWithValue }) => {
    const { peopleId, imageFile } = params;
    try {
      const response = await usersAPI.uploadPhoto(peopleId, imageFile);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

export const cancelUploadPhoto = createAsyncThunk(
  'people/cancelUploadPhoto',
  async (params, { rejectWithValue }) => {
    const { peopleId } = params;
    try {
      const response = await usersAPI.cancelUploadPhoto(peopleId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

export const publishPerson = createAsyncThunk('users/publishPerson', async (params) => {
  const { personId } = params;
  const response = await usersAPI.publishPerson(personId);
  return response.data;
});

export const peopleAdapter = createEntityAdapter();

export const peopleSlice = createSlice({
  name: 'people',
  initialState: peopleAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {
    updateOnePeople: peopleAdapter.setOne,
    updateManyPeople: peopleAdapter.setMany,
    deleteOnePeople: peopleAdapter.removeOne,
    deleteManyPeople: peopleAdapter.removeMany,
  },
  extraReducers: {
    [fetchPeople.pending](state) {
      state.pending = true;
    },
    [fetchPeople.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      peopleAdapter.setAll(state, action.payload.users);
    },
    [fetchPeople.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [fetchPeopleById.pending](state) {
      state.pending = true;
    },
    [fetchPeopleById.fulfilled](state, action) {
      state.pending = false;
      peopleAdapter.setOne(state, action.payload.user);
    },
    [fetchPeopleById.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [uploadPhoto.pending](state) {
      state.pending = true;
    },
    [uploadPhoto.fulfilled](state) {
      state.pending = false;
    },
    [uploadPhoto.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [publishPerson.pending](state) {
      state.pending = true;
    },
    [publishPerson.fulfilled](state, action) {
      state.pending = false;
    },
    [publishPerson.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
  },
});

export const { updateOnePeople, updateManyPeople, deleteOnePeople, deleteManyPeople } =
  peopleSlice.actions;

export const peopleSelectors = peopleAdapter.getSelectors();

export default peopleSlice.reducer;
