import React, { useState } from 'react';
import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { BulkActionsMenu } from './components/BulkActionsMenu';
import { archiveMessages, readMessages } from '../messagesSlice';
import { acceptCommandStep, rejectCommandStep } from '../commandStepsSlice';
import { displayErrorToast } from '../../../common/utils/toasts-utils';

export const useBulkActions = (initialState) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState(initialState);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [validationQueue, setValidationQueue] = useState([]);

  const clearSelectedRows = () => {
    setToggledClearRows(!toggledClearRows);
    setSelectedRows([]);
  };

  const handleMultipleMessagesUpdate = (action) => {
    clearSelectedRows();

    dispatch(action)
      .then(unwrapResult)
      .then(() => {
        setValidationQueue(validationQueue.slice(1));
      })
      .catch((err, params) => {
        /* Remove first element of validation queue */
        setValidationQueue(validationQueue.slice(1));
        displayErrorToast(err, params);
      });
  };

  const handleCommandStepAction = (action, commandStepId, messageId) => {
    const actionCommandStep = action === 'accept' ? acceptCommandStep : rejectCommandStep;
    handleMultipleMessagesUpdate(
      actionCommandStep({
        commandStepId,
        messageId,
      }),
    );
  };

  const processValidationQueue = () => {
    /* Do nothing if the validation queue is empty */
    if (validationQueue.length === 0) {
      return;
    }
    /* Read the first element of the validation queue */
    const [messageId, commandStepId, action] = validationQueue[0];
    handleCommandStepAction(action, commandStepId, messageId);
  };

  const handleClickCommandStepAction = (action, commandStepId, messageId) => {
    /* Add the action as last element to the validation queue */
    setValidationQueue(...validationQueue, [[messageId, commandStepId, action]]);
  };

  const handleClickBulkCommandStepAction = (action) => {
    const selectedMessages = selectedRows.filter((row) => row.validable);
    const commandStepIds = selectedMessages.map((row) => row.commandStep.id);
    const messageIds = selectedMessages.map((row) => row.id);
    clearSelectedRows();
    /* Add all the actions to the end of the validation queue */
    setValidationQueue([
      ...validationQueue,
      ...messageIds.map((messageId, index) => [messageId, commandStepIds[index], action]),
    ]);
  };

  const handleMultipleMessagesRead = (boolean, messageIds) => {
    handleMultipleMessagesUpdate(
      readMessages({
        messageIds,
        read: boolean,
      }),
    );
  };

  const handleClickMessageRead = (boolean, message) => {
    if ((boolean && !message.readable) || (!boolean && !message.unreadable)) {
      return;
    }
    handleMultipleMessagesRead(boolean, [message.id]);
  };

  const handleClickBulkMessagesRead = (boolean) => {
    handleMultipleMessagesRead(
      boolean,
      selectedRows
        .filter((row) => (boolean && row.readable) || (!boolean && row.unreadable))
        .map((row) => row.id),
    );
  };

  const handleMultipleMessageArchive = (boolean, messageIds) => {
    clearSelectedRows();

    handleMultipleMessagesUpdate(
      archiveMessages({
        messageIds,
        archive: boolean,
      }),
    );
  };

  const handleClickBulkMessagesArchive = (boolean) => {
    handleMultipleMessageArchive(
      boolean,
      selectedRows
        .filter((row) => (boolean && row.archivable) || (!boolean && row.unarchivable))
        .map((row) => row.id),
    );
  };

  const handleClickMessageArchive = (boolean, message) => {
    if ((boolean && !message.archivable) || (!boolean && !message.unarchivable)) {
      return;
    }
    handleMultipleMessageArchive(boolean, [message.id]);
  };

  const CheckBoxWithBulkActionsMenu = React.forwardRef(({ onClick, ...rest }, ref) => {
    return rest.name === 'select-all-rows' ? (
      <ButtonGroup spacing={0} mx={1}>
        <IconButton
          aria-label="actions par lot"
          as="label"
          size="sm"
          borderTopLeftRadius="md"
          borderBottomLeftRadius="md"
          cursor="pointer"
          _hover={{ backgroundColor: 'gray.300' }}
        >
          <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
        </IconButton>
        <BulkActionsMenu
          handleClickBulkMessagesArchive={handleClickBulkMessagesArchive}
          handleClickBulkMessagesRead={handleClickBulkMessagesRead}
          handleClickBulkCommandStepAction={handleClickBulkCommandStepAction}
          selectedRows={selectedRows}
        />
      </ButtonGroup>
    ) : (
      <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
    );
  });

  return [
    toggledClearRows,
    validationQueue,
    CheckBoxWithBulkActionsMenu,
    {
      setSelectedRows,
      clearSelectedRows,
      processValidationQueue,
      handleClickMessageRead,
      handleClickMessageArchive,
      handleClickCommandStepAction,
      handleClickBulkCommandStepAction,
    },
  ];
};
