import React from 'react';
import { Center, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { GiSandsOfTime } from 'react-icons/all';
import { PageHeader, PageTitle, PageContainer, PageBody } from '../../../common/components/Page';
import { Metadata } from '../../../Metadata';

const tabName = 'Contrats de recherche';
export const ContractsIndex = () => {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>{tabName}</PageTitle>
      </PageHeader>
      <Metadata titleSuffix={tabName} descriptionSuffix="Contrats de recherche" />
      <PageBody p="page.gutter">
        <Center>
          <VStack w="300px" bg="blue.100" p="3">
            <HStack>
              <Icon as={GiSandsOfTime} boxSize="1.75em" margin={1.5} />
              <Heading fontSize="l" color="gray.600">
                Un peu de patience !
              </Heading>
            </HStack>
            <Text>
              Prochainement, cette rubrique vous donnera accès à un ensemble de fonctionnalités
              liées à la description des contrats de recherche de l'EHESS.
            </Text>
          </VStack>
        </Center>
      </PageBody>
    </PageContainer>
  );
};
