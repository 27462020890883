import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { transparentize } from '@chakra-ui/theme-tools';

export const theme = extendTheme({
  colors: {
    ehess_green: '#00B9AF',
  },
  styles: {
    global: {
      body: {
        color: 'gray.700',
      },
    },
  },
  space: {
    page: {
      gutter: '2rem',
    },
  },
  sizes: {
    page: {
      header: '7.375rem',
    },
  },
  components: {
    Heading: {
      sizes: {
        lg: {
          fontSize: ['xl', null, '2xl'],
        },
      },
      defaultProps: {
        size: 'lg',
      },
    },
    Button: {
      variants: {
        action: {
          color: 'blue.600',
          rounded: 'full',
          bg: 'transparent',
          _hover: {
            bg: transparentize(baseTheme.colors.blue[100], 0.4),
          },
          _focus: {
            bg: transparentize(baseTheme.colors.blue[100], 0.5),
          },
          _active: {
            bg: transparentize(baseTheme.colors.blue[100], 0.5),
          },
        },
      },
      sizes: {
        md: {
          height: 7,
          minW: 7,

          svg: {
            width: 5,
            height: 5,
          },
        },
      },
    },
    Table: {
      baseStyle: {
        tbody: {
          tr: {
            _hover: {
              backgroundColor: 'gray.50',
            },
          },
        },
      },
    },
  },
});
