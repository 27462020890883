import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Link,
  Radio,
  StackItem,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { BiTrash } from 'react-icons/bi';
import { routes } from '../../../../../../common/routes/routes';
import {
  organisationIcon,
  organisationTypeLabel,
} from '../../../../../../common/utils/organisations-utils';
import { DateChooser } from '../../../../../../common/components/Calendar';
import { createCommand } from '../../../../../commands/commandsSlice';
import { commandSuccess } from '../../../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../../../common/utils/toasts-utils';
import { ConfirmDialog } from '../../../../../../common/components/ConfirmDialog';
import { upsertManyOrganisations } from '../../../../organisationsSlice';

export const RelationshipItem = ({
  relationship,
  organisation,
  type,
  hasParentSupervisionFlags,
  hasChildSupervisionFlags,
  ...props
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const relationshipType = relationship.supervisor ? 'supervision' : 'inclusion';

  const handleRelationShipChange = ({ code, field, newValue }) => {
    const payload = {
      code: code || `update_${relationshipType}_${field}`,
      [`${relationshipType}Id`]: relationship.id,
      newValue,
    };

    return dispatch(createCommand(payload))
      .then(unwrapResult)
      .then((data) => {
        if (data.command?.payload?.organisations) {
          dispatch(upsertManyOrganisations(data.command.payload.organisations));
        }
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };
  const PhysAccControl = useMemo(() => {
    return Checkbox;
  }, [hasParentSupervisionFlags, hasChildSupervisionFlags]);
  const ManagDelegControl = useMemo(() => {
    return hasChildSupervisionFlags ? Checkbox : Radio;
  }, [hasParentSupervisionFlags, hasChildSupervisionFlags]);
  const DPOControl = useMemo(() => {
    return hasChildSupervisionFlags ? Checkbox : Radio;
  }, [hasParentSupervisionFlags, hasChildSupervisionFlags]);

  const bgColor = useMemo(() => {
    if (relationship.past) {
      return 'gray.100';
    }
    if (relationship.future) {
      return 'blue.100';
    }
    return 'gray.100';
  }, [relationship]);

  return (
    <HStack
      {...props}
      spacing="3"
      px="4"
      py="2"
      background={bgColor}
      style={{ opacity: relationship.past ? 0.4 : 1 }}
      borderRadius="base"
    >
      <HStack alignItems="flex-start" flexGrow="1" mr="auto">
        <Icon mt={0.5} as={organisationIcon(organisation)} />
        <VStack alignItems="flex-start" spacing={1}>
          <Link
            as={NavLink}
            to={{
              pathname: `${routes.organisations}/${organisation.id}/relationships`,
              state: { from: location.pathname, search: location.search },
            }}
          >
            {organisation.titleFr}
          </Link>
          {organisation.structureTypes ? (
            <Text as="small" opacity="0.8">
              {organisationTypeLabel(organisation)}
            </Text>
          ) : null}
        </VStack>
      </HStack>
      {hasParentSupervisionFlags || hasChildSupervisionFlags ? (
        <>
          <StackItem flex="0 0 4ch" d="grid" gridAutoFlow="column" gridGap="2" alignItems="center">
            <PhysAccControl
              colorScheme="blue"
              value="validation"
              aria-label="Hébergeante"
              isChecked={relationship.physicalAccommodation}
              onChange={(e) =>
                handleRelationShipChange({
                  field: 'physical_accommodation_flag',
                  newValue: e.target.checked,
                })
              }
            />
          </StackItem>
          <StackItem flex="0 0 4ch" d="grid" gridAutoFlow="column" gridGap="2" alignItems="center">
            <ManagDelegControl
              colorScheme="blue"
              value="validation"
              aria-label="Hébergeante"
              isChecked={relationship.managementDelegation}
              onChange={(e) =>
                handleRelationShipChange({
                  field: 'management_delegation_flag',
                  newValue: e.target.checked,
                })
              }
            />
          </StackItem>
          <StackItem flex="0 0 4ch" d="grid" gridAutoFlow="column" gridGap="2" alignItems="center">
            <DPOControl
              colorScheme="blue"
              value="validation"
              aria-label="Hébergeante"
              isChecked={relationship.dataProtectionOfficer}
              onChange={(e) =>
                handleRelationShipChange({
                  field: 'data_protection_officer_flag',
                  newValue: e.target.checked,
                })
              }
            />
          </StackItem>
        </>
      ) : null}
      <StackItem flex="0 0 18ch" d="grid" gridAutoFlow="column" gridGap="2" alignItems="center">
        <Text as="span" w="3ch" textAlign="right">
          De
        </Text>
        <VStack>
          <DateChooser
            defaultDate={Date.parse(relationship.startDate) || null}
            handleDateChange={(date) => {
              handleRelationShipChange({ field: 'start_date', newValue: date });
            }}
            wrapperClassName="react-datepicker-sm"
          />
          <FormControl display="flex" alignItems="center" ml={4}>
            <FormLabel htmlFor="past-relationship" mb="0">
              Passée
            </FormLabel>
            <Switch
              id="past-relationship"
              isChecked={relationship.past}
              onChange={(e) =>
                handleRelationShipChange({ field: 'past_flag', newValue: e.target.checked })
              }
            />
          </FormControl>
        </VStack>
      </StackItem>
      <StackItem flex="0 0 18ch" d="grid" gridAutoFlow="column" gridGap="2" alignItems="center">
        <Text as="span" w="3ch" textAlign="right">
          à
        </Text>
        <VStack>
          <DateChooser
            defaultDate={Date.parse(relationship.endDate) || null}
            handleDateChange={(date) => {
              handleRelationShipChange({ field: 'end_date', newValue: date });
            }}
            wrapperClassName="react-datepicker-sm"
          />
          <FormControl display="flex" alignItems="center" ml={4}>
            <FormLabel htmlFor="future-relationship" mb="0">
              Future
            </FormLabel>
            <Switch
              id="future-relationship"
              isChecked={relationship.future}
              onChange={(e) =>
                handleRelationShipChange({ field: 'future_flag', newValue: e.target.checked })
              }
            />
          </FormControl>
        </VStack>
      </StackItem>
      <StackItem>
        <ConfirmDialog
          control={
            <IconButton
              size="sm"
              aria-label="Supprimer"
              title="Supprimer"
              variant="ghost"
              colorScheme="red"
              icon={<BiTrash />}
              rounded="full"
            />
          }
          actionText="Supprimer"
          headerText="Suppression"
          mainText={<p>Voulez-vous vraiment retirer cette relation ?</p>}
          callback={(_, { onClose }) => {
            handleRelationShipChange({
              code: `remove_${relationship.supervisor ? 'supervision' : 'inclusion'}`,
            }).then(onClose);
          }}
        />
      </StackItem>
    </HStack>
  );
};
