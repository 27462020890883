import React from 'react';
import { css, Flex, Grid, GridItem, Heading, Text, useTheme } from '@chakra-ui/react';

export const DetailGroup = ({ children, ...props }) => {
  return (
    <Grid gridAutoFlow="column" gap={4} gridTemplateColumns="minmax(300px, 9vw) 1fr" {...props}>
      {children}
    </Grid>
  );
};

export const DetailGroupTitle = ({ children, ...props }) => {
  return (
    <Heading fontSize="xl" pos="sticky" top={4} color="gray.600" {...props}>
      {children}
    </Heading>
  );
};

export const DetailGroupCol = GridItem;

export const Detail = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Flex
      as="section"
      flexDir="column"
      rounded="md"
      css={css({
        '> * + *': {
          borderTop: '1px solid',
          borderTopColor: 'gray.100',
        },
      })(theme)}
      {...props}
    >
      {children}
    </Flex>
  );
};

export const DetailRow = ({ children, ...props }) => {
  return (
    <Grid
      gridTemplateColumns="16rem 1fr"
      py={3}
      px={5}
      _first={{ pt: 0 }}
      _last={{ pb: 0 }}
      alignItems="center"
      {...props}
    >
      {children}
    </Grid>
  );
};

export const DetailRowLabel = ({ children, ...props }) => {
  return (
    <Text color="gray.500" {...props}>
      {children}
    </Text>
  );
};
