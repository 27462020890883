import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Badge,
  chakra,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { GoSignOut } from 'react-icons/go';
import {
  FaExchangeAlt,
  FaFileContract,
  FaMailBulk,
  FaProjectDiagram,
  FaStamp,
  FaTable,
  FaUsers,
  FaUsersCog,
} from 'react-icons/fa';
import { ImFileText } from 'react-icons/all';
import { routes } from '../routes/routes';
import { WS } from '../../WS';
import { check } from '../../features/roleBasedAccess/Can';
import rules from '../../features/roleBasedAccess/rules';
import { omit } from '../utils/hash-utils';
import LogoEHESS from '../../assets/images/Logo_EHESS_2021_Blanc_73x73.png';

const navItems = [
  {
    label: 'Organisations',
    openToAutoEditors: false,
    path: routes.organisations,
    icon: FaProjectDiagram,
  },
  { label: 'Personnes', openToAutoEditors: false, path: routes.people, icon: FaUsers },
  { label: 'Contrats', openToAutoEditors: false, path: routes.contracts, icon: FaFileContract },
  { label: 'Instances', openToAutoEditors: false, path: routes.instances, icon: FaStamp },
  { label: 'Utilisateurs·rices', openToAutoEditors: false, path: routes.users, icon: FaUsersCog },
  { label: 'Messages', openToAutoEditors: true, path: routes.messages, icon: FaMailBulk },
  { label: 'Flux', openToAutoEditors: false, path: routes.streams, icon: FaExchangeAlt },
  {
    label: 'Extractions',
    openToAutoEditors: false,
    path: routes.extractions,
    icon: FaTable,
    sx: { mb: 'auto!important' },
  },
  {
    label: 'Mentions légales',
    openToAutoEditors: true,
    path: routes.informations,
    icon: ImFileText,
  },
  { label: 'Déconnexion', openToAutoEditors: true, path: routes.logout, icon: GoSignOut },
];

const PrivateLayout = ({ children }) => {
  const { loggedIn, user } = useSelector((state) => state.currentUser);
  const extendedPermissions = useMemo(() => {
    return loggedIn && check(rules, user.roles, 'app:use', { user });
  }, [user, loggedIn]);

  return (
    <Flex minH="100vh" bgColor="gray.300">
      <chakra.nav
        d="flex"
        flexDir="column"
        flexShrink="0"
        h="100vh"
        width={60}
        p={4}
        bgColor="blue.800"
        color="white"
        pos="sticky"
        top="0"
      >
        <Flex alignItems="center" mt={2} mb={5}>
          <Image src={LogoEHESS} />
          <Text fontSize="2xl" textTransform="uppercase" fontWeight="semibold">
            Sangam
          </Text>
        </Flex>

        <HStack
          wrap="nowrap"
          spacing={2}
          mx={-4}
          p={4}
          textAlign="left"
          borderBlock="1px solid"
          borderColor="whiteAlpha.500"
        >
          <Link
            as={NavLink}
            to={`${routes.people}/${user.id}`}
            d="flex"
            alignItems="center"
            px={2}
            py={2}
            borderRadius="md"
            fontSize="sm"
            _hover={{ textDecoration: 'none', bgColor: 'blackAlpha.300' }}
            // _activeLink={{ bgColor: 'blackAlpha.500' }}
          >
            <Avatar name={user.displayName} size="sm" />
            <Text fontSize="sm" minW={0} mt={0} ml={2} whiteSpace="break-spaces">
              {user.displayName}
            </Text>
          </Link>
        </HStack>

        <Stack spacing={1} mt={5} flexGrow="1">
          {navItems.map(
            (navItem) =>
              (extendedPermissions || navItem.openToAutoEditors) && (
                <MainNavLink key={navItem.label} {...navItem}>
                  {navItem.label}
                  {navItem.label === 'Messages' && user.unreadMessagesCount ? (
                    <Badge colorScheme="red" variant="solid" fontSize="xs" ml={2}>
                      {user.unreadMessagesCount}
                    </Badge>
                  ) : null}
                </MainNavLink>
              ),
          )}
          <WS />
        </Stack>
      </chakra.nav>
      <chakra.article flexGrow="1">{children}</chakra.article>
    </Flex>
  );
};

export default PrivateLayout;

const MainNavLink = ({ path, icon, children, ...props }) => {
  return (
    <Link
      as={NavLink}
      to={path || '/'}
      d="flex"
      alignItems="center"
      px={3}
      py={2}
      borderRadius="md"
      fontSize="sm"
      _hover={{ textDecoration: 'none', bgColor: 'blackAlpha.300' }}
      _activeLink={{ bgColor: 'blackAlpha.500' }}
      {...omit(props, 'openToAutoEditors')}
    >
      <Icon as={icon} fontSize="lg" mr={2} />
      {children}
    </Link>
  );
};
