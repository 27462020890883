import React from 'react';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';

import { AsyncSelect } from '../../../common/components/Select';
import { getOrganisationList } from '../../../api/organisations';
import { organisationTypeLabel } from '../../../common/utils/organisations-utils';

export const OrganisationsAutocomplete = ({
  organisationType,
  handleSelectedItemsChange,
  disabled,
  placeholder,
  ...props
}) => {
  const fetchedOrganisations = (inputValue) =>
    getOrganisationList({
      query: inputValue,
      byOrganisationType: organisationType,
      perPage: 25,
      page: 1,
      short: true,
    }).then(({ data }) => {
      const options = data.organisations.map((organisation) => ({
        label: `${organisation.titleFr} (${organisation.acronymFr}) - ${organisationTypeLabel(
          organisation,
        )}`,
        value: organisation.id,
      }));
      if (data.meta.total > data.organisations.length) {
        options.unshift({
          label: 'Tapez plus de lettres pour voir les autres résultats',
          value: null,
          isDisabled: true,
        });
      }
      return options;
    });

  if (disabled) {
    return (
      <Alert status="info">
        <AlertIcon />
        <AlertDescription>Rôle sans périmètre</AlertDescription>
      </Alert>
    );
  }

  return (
    <AsyncSelect
      isClearable
      cacheOptions
      noOptionsMessage={() => 'Tapez quelques lettres'}
      loadOptions={fetchedOrganisations}
      placeholder={placeholder}
      onChange={(item) => {
        handleSelectedItemsChange(item?.value);
      }}
      {...props}
    />
  );
};
