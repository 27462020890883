import React from 'react';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import {
  AiFillEye,
  AiOutlineEye,
  BsArchive,
  BsArchiveFill,
  BsCheck,
  BsChevronDown,
  IoBanOutline,
} from 'react-icons/all';

export const BulkActionsMenu = ({
  selectedRows,
  handleClickBulkMessagesRead,
  handleClickBulkMessagesArchive,
  handleClickBulkCommandStepAction,
}) => {
  const readableEntries = selectedRows.some((row) => row.readable);
  const unreadableEntries = selectedRows.some((row) => row.unreadable);
  const validableEntries = selectedRows.some((row) => row.validable);
  const archivableEntries = selectedRows.some((row) => row.archivable);
  const unarchivableEntries = selectedRows.some((row) => row.unarchivable);

  const bulkActionsMenuAvailable =
    readableEntries ||
    unreadableEntries ||
    validableEntries ||
    archivableEntries ||
    unarchivableEntries ||
    validableEntries;

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        size="sm"
        minW="6"
        disabled={!bulkActionsMenuAvailable}
        icon={<BsChevronDown />}
        _hover={{ backgroundColor: 'gray.300' }}
      />
      <MenuList>
        <MenuItem onClick={() => handleClickBulkMessagesRead(true)} isDisabled={!readableEntries}>
          <Icon as={AiFillEye} />
          &nbsp;Marquer comme lu
        </MenuItem>
        <MenuItem
          onClick={() => handleClickBulkMessagesRead(false)}
          isDisabled={!unreadableEntries}
        >
          <Icon as={AiOutlineEye} />
          &nbsp;Marquer comme non lu
        </MenuItem>
        <MenuItem
          onClick={() => handleClickBulkMessagesArchive(true)}
          isDisabled={!archivableEntries}
        >
          <Icon as={BsArchiveFill} />
          &nbsp;Archiver
        </MenuItem>
        <MenuItem
          onClick={() => handleClickBulkMessagesArchive(false)}
          isDisabled={!unarchivableEntries}
        >
          <Icon as={BsArchive} />
          &nbsp;Ne plus archiver
        </MenuItem>
        <MenuItem
          onClick={() => handleClickBulkCommandStepAction('accept')}
          isDisabled={!validableEntries}
        >
          <Icon as={BsCheck} />
          &nbsp;Accepter
        </MenuItem>
        <MenuItem
          onClick={() => handleClickBulkCommandStepAction('reject')}
          isDisabled={!validableEntries}
        >
          <Icon as={IoBanOutline} />
          &nbsp;Refuser
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
