/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: {},
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages[action.payload.id] = action.payload;
    },
    clearById: (state, action) => {
      delete state.messages[action.payload.id];
    },
  },
});

export const { addMessage, clearById } = notificationsSlice.actions;

export default notificationsSlice.reducer;
