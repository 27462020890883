import React from 'react';
import {
  Button,
  chakra,
  Icon,
  IconButton,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { FaBan, FaCheck } from 'react-icons/fa';
import {
  AiFillEye,
  AiOutlineEye,
  BsArchive,
  BsArchiveFill,
  BsCheck,
  GiChoice,
  GiInfo,
  IoBanOutline,
} from 'react-icons/all';

export const columns = ({
  handleClickMessageRead,
  handleClickMessageArchive,
  handleClickCommandStepAction,
}) => [
  {
    name: 'Type',
    center: true,
    cell: (message) => (
      <Icon fontSize="2xl" as={message.commandStep?.type === 'Validation' ? GiChoice : GiInfo} />
    ),
    width: '75px',
  },
  {
    name: 'Message',
    cell: (message) => (
      <VStack alignItems="self-start" py={1}>
        <Text fontWeight={message.read ? null : 'bold'}>{message.titleFr}</Text>
        <Text
          as="small"
          dangerouslySetInnerHTML={{
            __html: message.textFr,
          }}
        />
      </VStack>
    ),
    grow: 3,
  },
  {
    name: 'Actions',
    center: true,
    grow: 1,
    cell: (message) => (
      <>
        {message.validable && (
          <VStack spacing={1} alignItems="stretch">
            <Button
              aria-label="Valider"
              title="Valider"
              variant="solid"
              size="sm"
              leftIcon={<BsCheck />}
              rounded="full"
              colorScheme="blue"
              onClick={() =>
                handleClickCommandStepAction('accept', message.commandStep.id, message.id)
              }
            >
              <chakra.span>Valider</chakra.span>
            </Button>
            <Button
              aria-label="Refuser"
              title="Refuser"
              variant="action"
              size="sm"
              leftIcon={<IoBanOutline />}
              rounded="full"
              onClick={() =>
                handleClickCommandStepAction('reject', message.commandStep.id, message.id)
              }
            >
              <chakra.span>Refuser</chakra.span>
            </Button>
          </VStack>
        )}
        {message.processed && (
          <Tooltip
            hasArrow
            label={`${message.commandStep?.accepted ? 'Accepté le ' : 'Rejeté le '}${
              message.commandStep?.dateProcessed
            } par ${message.commandStep?.processedBy?.displayName}`}
            key={message.commandStep?.id}
          >
            <Tag
              size="md"
              colorScheme={message.commandStep?.accepted ? 'green' : 'red'}
              borderRadius="full"
              cursor="pointer"
            >
              <TagLeftIcon as={message.commandStep?.accepted ? FaCheck : FaBan} />
              <TagLabel>{message.commandStep?.accepted ? 'Validé' : 'Rejeté'}</TagLabel>
            </Tag>
          </Tooltip>
        )}
      </>
    ),
  },
  {
    name: 'Source',
    cell: (message) => (
      <Text whiteSpace="nowrap">
        {message.userName} <br />
        {message.createdAt}
      </Text>
    ),
    grow: 'auto',
  },
  {
    name: 'Lu / Non lu',
    center: true,
    maxWidth: '100px',
    cell: (message) => (
      <>
        {message.readable && (
          <IconButton
            aria-label="Marquer comme lu"
            title="Marquer comme lu"
            variant="action"
            icon={<AiOutlineEye />}
            rounded="full"
            onClick={() => handleClickMessageRead(true, message)}
          />
        )}
        {message.unreadable && (
          <IconButton
            aria-label="Marquer comme non lu"
            title="Marquer comme non lu"
            variant="action"
            icon={<AiFillEye />}
            rounded="full"
            onClick={() => handleClickMessageRead(false, message)}
          />
        )}
      </>
    ),
  },
  {
    name: 'Archivé / non archivé',
    center: true,
    maxWidth: '100px',
    cell: (message) => (
      <>
        {!message.archived &&
          (!message.commandStep ||
            (message.commandStep?.type === 'Validation' && message.commandStep?.done === true) ||
            message.commandStep?.type === 'Notification') && (
            <IconButton
              aria-label="Archiver"
              title="Archiver"
              variant="action"
              icon={<BsArchive />}
              rounded="full"
              onClick={() => handleClickMessageArchive(true, message)}
            />
          )}
        {message.archived === true && (
          <IconButton
            aria-label="Ne plus archiver"
            title="Ne plus archiver"
            variant="action"
            icon={<BsArchiveFill />}
            rounded="full"
            onClick={() => handleClickMessageArchive(false, message)}
          />
        )}
      </>
    ),
  },
];
