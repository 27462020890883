import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../../common/routes/routes';
import Can from '../roleBasedAccess/Can';
import { InstancesIndex } from './InstancesIndex/InstancesIndex';

const Instances = () => {
  const { user } = useSelector((state) => state.currentUser);
  const match = useRouteMatch();

  return (
    <Can
      roles={user.roles}
      perform="app:use"
      data={{ user }}
      yes={() => (
        <Switch>
          <Route path={`${match.url}`} exact component={InstancesIndex} />
        </Switch>
      )}
      no={() => <Redirect to={routes.home} />}
    />
  );
};

export default Instances;
