import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Spacer,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../../common/routes/routes';
import LoginSplash from '../../assets/images/login-splash-image2.jpg';
import Logo from '../../assets/images/Logo_EHESS_2021_Blanc_210x210.png';
import { Metadata } from '../../Metadata';

const Login = () => {
  const { loggedIn } = useSelector((state) => state.currentUser);

  if (loggedIn) {
    return <Redirect to={routes.home} />;
  }

  return (
    <>
      <Metadata titleSuffix="Authentification" description="Accueil & authentification" />
      <Flex minH="100vh">
        <chakra.section
          maxW={{ base: 'md', lg: 'xl' }}
          flexGrow="1"
          color="white"
          backgroundColor="blue.800"
          p={10}
        >
          <Heading as="h1" textAlign="center">
            <Stack direction="row">
              <Image
                boxSize="150px"
                objectFit="cover"
                src={Logo}
                alt="École de hautes études en sciences sociales"
              />
              <Spacer />
              <Box pt={5} pr={3} fontSize="3xl">
                சங்கம் <br />
                caṅkam <br />
                sangam
              </Box>
            </Stack>
          </Heading>

          <Stack spacing={6} mt={8}>
            <Box textAlign="center" mt={10}>
              <Button
                as={Link}
                css={{ ':hover': { textDecoration: 'none' } }}
                backgroundColor="ehess_green"
                size="lg"
                href={`${process.env.REACT_APP_API_URL}/users/sign_in`}
              >
                S'authentifier
              </Button>
            </Box>
            <Accordion pt={10}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Qu'est-ce que Sangam ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize="xs">
                  <strong>
                    Sangam est le système d'information de la Direction du développement de la
                    Recherche (DDR) de l'École des Hautes Études en Sciences Sociales.
                  </strong>
                  <br />
                  <UnorderedList>
                    <ListItem mt={3}>
                      Il rassemble les informations relatives aux acteurs, structures, projets,
                      productions de la recherche à l'EHESS : organisation de la recherche;
                      informations relatives aux membres des structures de recherche et à leur
                      activité scientifique; contrats de recherche dont les unités de l'école sont
                      parties prenantes.
                    </ListItem>
                    <ListItem mt={3}>
                      Ces informations sont destinées à la diffusion sur les interfaces web de
                      l'école (sites web des unités de recherche, et bientôt plateforme Vivo-EHESS).
                      Elles sont aussi également utilisées par les services à des fins de pilotage.
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Qui peut utiliser Sangam ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize="xs">
                  <strong>Sangam se destine à deux catégories d'utilisateurs·trices.</strong>
                  <UnorderedList>
                    <ListItem mt={3}>
                      L'ensemble des membres de l'école (membres statutaires ou associés des
                      structures de recherche dont l'EHESS est tutelle; personnels de soutien à la
                      recherche, de soutien administratifs; doctorants). Ils n'ont accès qu'aux
                      fonctionnalités de Sangam qui leur permettent de vérifier les données
                      relatives à leur activité scientifique, de les rectifier ou de les compléter
                      au besoin, et de contrôler leur diffusion sur Internet.
                    </ListItem>
                    <ListItem mt={3}>
                      Les personnels que leurs missions amènent à consulter ou à mettre à jour les
                      informations contenues dans Sangam : gestionnaires et directions des unités de
                      recherche, certains personnels des services centraux. Elles·Ils ont accès à
                      toutes les fonctionnalités de l'application mais leurs niveaux de permissions,
                      pour la mise à jour des informations, sont variables.
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Que faire si vous rencontrez des difficultés avec Sangam ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize="xs">
                  <strong>
                    Les services de l'EHESS vous accompagnent dans l'utilisation de Sangam.
                  </strong>
                  <UnorderedList>
                    <ListItem mt={3}>
                      Des informations sont disponibles sur
                      <Link href="https://services-numeriques.ehess.fr/services" isExternal>
                        le site «Services numériques» de la DSI de l'EHESS
                      </Link>
                    </ListItem>
                    <ListItem mt={3}>
                      Si vous rencontrez des difficultés pour vous authentifier, veuillez contacter
                      le 2626@ehess.fr.
                    </ListItem>
                    <ListItem mt={3}>
                      Si vous rencontrez un problème avec les informations présentes dans Sangam ou
                      les droits de consultation ou de mise à jour dont vous bénéficiez, veuillez
                      contacter la direction du développement de la recherche à l'adresse ...
                    </ListItem>
                    <ListItem mt={3}>
                      Si malgré nos efforts vous rencontrez un dysfonctionnement de l'application
                      Sangam, veuillez contacter le Pôle numérique recherche de la DSI à l'adresse
                      recherche.dsi@ehess.fr.
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Pourquoi ce nom ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} fontSize="xs">
                  <Text>
                    Ce nom a été suggéré par le Centre d'Études de l'Inde et de l'Asie du Sud
                    (CEIAS), que nous remercions.
                    <chakra.aside>
                      <Text
                        mt={3}
                        backgroundColor="#bebebe"
                        color="black"
                        p={3}
                        dangerouslySetInnerHTML={{
                          __html:
                            '<strong>சங்கம்² caṅkam [[sangam]]</strong>, n. < saṅgha. 1. Mustering, gathering; கூட்டம். சங்கமாகி வெங்கணை வீக்க மொடு (பெருங். மகத. 17, 38). 2. Society, assembly, council, senate, academy; சபை. புலம்பரிச் சங்கம் பொருளொடு முழங்க (மணி. 7, 114). 3. Literati, poets; புலவர். (திவா.) 4. Learned assemblies or academies of ancient times patronised by Pāṇḍya kings, three in number, viz., talai-c-caṅkam, iṭai-c-caṅkam, kaṭai-c-caṅkam; பாண்டி யர் ஆதரவுபெற்று விளங்கிய தலைச்சங்கம், இடைச் சங்கம், கடைச்சங்கம் என்ற முச்சங்கங்கள். எம்மைப் பவந்தீர்ப்பவர் சங்கமிருந்தது (பெரியபு. மூர்த்திநா. 7). 5. Fraternity of monks among Buddhists and Jains; சைனபௌத்தர்களின் சங்கம்.',
                        }}
                      />
                    </chakra.aside>
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
          <Box alignItems="center">
            <Text color="grey.800" position="fixed" bottom={10} left={5} textAlign="center">
              {' '}
              © École des Hautes Études en Sciences Sociales 2021 ·{' '}
              <Link href="/mentions-legales" target="_blank">
                Mentions legales
              </Link>
            </Text>
          </Box>
        </chakra.section>
        <Box
          flexGrow="1"
          backgroundImage={LoginSplash}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        />
      </Flex>
    </>
  );
};

export default Login;
