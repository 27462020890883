import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../common/routes/routes';
import Can from '../roleBasedAccess/Can';
import { MessagesIndex } from './MessagesIndex/MessagesIndex';

export const Messages = () => {
  const match = useRouteMatch();
  const { user } = useSelector((state) => state.currentUser);
  return (
    <Can
      roles={user.roles}
      perform="app:autoedit"
      data={{ user }}
      yes={() => (
        <Switch>
          <Route path={`${match.url}`} component={MessagesIndex} exact />
        </Switch>
      )}
      no={() => <Redirect to={routes.home} />}
    />
  );
};
