import React from 'react';
import { Streams } from '../Stream/Streams';

export const DirectoryStreams = () => {
  const streamTypes = {
    directory_structures: 'Lancer la synchronisation des structures',
    directory_people: 'Lancer la synchronisation des personnes',
  };
  return <Streams title="Synchronisation annuaire LDAP" streamTypes={streamTypes} />;
};
