import React from 'react';
import { useToken, Spinner, Box, Text } from '@chakra-ui/react';
import RDataTable, { createTheme } from 'react-data-table-component';

export const DataTable = ({ hasFilters, progressPending, noDataText, ...props }) => {
  const [gray100, gray200, gray700] = useToken('colors', ['gray.100', 'gray.200', 'gray.700']);
  const [rounded] = useToken('radii', ['md']);

  createTheme('chakra', {
    background: {
      default: 'transparent',
    },
    text: {
      primary: gray700,
    },
    divider: {
      default: gray200,
    },
    highlightOnHover: {
      default: gray100,
    },
  });

  const paginationOptions = {
    rowsPerPageText: 'Nombre de résultats par page',
    rangeSeparatorText: 'sur',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Tout',
  };

  return (
    <Box
      css={{
        opacity: progressPending ? 0.6 : null,
        pointerEvents: progressPending ? 'none' : null,
        transition: '0.2s',
        '>div:first-of-type': {
          overflow: 'initial',
        },
        '>div:last-of-type': {
          position: 'sticky',
          bottom: 0,
          background: 'white',
        },
        '.rdt_TableRow': {
          '.rdt_TableCol:first-child, .rdt_TableCell:first-child': {
            flex: hasFilters && props.selectableRows ? '0 0 64px' : null,
            justifyContent: props.selectableRows ? 'flex-start' : null,
            input: {
              marginLeft: hasFilters ? '0.85rem' : '1.125rem',
            },
          },
        },
        '.rdt_TableCol:first-child, .rdt_TableCell:first-child': {
          paddingLeft: props.selectableRows ? null : '0.75rem',
        },
        '.rdt_TableCol:last-child, .rdt_TableCell:last-child': {
          paddingRight: '0.75rem',
        },
      }}
    >
      <RDataTable
        persistTableHead
        pagination
        paginationComponentOptions={paginationOptions}
        progressComponent={<Spinner my={5} />}
        theme="chakra"
        highlightOnHover
        subHeaderAlign="left"
        paginationPerPage={50}
        paginationRowsPerPageOptions={[10, 50]}
        noDataComponent={<Text p={6}>{noDataText || 'Aucun résultat'}</Text>}
        customStyles={{
          tableWrapper: {
            style: {
              borderRadius: `${rounded} ${rounded} 0 0`,
              border: `1px solid ${gray200}`,
            },
          },
          subHeader: {
            style: {
              minHeight: 0,
              padding: 0,
              position: 'sticky',
              top: 0,
              zIndex: 10,
            },
          },
          head: {
            style: {
              zIndex: 1,
              backgroundColor: gray200,
              position: 'sticky',
              borderRadius: `${rounded} ${rounded} 0 0`,
              top: 0,
            },
          },
          headRow: {
            style: {
              borderRadius: `${rounded} ${rounded} 0 0`,
            },
          },
          headCells: {
            style: {
              padding: '4px 8px',
            },
          },
          cells: {
            style: {
              padding: '4px 8px',
            },
          },
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: `${gray100} !important`,
            },
          },
          pagination: {
            style: {
              borderRadius: `0 0 ${rounded} ${rounded}`,
              border: `1px solid ${gray200}`,
              borderTopWidth: 0,
              color: gray700,
            },
          },
        }}
        {...props}
      />
    </Box>
  );
};
