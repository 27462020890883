import React from 'react';
import {
  VStack,
  Flex,
  useClipboard,
  IconButton,
  Box,
  Divider,
  Link,
  Text,
  HStack,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { HiClipboard, HiClipboardCheck, HiMail } from 'react-icons/hi';
import {
  Detail,
  DetailGroup,
  DetailGroupCol,
  DetailGroupTitle,
  DetailRow,
  DetailRowLabel,
} from '../../../../common/components/Detail';
import { createCommand } from '../../../commands/commandsSlice';
import { updateOneOrganisation } from '../../organisationsSlice';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import { EditableWithControlButton } from '../../../../common/components/Editable';
import { capitalizeFirstLetter } from '../../../../common/utils';
import { routes } from '../../../../common/routes/routes';
import { Metadata } from '../../../../Metadata';

const tabName = 'Synthèse';

export const Overview = ({ organisation }) => {
  const dispatch = useDispatch();
  const { hasCopied, onCopy } = useClipboard(organisation?.email || 'direction.ccj@ehess.fr');

  const handleEditInPlace = (code, payload = {}) => {
    dispatch(
      createCommand({
        code,
        ...payload,
        organisationId: organisation?.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${organisation.titleFr}`}
        descriptionSuffix={`Données principales de l'organisation ${organisation.titleFr}`}
      />
      <VStack spacing={5} alignItems="stretch">
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Résumé</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>
            <Detail>
              <DetailRow>
                <DetailRowLabel>Nom de l'organisation</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.titleFr}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_name', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Identifiant CNRS</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.cnrsId}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_cnrs_id', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>DPO</DetailRowLabel>
                {organisation.type === 'Institution' ? (
                  <EditableWithControlButton
                    defaultValue={organisation?.dataProtectionOfficer}
                    onSubmit={(newValue) =>
                      handleEditInPlace('update_organisation_data_protection_officer', { newValue })
                    }
                  />
                ) : (
                  <Text>{organisation?.dataProtectionOfficer}</Text>
                )}
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Adresse e-mail générique</DetailRowLabel>
                <Flex>
                  <EditableWithControlButton
                    defaultValue={organisation?.genericEmailAddress}
                    onSubmit={(newValue) =>
                      handleEditInPlace('update_organisation_generic_email', { newValue })
                    }
                  />
                  <IconButton
                    size="sm"
                    variant="action"
                    onClick={onCopy}
                    ml={2}
                    aria-label={hasCopied ? 'Copied' : 'Copy'}
                    icon={hasCopied ? <HiClipboardCheck /> : <HiClipboard />}
                  />
                  <IconButton
                    aria-label="Envoyer un message"
                    as={Link}
                    ml={2}
                    href={`mailto:${organisation?.genericEmailAddress}`}
                    size="sm"
                    variant="action"
                    icon={<HiMail />}
                  />
                </Flex>
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Adresse postale</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={organisation?.postalAddress}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_organisation_postal_address', { newValue })
                  }
                />
              </DetailRow>
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
        <Box>
          <Divider borderColor="gray.400" orientation="horizontal" />
        </Box>
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Gestion</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>
            <Detail>
              <DetailRow alignItems="flex-start">
                <DetailRowLabel>Tutelles</DetailRowLabel>
                <VStack alignItems="flex-start">
                  {organisation.supervisors?.length > 0 ? (
                    organisation.supervisors?.map((org) => (
                      <Text lineHeight="short">
                        {capitalizeFirstLetter(org.titleFr)}{' '}
                        <Link
                          as={NavLink}
                          color="blue.600"
                          textDecor="underline"
                          wordBreak="break-all"
                          to={`${routes.organisations}/${org.id}`}
                        >
                          {org.acronymFr}
                        </Link>
                      </Text>
                    ))
                  ) : (
                    <Text>Aucune</Text>
                  )}
                </VStack>
              </DetailRow>
              <DetailRow alignItems="flex-start">
                <DetailRowLabel>Direction actuelle</DetailRowLabel>
                <VStack alignItems="flex-start">
                  {organisation.directorsReponsibilities?.length > 0 ? (
                    organisation.directorsReponsibilities?.map((responsibility) => (
                      <HStack>
                        <Link
                          as={NavLink}
                          color="blue.600"
                          textDecor="underline"
                          wordBreak="break-all"
                          lineHeight="short"
                          to={`${routes.people}/${responsibility.userId}`}
                        >
                          {capitalizeFirstLetter(responsibility.userName)}
                        </Link>
                        <Text>({responsibility.longTitleFr})</Text>
                      </HStack>
                    ))
                  ) : (
                    <Text>Aucune</Text>
                  )}
                </VStack>
              </DetailRow>
              <DetailRow alignItems="flex-start">
                <DetailRowLabel>Gestionnaire.s actuel.les</DetailRowLabel>
                <VStack alignItems="flex-start">
                  {organisation.managersReponsibilities?.length > 0 ? (
                    organisation.managersReponsibilities?.map((responsibility) => (
                      <HStack>
                        <Link
                          as={NavLink}
                          color="blue.600"
                          textDecor="underline"
                          wordBreak="break-all"
                          lineHeight="short"
                          to={`${routes.people}/${responsibility.userId}`}
                        >
                          {capitalizeFirstLetter(responsibility.userName)}
                        </Link>
                        <Text>({responsibility.longTitleFr})</Text>
                      </HStack>
                    ))
                  ) : (
                    <Text>Aucune</Text>
                  )}
                </VStack>
              </DetailRow>
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
      </VStack>
    </>
  );
};
