import React, { useCallback, useState } from 'react';
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  Link,
  useControllableState,
  useDisclosure,
  useEditableControls,
} from '@chakra-ui/react';
import { HiCheck, HiPencil, HiX } from 'react-icons/hi';

export const EditableWithControlButton = ({ preview, isLink, linkProps, ...props }) => {
  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } =
      useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" spacing="0.5" ml={1} size="sm">
        <IconButton
          size="sm"
          variant="ghost"
          rounded="full"
          colorScheme="green"
          icon={<HiCheck />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          size="sm"
          variant="ghost"
          rounded="full"
          colorScheme="red"
          icon={<HiX />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" ml={1}>
        <IconButton size="sm" variant="action" icon={<HiPencil />} {...getEditButtonProps()} />
      </Flex>
    );
  }

  function LinkPreview() {
    const { isEditing } = useEditableControls();
    return isEditing ? null : (
      <Link
        href={props.defaultValue}
        bg="white"
        gridRow={1}
        gridColumn="1/-1"
        pos="relative"
        {...linkProps}
      >
        {props.defaultValue}
      </Link>
    );
  }

  return (
    <Editable d="flex" flexGrow={1} alignItems="center" isPreviewFocusable={false} {...props}>
      <Grid gridTemplate="1fr" alignItems="center" flexGrow={1}>
        {isLink ? <LinkPreview /> : null}
        <EditablePreview
          py={0}
          color={props.defaultValue || props.value ? null : 'gray.400'}
          {...(isLink ? { gridRow: 1, gridColumn: '1/-1' } : {})}
          {...preview}
        />
      </Grid>
      <EditableInput flexGrow={1} variant="action" />
      <EditableControls />
    </Editable>
  );
};

export const CustomEditable = (props) => {
  const {
    value: valueProp,
    onChange: onChangeProp,
    onSubmit: onSubmitProp,
    defaultValue,
    hasMultiValue,
    Input,
    previewDisplay,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [value, setValue] = useControllableState({
    defaultValue: defaultValue || '',
    value: valueProp,
    onChange: onChangeProp,
  });

  const [prevValue] = useState(value);

  const onSubmit = useCallback(() => {
    setValue(prevValue);
    onClose();
    onSubmitProp?.(value);
  }, [onClose, onSubmitProp, prevValue, setValue, value]);

  const onCancel = useCallback(() => {
    setValue(prevValue);
    onClose();
  }, [onClose, prevValue, setValue]);

  const onChange = useCallback(
    ({ name, newValue }) => {
      if (hasMultiValue) {
        setValue({ ...value, [name]: newValue });
      } else {
        setValue(newValue);
      }
    },
    [hasMultiValue, setValue, value],
  );

  function EditableControls(ownProps) {
    return isOpen ? (
      <ButtonGroup justifyContent="center" spacing="0.5" size="sm" {...ownProps}>
        <IconButton
          size="sm"
          variant="ghost"
          colorScheme="green"
          icon={<HiCheck />}
          aria-label="Submit"
          onClick={onSubmit}
        />
        <IconButton
          size="sm"
          variant="ghost"
          colorScheme="red"
          icon={<HiX />}
          aria-label="Cancel"
          onClick={onCancel}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" {...ownProps}>
        <IconButton
          size="sm"
          variant="action"
          icon={<HiPencil />}
          aria-label="Edit"
          onClick={onOpen}
        />
      </Flex>
    );
  }

  const previewText = previewDisplay?.(value) || value;

  return (
    <Grid gridTemplateColumns="1fr auto" gridGap="0.5em" alignItems="center">
      <GridItem>
        {!isOpen ? <Text>{previewText}</Text> : null}
        {isOpen ? <Input value={value} onChange={onChange} /> : null}
      </GridItem>
      <EditableControls ml={3} />
    </Grid>
  );
};
