import React from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Detail,
  DetailGroup,
  DetailGroupCol,
  DetailGroupTitle,
  DetailRowLabel,
} from '../../../../common/components/Detail';
import { createCommand } from '../../../commands/commandsSlice';
import { updateOneOrganisation } from '../../organisationsSlice';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';
import { Metadata } from '../../../../Metadata';
import { DateChooser } from '../../../../common/components/Calendar';

const tabName = 'Historique';

export const History = ({ organisation }) => {
  const dispatch = useDispatch();

  const handleDateChange = (dateType, newValue) => {
    dispatch(
      createCommand({
        code: `update_organisation_${dateType}_date`,
        newValue,
        organisationId: organisation?.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };
  const handleFlagChange = (flagType, newValue) => {
    dispatch(
      createCommand({
        code: `update_organisation_${flagType}_flag`,
        newValue,
        organisationId: organisation?.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOneOrganisation(data.command?.payload?.organisation));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${organisation.titleFr}`}
        descriptionSuffix={`Cycle de vie de l'organisation ${organisation.titleFr}`}
      />
      <VStack spacing={5} alignItems="stretch">
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Période d'existence</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>
            <Detail>
              <HStack spacing={8} align="center">
                <Flex dir="row" align="center">
                  <DetailRowLabel mr={3}>Création</DetailRowLabel>
                  <DateChooser
                    defaultDate={Date.parse(organisation.startDate) || null}
                    handleDateChange={(date) => handleDateChange('start', date)}
                  />
                  <FormControl display="flex" alignItems="center" flexGrow={0} ml={4}>
                    <FormLabel htmlFor="future-organisation" mb="0">
                      À venir
                    </FormLabel>
                    <Switch
                      id="future-organisation"
                      isChecked={organisation.future}
                      onChange={(e) => handleFlagChange('future', e.target.checked)}
                    />
                  </FormControl>
                </Flex>
                <Flex dir="row" align="center">
                  <DetailRowLabel mr={3}>Fermeture</DetailRowLabel>
                  <DateChooser
                    defaultDate={Date.parse(organisation.endDate) || null}
                    handleDateChange={(date) => handleDateChange('end', date)}
                  />
                  <FormControl display="flex" alignItems="center" ml={4}>
                    <FormLabel htmlFor="past-organisation" mb="0">
                      Fermée
                    </FormLabel>
                    <Switch
                      id="past-organisation"
                      isChecked={organisation.past}
                      onChange={(e) => handleFlagChange('past', e.target.checked)}
                    />
                  </FormControl>
                </Flex>
              </HStack>
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
        <Box>
          <Divider borderColor="gray.400" orientation="horizontal" />
        </Box>
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Filiation</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>à venir</DetailGroupCol>
        </DetailGroup>
      </VStack>
    </>
  );
};
