import { Identification } from './components/Identification';
import { Members } from './components/Members/Members';
import { Overview } from './components/Overview';
import { Relationships } from './components/Relationships/Relationships';
import { History } from './components/History';

export const organisationsRoutes = [
  {
    path: '/overview',
    label: 'Synthèse',
    component: Overview,
  },
  {
    path: '/identification',
    label: 'Identification',
    component: Identification,
  },
  {
    path: '/membres',
    label: 'Membres',
    component: Members,
  },
  {
    path: '/relationships',
    label: 'Relations',
    component: Relationships,
  },
  {
    path: '/history',
    label: 'Historique',
    component: History,
  },
];
