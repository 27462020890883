import React, { useMemo, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  ButtonGroup,
  chakra,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Radio,
  Spacer,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { HiMail } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { BiTrash } from 'react-icons/bi';
import {
  AiOutlineHome,
  AiOutlineInfoCircle,
  BsFillBriefcaseFill,
  FaPlus,
  MdCancel,
  MdCheck,
  VscBroadcast,
} from 'react-icons/all';
import { useForm } from 'react-hook-form';
import FileResizer from 'react-image-file-resizer';
import 'react-image-crop/dist/ReactCrop.css';
import { createCommand } from '../../../commands/commandsSlice';
import {
  cancelUploadPhoto,
  fetchPeopleById,
  publishPerson,
  updateOnePeople,
  uploadPhoto,
} from '../../peopleSlice';
import { acceptCommandStep, rejectCommandStep } from '../../../messages/commandStepsSlice';

import {
  Detail,
  DetailGroup,
  DetailGroupCol,
  DetailGroupTitle,
  DetailRow,
  DetailRowLabel,
} from '../../../../common/components/Detail';
import { EditableWithControlButton } from '../../../../common/components/Editable';
import { CheckboxAndDate } from '../../../../common/components/Editable/CustomEditables';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast, displayToast } from '../../../../common/utils/toasts-utils';
import { RelationshipRecap } from './RelationshipRecap';
import { ConfirmDialog } from '../../../../common/components/ConfirmDialog';
import { Metadata } from '../../../../Metadata';
import FileUpload from '../../../../common/components/FileUpload';
import { PhotoCropModal } from './PhotoCropModal';
import { check } from '../../../roleBasedAccess/Can';
import rules from '../../../roleBasedAccess/rules';
import { sortByKey } from '../../../../common/utils/array-utils';
import { stripHtml } from '../../../../common/utils/string-utils';

const tabName = 'Identité et récapitulatif';

export const Identification = ({ person }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [creatingEmail, setCreatingEmail] = useState(false);
  const [newPhoto, setNewPhoto] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user: currentUser } = useSelector((state) => state.currentUser);

  // Form

  const { register, handleSubmit, reset } = useForm();

  const handleEditInPlace = (code, payload = {}) => {
    dispatch(
      createCommand({
        code,
        ...payload,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const handleEmailRemoval = ({ emailId }) => {
    dispatch(
      createCommand({
        code: 'remove_email_from_user',
        emailId,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const handleEmailFlag = ({ field, emailId, newValue }) => {
    dispatch(
      createCommand({
        code: `update_user_email_${field}_flag`,
        emailId,
        newValue,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const handleEmailCreation = (values) => {
    dispatch(
      createCommand({
        code: 'add_email_to_user',
        newAddress: values.newAddress,
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        setCreatingEmail(false);
        reset();
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
      });
  };

  const fileChangedHandler = (event) => {
    let fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        FileResizer.imageFileResizer(
          event.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            setNewPhoto(uri);
          },
          'base64',
          200,
          200,
        );
        onOpen();
      } catch (err) {
        displayErrorToast(err);
      }
    }
  };

  const isSelfEditor = useMemo(() => {
    return check(rules, currentUser.roles, 'app:autoedit', { user: currentUser, object: person });
  }, [currentUser, person]);

  const canSubmitPhoto = useMemo(() => {
    return (
      check(rules, currentUser.roles, 'app:perimeter', { user: currentUser, object: person }) ||
      check(rules, currentUser.roles, 'app:autoedit', { user: currentUser, object: person }) ||
      check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'admin' }) ||
      check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'rdd-member' }) ||
      check(rules, currentUser.roles, 'app:role', { user: currentUser, role: 'rdd-direction' })
    );
  }, [currentUser, person]);

  const onSubmitPhoto = (cropped) => {
    dispatch(uploadPhoto({ peopleId: person.id, imageFile: cropped }))
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.person));
        onClose();
        dispatch(
          createCommand({
            code: 'update_user_photo',
            userId: person.id,
          }),
        )
          .then(unwrapResult)
          .then((data2) => {
            dispatch(updateOnePeople(data2.command?.payload?.user));
            commandSuccess(data2.command);
          })
          .catch((err, params = {}) => {
            displayErrorToast(err, params);
            dispatch(fetchPeopleById(person.id));
          });
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
        dispatch(fetchPeopleById(person.id));
      });
  };
  const onRemovePhoto = () => {
    dispatch(
      createCommand({
        code: 'remove_photo_from_user',
        userId: person.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
        dispatch(fetchPeopleById(person.id));
      });
  };
  const onCancelPhoto = () => {
    dispatch(cancelUploadPhoto({ peopleId: person.id }))
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.person));
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
        dispatch(fetchPeopleById(person.id));
      });
  };

  const handleClickPhotoValidationAction = (action) => {
    const message = person.photoValidationMessage;
    if (!message) {
      return;
    }
    dispatch(
      action === 'accept'
        ? acceptCommandStep({
            commandStepId: message.commandStep.id,
            messageId: message.id,
          })
        : rejectCommandStep({
            commandStepId: message.commandStep.id,
            messageId: message.id,
          }),
    )
      .then(unwrapResult)
      .then(() => {
        toast({
          title: 'Action effectuée',
          position: 'bottom-right',
          duration: 2000,
        });
        dispatch(fetchPeopleById(person.id));
      })
      .catch((err, params = {}) => {
        displayErrorToast(err, params);
      });
  };

  const handlePersonPublication = ({ personId }) => {
    dispatch(publishPerson({ personId }))
      .then(unwrapResult)
      .then((data) => {
        displayToast({
          status: 'success',
          title: 'Mise à jour réussie',
          description: stripHtml(data.message),
        });
      });
  };

  const emailIsProfessional = (email) => {
    return (
      email.emailType === 'institutional' ||
      email.emailType === 'professional' ||
      email.emailType === 'edugain'
    );
  };

  const orderedEmails = useMemo(() => {
    return sortByKey([...person.emails], 'address');
  }, [person]);

  return (
    <>
      <Metadata
        titleSuffix={`${tabName} ${person.displayName}`}
        descriptionSuffix={`Principales données de ${person.displayName}`}
      />
      <VStack spacing={5} alignItems="stretch">
        <DetailGroup>
          <DetailGroupCol>
            <DetailGroupTitle>Identité et coordonnées</DetailGroupTitle>
          </DetailGroupCol>
          <DetailGroupCol>
            <Detail>
              {person.status !== 'guest' && (
                <DetailRow alignItems="start">
                  <Flex direction="row">
                    <Flex direction="column">
                      <DetailRowLabel mb={3}>
                        <AvatarGroup size="2xl" max={2}>
                          <Avatar
                            borderRadius={0}
                            size="xl"
                            src={`${process.env.REACT_APP_API_URL}/${person.photoPath}`}
                            bgColor="blue.500"
                          />
                          {person.newPhotoPath && (
                            <Avatar
                              borderRadius={0}
                              size="xl"
                              src={`${process.env.REACT_APP_API_URL}/${person.newPhotoPath}`}
                              bgColor="blue.500"
                            />
                          )}
                        </AvatarGroup>
                        {person.newPhotoPath && (
                          <VStack>
                            <Flex dir="row">
                              <Badge variant="subtle" colorScheme="gray">
                                Actuelle
                              </Badge>
                              <Spacer />
                              <Badge variant="solid" colorScheme="green">
                                Nouvelle
                              </Badge>
                            </Flex>
                            <Text>En attente de validation</Text>
                            {isSelfEditor && person.photoValidationMessage && (
                              <HStack>
                                <Button
                                  size="xs"
                                  leftIcon={<MdCancel />}
                                  colorScheme="red"
                                  variant="ghost"
                                  onClick={() => handleClickPhotoValidationAction('reject')}
                                >
                                  Refuser
                                </Button>
                                <Button
                                  size="xs"
                                  leftIcon={<MdCheck />}
                                  colorScheme="green"
                                  variant="ghost"
                                  onClick={() => handleClickPhotoValidationAction('accept')}
                                >
                                  Accepter
                                </Button>
                              </HStack>
                            )}
                            {canSubmitPhoto && (
                              <Button
                                size="xs"
                                leftIcon={<MdCancel />}
                                colorScheme="gray"
                                variant="ghost"
                                onClick={onCancelPhoto}
                              >
                                Annuler la demande de changement
                              </Button>
                            )}
                          </VStack>
                        )}
                      </DetailRowLabel>
                      {person.photoPath && !person.newPhotoPath && canSubmitPhoto && (
                        <ConfirmDialog
                          control={
                            <Button
                              size="xs"
                              leftIcon={<MdCancel />}
                              colorScheme="red"
                              variant="ghost"
                              onClick={() => handleClickPhotoValidationAction('reject')}
                            >
                              Supprimer cette photo
                            </Button>
                          }
                          actionText="Supprimer"
                          headerText="Suppression de photo"
                          mainText="Voulez-vous vraiment supprimer cette photo ?"
                          callback={onRemovePhoto}
                        />
                      )}
                      {!person.newPhotoPath && canSubmitPhoto && (
                        <FileUpload
                          onChange={fileChangedHandler}
                          value={newPhoto}
                          placeholder="Changer de photo"
                          mt={3}
                        />
                      )}
                    </Flex>

                    <PhotoCropModal
                      newPhoto={newPhoto}
                      isOpen={isOpen}
                      onClose={onClose}
                      onSubmit={onSubmitPhoto}
                    />
                  </Flex>
                  <VStack alignItems="start">
                    <HStack mt={4} spacing={3}>
                      <Switch
                        id="publicAvatar"
                        mt={1}
                        isChecked={person.publicAvatar}
                        onChange={(e) =>
                          handleEditInPlace('update_user_public_avatar', {
                            newValue: e.target.checked,
                          })
                        }
                      />
                      <chakra.label htmlFor="publicAvatar" maxW="80%">
                        Publication de la photo sur internet
                      </chakra.label>
                    </HStack>

                    <Alert status="info">
                      <AlertIcon />
                      La publication de votre photograhie sur internet peut entraîner sa réplication
                      sur des moteurs de recherche, des sites web ou des réseaux sociaux hors du
                      périmètre de l'EHESS. Cette action n'est donc pas réversible.
                    </Alert>
                  </VStack>
                </DetailRow>
              )}
              <DetailRow>
                <DetailRowLabel>Nom</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={person.lastName}
                  onSubmit={(newValue) => {
                    handleEditInPlace('update_user_last_name', { newValue });
                  }}
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Prénom(s)</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={person.firstName}
                  onSubmit={(newValue) => {
                    if (newValue === person.firstName) {
                      return;
                    }
                    handleEditInPlace('update_user_first_name', { newValue });
                  }}
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Nom / langue orig.</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={person.nativeName}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_user_native_name', { newValue })
                  }
                />
              </DetailRow>
              <DetailRow>
                <DetailRowLabel>Nom d'affichage</DetailRowLabel>
                <EditableWithControlButton
                  defaultValue={person.displayName}
                  onSubmit={(newValue) =>
                    handleEditInPlace('update_user_display_name', { newValue })
                  }
                />
              </DetailRow>

              {person.status !== 'guest' && (
                <DetailRow>
                  <DetailRowLabel as="label" htmlFor="retired">
                    Retraité
                  </DetailRowLabel>
                  <CheckboxAndDate
                    resource={person}
                    checkboxName="retired"
                    dateName="dateRetired"
                    onSubmit={(newValue) =>
                      handleEditInPlace('update_user_retired', {
                        newValue: {
                          flag: newValue.retired,
                          date: newValue.dateRetired,
                        },
                      })
                    }
                  />
                </DetailRow>
              )}

              {currentUser.id !== person.id && person.status !== 'guest' ? (
                <DetailRow>
                  <DetailRowLabel as="label" htmlFor="deceased">
                    Décédé
                  </DetailRowLabel>
                  <CheckboxAndDate
                    resource={person}
                    checkboxName="deceased"
                    dateName="dateDeceased"
                    onSubmit={(newValue) =>
                      handleEditInPlace('update_user_deceased', {
                        newValue: {
                          flag: newValue.deceased,
                          date: newValue.dateDeceased,
                        },
                      })
                    }
                  />
                </DetailRow>
              ) : null}

              <DetailRow alignItems="flex-start">
                <DetailRowLabel>Emails</DetailRowLabel>
                <Box>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>
                          <VisuallyHidden>Email</VisuallyHidden>
                        </Th>
                        <Th>
                          <VisuallyHidden>Actions</VisuallyHidden>
                        </Th>
                        <Th textAlign="center">Type</Th>
                        <Th textAlign="center">Contact</Th>
                        <Th textAlign="center">Identifiant</Th>
                        <Th textAlign="center">Affiché sur le site</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orderedEmails.map((email) => (
                        <Tr
                          key={`email_${email.id}`}
                          borderInline="1px solid"
                          borderColor="gray.100"
                        >
                          <Td>
                            <EditableWithControlButton
                              defaultValue={email.address}
                              onSubmit={(newValue) =>
                                handleEditInPlace('update_user_email', {
                                  newValue,
                                  emailId: email.id,
                                })
                              }
                            />
                          </Td>
                          <Td textAlign="center">
                            <HStack spacing={0} display="inline-flex">
                              <ConfirmDialog
                                control={
                                  <IconButton
                                    aria-label="Supprimer l'adresse"
                                    size="sm"
                                    variant="action"
                                    icon={<BiTrash />}
                                  />
                                }
                                actionText="Supprimer"
                                headerText={`Suppression d'une adresse de messagerie`}
                                mainText={`Voulez-vous vraiment supprimer l'adresse "${email.address}"`}
                                callback={() => {
                                  handleEmailRemoval({ emailId: email.id });
                                }}
                              />
                              <IconButton
                                aria-label="Envoyer un message"
                                as={Link}
                                href={`mailto:${email.address}`}
                                size="sm"
                                variant="action"
                                icon={<HiMail />}
                              />
                            </HStack>
                          </Td>
                          <Td textAlign="center">
                            {emailIsProfessional(email) && (
                              <>
                                <Icon as={BsFillBriefcaseFill} /> Pro
                              </>
                            )}
                            {!emailIsProfessional(email) && (
                              <>
                                <Icon as={AiOutlineHome} /> Perso
                              </>
                            )}
                          </Td>
                          <Td textAlign="center">
                            <Radio
                              isChecked={email.contact}
                              name="contactEmail"
                              readOnly={email.contact}
                              onClick={() => {
                                if (email.contact) {
                                  return;
                                }
                                handleEmailFlag({
                                  field: 'contact',
                                  emailId: email.id,
                                  newValue: true,
                                });
                              }}
                            />
                          </Td>
                          <Td textAlign="center">
                            <Radio
                              isChecked={email.authentifier}
                              name="authentifierEmail"
                              onClick={() => {
                                handleEmailFlag({
                                  field: 'authentifier',
                                  emailId: email.id,
                                  newValue: !email.authentifier,
                                });
                              }}
                            />
                          </Td>
                          <Td textAlign="center">
                            <Radio
                              isChecked={email.displayOnWebsite}
                              name="displayOnWebsiteEmail"
                              onClick={() => {
                                handleEmailFlag({
                                  field: 'display_on_website',
                                  emailId: email.id,
                                  newValue: !email.displayOnWebsite,
                                });
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Box mt={2}>
                    {!creatingEmail && (
                      <IconButton
                        aria-label="Ajouter une adresse email"
                        size="sm"
                        variant="action"
                        ml={1.5}
                        my={1}
                        icon={<FaPlus />}
                        disabled={creatingEmail}
                        onClick={() => {
                          if (creatingEmail) {
                            return;
                          }
                          setCreatingEmail(true);
                        }}
                      />
                    )}
                    {creatingEmail && (
                      <form onSubmit={handleSubmit(handleEmailCreation)}>
                        <InputGroup>
                          <Input
                            placeholder="aaa@bbb.ccc"
                            type="email"
                            isRequired
                            variant="filled"
                            {...register('newAddress')}
                          />
                          <InputRightElement width="auto">
                            <ButtonGroup size="sm" pr={3}>
                              <Button type="submit" h={7} colorScheme="blue">
                                Ajouter
                              </Button>
                              <Button
                                type="reset"
                                variant="link"
                                colorScheme="red"
                                h={7}
                                onClick={() => {
                                  reset();
                                  setCreatingEmail(false);
                                }}
                              >
                                Annuler
                              </Button>
                            </ButtonGroup>
                          </InputRightElement>
                        </InputGroup>
                      </form>
                    )}
                  </Box>
                </Box>
              </DetailRow>
              {person.status === 'guest' && (
                <DetailRow>
                  <DetailRowLabel as="label" htmlFor="invitationInformation">
                    Invitation
                  </DetailRowLabel>
                  <Flex>
                    <Text id="invitationInformation">
                      Invité par : <b>{person.createdBy || 'n/c'}</b>. Date :{' '}
                      <b>{person.createdAt}</b>
                    </Text>
                  </Flex>
                </DetailRow>
              )}
            </Detail>
          </DetailGroupCol>
        </DetailGroup>
        {person.status !== 'guest' && (
          <>
            <Box>
              <Divider borderColor="gray.400" orientation="horizontal" />
            </Box>
            <DetailGroup>
              <DetailGroupCol>
                <DetailGroupTitle>Profil professionnel</DetailGroupTitle>
              </DetailGroupCol>
              <DetailGroupCol>
                <Detail>
                  <RelationshipRecap
                    label="Statut"
                    person={person}
                    relationshipType="functions"
                    organisationType="institution"
                  />
                  <RelationshipRecap
                    label="Appartenance(s)"
                    person={person}
                    relationshipType="memberships"
                    organisationType="structure"
                  />
                  <RelationshipRecap
                    label="Responsabilité(s)"
                    person={person}
                    relationshipType="responsibilities"
                    organisationType="organisation"
                  />
                </Detail>
              </DetailGroupCol>
            </DetailGroup>
            <Box>
              <Divider borderColor="gray.400" orientation="horizontal" />
            </Box>
            <DetailGroup>
              <DetailGroupCol>
                <DetailGroupTitle>Affichages sur internet</DetailGroupTitle>
              </DetailGroupCol>
              <DetailGroupCol>
                <Detail>
                  {/*
                  <DetailRow>
                    <DetailRowLabel>Page web</DetailRowLabel>
                    <Link href={person.web_page} target="_blank">
                      {person.web_page || 'https://page-web.fr'}
                    </Link>
                  </DetailRow>
                  <DetailRow>
                    <DetailRowLabel>Page Vivo</DetailRowLabel>
                    <Link href={person.vivo_page} target="_blank">
                      {person.vivo_page || 'https://page-web.fr'}
                    </Link>
                  </DetailRow>

                  <DetailRow>
                    <DetailRowLabel as="label" htmlFor="displayOnVivo">
                      Afficher sur Vivo
                    </DetailRowLabel>
                    <Flex>
                      <Switch
                        id="displayOnVivo"
                        isChecked={person.displayOnVivo}
                        onChange={(e) =>
                          handleEditInPlace('update_user_display_on_vivo', {
                            newValue: e.target.checked,
                          })
                        }
                      />
                    </Flex>
                  </DetailRow>
                  */}
                  <DetailRow>
                    <DetailRowLabel as="label" htmlFor="displayOnWebsite">
                      Afficher sur le site web
                    </DetailRowLabel>
                    <Flex>
                      <Switch
                        mt={1.5}
                        id="displayOnWebsite"
                        isChecked={person.displayOnWebsite}
                        onChange={(e) =>
                          handleEditInPlace('update_user_display_on_website', {
                            newValue: e.target.checked,
                          })
                        }
                      />
                      {person.validated || (
                        <HStack ml={5}>
                          <AiOutlineInfoCircle />
                          <Text ml={5}>
                            {' '}
                            La diffusion sur le site web ne prend effet que si la fiche est validée.
                          </Text>
                        </HStack>
                      )}
                    </Flex>
                  </DetailRow>
                  <DetailRow>
                    <DetailRowLabel as="label" htmlFor="republish">
                      <Icon as={VscBroadcast} boxSize="1.75em" margin={1.5} /> Mise à jour
                    </DetailRowLabel>
                    <Flex>
                      <Text>
                        Cliquez{' '}
                        <Button
                          id="republish"
                          variant="outline"
                          colorScheme="blue"
                          size="xs"
                          onClick={() => handlePersonPublication({ personId: person.id })}
                        >
                          sur ce bouton
                        </Button>{' '}
                        si les données ou le compte utilisateur de {person.displayName} ne sont pas
                        présentes sur les autres applications, ou à l'inverse si elles sont encore
                        présentes alors qu'elles devraient avoir disparu.
                      </Text>
                    </Flex>
                  </DetailRow>
                </Detail>
              </DetailGroupCol>
            </DetailGroup>
          </>
        )}
      </VStack>
    </>
  );
};
