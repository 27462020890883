import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { history } from '../../common/routes/history';
import { routes } from '../../common/routes/routes';
import { logout } from '../login/currentUserSlice';

const Logout = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        toast({
          title: 'Déconnexion',
          description: 'Vous avez été déconnecté avec succès',
          status: 'info',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      })
      .catch(() => {
        toast({
          title: 'Déconnexion',
          description: 'Une erreur est survenue lors de la déconnexion',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      })
      .finally(() => history.push(routes.login));
  }, [dispatch, toast]);
  return null;
};

export default Logout;
