import React, { memo, useMemo, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from '@chakra-ui/react';
import isEmail from 'validator/es/lib/isEmail';
import { isEmpty } from '../../utils/string-utils';
import { duplicateCheck } from '../../../api/duplicateCheck';
import { displayErrorToast } from '../../utils/toasts-utils';
import Select from '../Select';

const StructureTypesSelect = ({
  handleSelectedItemsChange,
  placeholder,
  value,
  structureTypes = [],
  ...props
}) => {
  const items = structureTypes.map((structureType) => ({
    label: structureType.longNameFr,
    value: structureType.id,
    data: structureType,
  }));

  return (
    <Select
      defaultValue={value}
      options={items}
      placeholder={placeholder}
      onChange={handleSelectedItemsChange}
      isClearable={false}
      isRequired
      {...props}
    />
  );
};

const CreationModalComponent = memo(
  ({ onClose, isOpen, onSubmit, loading, entityType, structureTypes = [] }) => {
    const FORM_STEP = 0;
    const NON_BLOCKING_CONTROL_STEP = 1;
    const BLOCKING_CONTROL_STEP = 2;
    const CONFIRMATION_STEP = 3;

    const [step, setStep] = useState(FORM_STEP);
    const [waiting, setWaiting] = useState(false);
    const [fields, setFields] = useState({
      firstName: '',
      lastName: '',
      email: '',
      guest: '',
    });
    const [fieldsProposals, setFieldsProposals] = useState({
      firstName: '',
      lastName: '',
      email: '',
      guest: '',
    });
    const [duplicates, setDuplicates] = useState([]);

    const requiredFields = {
      person: ['firstName', 'lastName', 'email'],
      organisation: ['titleFr', 'acronymFr', 'structureType'],
    };
    const handleReset = () => {
      setStep(FORM_STEP);
      onClose();
    };
    const handleCreation = () => {
      onSubmit(fields);
    };
    const handleBackToForm = () => {
      setStep(FORM_STEP);
    };

    const handleControl = () => {
      setWaiting(true);
      duplicateCheck(entityType, fields)
        .then((data) => {
          const payload = data?.data?.payload;
          const success = data?.data?.success;
          if (!success) {
            setStep(CONFIRMATION_STEP);
          } else if (payload.duplicate) {
            setDuplicates([payload.duplicate]);
            setStep(BLOCKING_CONTROL_STEP);
          } else if (payload.potentialDups.length > 0) {
            setDuplicates(payload.potentialDups);
            setStep(NON_BLOCKING_CONTROL_STEP);
          }
          setWaiting(false);
        })
        .catch((err, params) => {
          setWaiting(false);
          setStep(FORM_STEP);
          displayErrorToast(err, params);
        });
    };

    const entityName = useMemo(
      () => (entityType === 'person' ? 'une personne' : 'une organisation'),
      [entityType],
    );
    const formReady = useMemo(() => {
      let ready = true;
      requiredFields[entityType].forEach(
        (fieldName) => (ready = ready && !isEmpty(fields[fieldName])),
      );
      return ready;
    }, [fields]);

    return (
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Créer {entityName} {fields.guest && <Badge colorScheme="purple">Invité</Badge>}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {step === FORM_STEP && entityType === 'person' && (
              <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 2fr)" gap={4}>
                <GridItem>
                  <FormControl id="last_name" isRequired>
                    <FormLabel>Nom</FormLabel>
                    <Input
                      type="text"
                      value={fields.lastName}
                      onChange={(e) => setFields({ ...fields, lastName: e.target.value })}
                    />
                    <FormHelperText>Nom de famille</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="first_name" isRequired>
                    <FormLabel>Prénom</FormLabel>
                    <Input
                      type="text"
                      value={fields.firstName}
                      onChange={(e) => setFields({ ...fields, firstName: e.target.value })}
                    />
                    <FormHelperText>Prénom</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="display_name">
                    <FormLabel>Nom d'Usage</FormLabel>
                    <Input
                      type="text"
                      value={fields.displayName}
                      onChange={(e) => setFields({ ...fields, displayName: e.target.value })}
                    />
                    <FormHelperText>
                      Nom complet utilisé dans le cadre professionnel. ex : Durand, Julie{' '}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="email" isRequired>
                    <FormLabel>Adresse mail</FormLabel>
                    <Input
                      isInvalid={!isEmpty(fieldsProposals.email) && isEmpty(fields.email)}
                      type="email"
                      value={fieldsProposals.email}
                      onChange={(e) => {
                        setFieldsProposals({ ...fieldsProposals, email: e.target.value });
                        if (isEmail(e.target.value)) {
                          setFields({ ...fields, email: e.target.value });
                        } else {
                          setFields({ ...fields, email: '' });
                        }
                      }}
                    />
                    <FormHelperText>Adresse mail, de préférence professionnelle</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem>&nbsp;</GridItem>
                <GridItem colSpan={2}>
                  <FormLabel htmlFor="is-guest" mb="0">
                    Invité(e) (simple utilisateur ou utilisatrice d'une application telle que
                    didomena, sans autre lien avec l'établissement).
                  </FormLabel>
                  <Switch
                    id="is-guest"
                    isChecked={fieldsProposals.guest}
                    onChange={(e) => {
                      setFieldsProposals({ ...fieldsProposals, guest: e.target.checked });
                      setFields({ ...fields, guest: e.target.checked });
                    }}
                  />
                </GridItem>
                <GridItem colSpan={2} rowSpan={2}>
                  <FormControl as="fieldset" border="1px" borderColor="gray.200" p={5}>
                    <FormLabel as="legend">Identifiants</FormLabel>
                    <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 2fr)" gap={4}>
                      <GridItem>
                        <FormControl id="idref">
                          <FormLabel>Idref</FormLabel>
                          <Input
                            type="text"
                            value={fields.idrefId}
                            onChange={(e) => setFields({ ...fields, idrefId: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant idref (si connu){' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link href="https://www.idref.fr/" rel="noreferrer" target="_blank">
                                Rechercher sur Idref
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl id="idhal">
                          <FormLabel>IdHal</FormLabel>
                          <Input
                            type="text"
                            value={fields.idhal}
                            onChange={(e) => setFields({ ...fields, idhal: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant idhal (si connu){' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link
                                href="https://aurehal.archives-ouvertes.fr/author/index"
                                rel="noreferrer"
                                target="_blank"
                              >
                                Rechercher sur Aurehal
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>

                      <GridItem>
                        <FormControl id="isni">
                          <FormLabel>Isni</FormLabel>
                          <Input
                            type="text"
                            value={fields.isniId}
                            onChange={(e) => setFields({ ...fields, isniId: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant ISNI (si connu){' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link
                                href="https://isni.org/page/search-database/"
                                rel="noreferrer"
                                target="_blank"
                              >
                                Rechercher sur ISNI
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>

                      <GridItem>
                        <FormControl id="Orcid">
                          <FormLabel>Orcid</FormLabel>
                          <Input
                            type="text"
                            value={fields.orcidId}
                            onChange={(e) => setFields({ ...fields, orcidId: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant Orcid (si connu).{' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link
                                href="https://orcid.org/orcid-search/search"
                                rel="noreferrer"
                                target="_blank"
                              >
                                Rechercher sur ORCID
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </FormControl>
                </GridItem>
              </Grid>
            )}
            {step === FORM_STEP && entityType === 'organisation' && (
              <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 2fr)" gap={4}>
                <GridItem colSpan={2}>
                  <FormControl id="title_fr" isRequired>
                    <FormLabel>Nom français</FormLabel>
                    <Input
                      type="text"
                      value={fields.titleFr}
                      onChange={(e) => setFields({ ...fields, titleFr: e.target.value })}
                    />
                    <FormHelperText>Intitulé de l'organisation en français</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="title_en">
                    <FormLabel>Nom anglais</FormLabel>
                    <Input
                      type="text"
                      value={fields.titleEn}
                      onChange={(e) => setFields({ ...fields, titleEn: e.target.value })}
                    />
                    <FormHelperText>Intitulé de l'organisation en anglais</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="acronym_fr" isRequired>
                    <FormLabel>Acronyme (français)</FormLabel>
                    <Input
                      type="text"
                      value={fields.acronymFr}
                      onChange={(e) => setFields({ ...fields, acronymFr: e.target.value })}
                    />
                    <FormHelperText>Acronyme de la structure en français.</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="acronym_en">
                    <FormLabel>Acronyme (anglais)</FormLabel>
                    <Input
                      type="text"
                      value={fields.acronymEn}
                      onChange={(e) => setFields({ ...fields, acronymEn: e.target.value })}
                    />
                    <FormHelperText>Acronyme de la structure en anglais.</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="structure_type" isRequired>
                    <FormLabel>Type d'organisation</FormLabel>
                    <StructureTypesSelect
                      id="structure_type"
                      value={fields.structureType?.id}
                      placeholder="Choisissez le type général de structure"
                      structureTypes={structureTypes.filter((st) => st.generic)}
                      handleSelectedItemsChange={(selection) => {
                        setFields({ ...fields, structureType: selection.data });
                      }}
                    />
                    <FormHelperText>
                      Vous pourrez ensuite préciser cette information.
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem>&nbsp;</GridItem>
                <GridItem colSpan={2} rowSpan={2}>
                  <FormControl as="fieldset" border="1px" borderColor="gray.200" p={5}>
                    <FormLabel as="legend">Identifiants</FormLabel>
                    <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 2fr)" gap={4}>
                      <GridItem>
                        <FormControl id="idref">
                          <FormLabel>Idref</FormLabel>
                          <Input
                            type="text"
                            value={fields.idrefId}
                            onChange={(e) => setFields({ ...fields, idrefId: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant idref (si connu){' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link href="https://www.idref.fr/" rel="noreferrer" target="_blank">
                                Rechercher sur Idref
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl id="idhal">
                          <FormLabel>IdHal</FormLabel>
                          <Input
                            type="text"
                            value={fields.idhal}
                            onChange={(e) => setFields({ ...fields, idhal: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant HAL (si connu){' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link
                                href="https://aurehal.archives-ouvertes.fr/structure/index"
                                rel="noreferrer"
                                target="_blank"
                              >
                                Rechercher sur Aurehal
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>

                      <GridItem>
                        <FormControl id="rnsr">
                          <FormLabel>Identifiant RNSR</FormLabel>
                          <Input
                            type="text"
                            value={fields.rnsrId}
                            onChange={(e) => setFields({ ...fields, rnsrId: e.target.value })}
                          />
                          <FormHelperText>
                            Identifiant RNSR (si connu){' '}
                            <Button size="xs" colorScheme="teal" variant="link">
                              <Link
                                href="https://scanr.enseignementsup-recherche.gouv.fr/"
                                rel="noreferrer"
                                target="_blank"
                              >
                                Rechercher sur ScanR
                              </Link>
                            </Button>
                          </FormHelperText>
                        </FormControl>
                      </GridItem>

                      {fields.structureType?.code !== 'institution' && (
                        <GridItem>
                          <FormControl id="cnrs_id">
                            <FormLabel>Identifiant CNRS</FormLabel>
                            <Input
                              type="text"
                              value={fields.cnrsId}
                              onChange={(e) => setFields({ ...fields, cnrsId: e.target.value })}
                            />
                            <FormHelperText>
                              Identifiant CNRS (si connu).{' '}
                              <Button size="xs" colorScheme="teal" variant="link">
                                <Link
                                  href="https://scanr.enseignementsup-recherche.gouv.fr/"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Rechercher sur ScanR
                                </Link>
                              </Button>
                            </FormHelperText>
                          </FormControl>
                        </GridItem>
                      )}
                      {fields.structureType?.code === 'institution' && (
                        <GridItem>
                          <FormControl id="cnrs_id">
                            <FormLabel>UAI</FormLabel>
                            <Input
                              type="text"
                              value={fields.uai}
                              onChange={(e) => setFields({ ...fields, uai: e.target.value })}
                            />
                            <FormHelperText>
                              UAI (si connu).{' '}
                              <Button size="xs" colorScheme="teal" variant="link">
                                <Link
                                  href="https://data.enseignementsup-recherche.gouv.fr/explore/dataset/fr-esr-principaux-etablissements-enseignement-superieur"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Rechercher sur data.gouv.fr
                                </Link>
                              </Button>
                            </FormHelperText>
                          </FormControl>
                        </GridItem>
                      )}
                    </Grid>
                  </FormControl>
                </GridItem>
              </Grid>
            )}
            {(step === NON_BLOCKING_CONTROL_STEP ||
              step === BLOCKING_CONTROL_STEP ||
              step === CONFIRMATION_STEP) &&
              entityType === 'person' && (
                <Grid
                  templateRows="repeat(2, 1fr)"
                  templateColumns="repeat(2, 2fr)"
                  gap={4}
                  mb={5}
                  mx={20}
                >
                  <GridItem>
                    <strong>Nom : </strong>
                    {fields.lastName}
                  </GridItem>
                  <GridItem>
                    <strong>Prénom : </strong>
                    {fields.firstName}
                  </GridItem>
                  <GridItem colSpan={2}>
                    <strong>Nom d'usage : </strong>
                    {fields.displayName || ' n/c'}
                  </GridItem>
                  <GridItem>
                    <strong>Email : </strong>
                    {fields.email}
                  </GridItem>
                  <GridItem>&nbsp;</GridItem>
                  <GridItem>
                    <strong>Idref : </strong>
                    {fields.idrefId || ' n/c'}
                  </GridItem>
                  <GridItem>
                    <strong>Idhal : </strong>
                    {fields.idhal || ' n/c'}
                  </GridItem>
                  <GridItem>
                    <strong>Isni : </strong>
                    {fields.isniId || ' n/c'}
                  </GridItem>
                  <GridItem>
                    <strong>Orcid : </strong>
                    {fields.orcidId || ' n/c'}
                  </GridItem>
                </Grid>
              )}
            {(step === NON_BLOCKING_CONTROL_STEP ||
              step === BLOCKING_CONTROL_STEP ||
              step === CONFIRMATION_STEP) &&
              entityType === 'organisation' && (
                <Grid
                  templateRows="repeat(2, 1fr)"
                  templateColumns="repeat(2, 2fr)"
                  gap={4}
                  mb={5}
                  mx={20}
                >
                  <GridItem colSpan={2}>
                    <strong>Nom français : </strong>
                    {fields.titleFr}
                  </GridItem>
                  <GridItem colSpan={2}>
                    <strong>Nom anglais : </strong>
                    {fields.titleEn}
                  </GridItem>
                  <GridItem colSpan={2}>
                    <strong>Type d'organisation : </strong>
                    {fields.structureType.longNameFr}
                  </GridItem>
                  <GridItem>
                    <strong>Idref : </strong>
                    {fields.idrefId || ' n/c'}
                  </GridItem>
                  <GridItem>
                    <strong>Idhal : </strong>
                    {fields.idhal || ' n/c'}
                  </GridItem>
                  {fields.structureType?.code !== 'institution' && (
                    <GridItem>
                      <strong>Isni : </strong>
                      {fields.isniId || ' n/c'}
                    </GridItem>
                  )}
                  {fields.structureType?.code === 'institution' && (
                    <GridItem>
                      <strong>UAI : </strong>
                      {fields.uai || ' n/c'}
                    </GridItem>
                  )}
                  <GridItem>
                    <strong>Orcid : </strong>
                    {fields.orcidId || ' n/c'}
                  </GridItem>
                </Grid>
              )}
            {step === NON_BLOCKING_CONTROL_STEP && entityType === 'person' && (
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Cette personne est peut-être déjà référencée</AlertTitle>
                  <AlertDescription display="block">
                    <Box align="center" my={2}>
                      {duplicates.map((dup) => {
                        return (
                          <Button ml={5} colorScheme="teal" variant="ghost">
                            <Link href={`people/${dup.id}`} target="_blank">
                              {dup.name}
                            </Link>
                          </Button>
                        );
                      })}
                    </Box>
                    Si c'est le cas, cliquez sur "Annuler" pour ne pas générer de doublon.
                  </AlertDescription>
                </Box>
              </Alert>
            )}

            {step === NON_BLOCKING_CONTROL_STEP && entityType === 'organisation' && (
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Cette organisation est peut-être déjà référencée</AlertTitle>
                  <AlertDescription display="block">
                    <Box align="center" my={2}>
                      {duplicates.map((dup) => {
                        return (
                          <Button ml={5} colorScheme="teal" variant="ghost">
                            <Link href={`people/${dup.id}`} target="_blank">
                              {dup.name}
                            </Link>
                          </Button>
                        );
                      })}
                    </Box>
                    Si c'est le cas, cliquez sur "Annuler" pour ne pas générer de doublon.
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            {step === BLOCKING_CONTROL_STEP && entityType === 'person' && (
              <Alert status="error">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>
                    Cette personne est déjà référencée par son {duplicates[0].field}.
                  </AlertTitle>
                  <AlertDescription display="block">
                    <Box align="center" my={2}>
                      {duplicates.map((dup) => {
                        return (
                          <Button ml={5} colorScheme="teal" variant="ghost">
                            <Link href={`people/${dup.user.id}`} target="_blank">
                              {dup.user.name}
                            </Link>
                          </Button>
                        );
                      })}
                    </Box>
                    <strong>Revenir à la saisie</strong> si c'est une erreur, sinon{' '}
                    <strong>Annuler</strong>.
                  </AlertDescription>
                </Box>
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleReset} mr={3}>
              Annuler
            </Button>
            {step === FORM_STEP && (
              <Button
                colorScheme="blue"
                isLoading={waiting}
                disabled={!formReady}
                onClick={handleControl}
                loadingText="Vérification en cours"
              >
                Suite
              </Button>
            )}
            {(step === NON_BLOCKING_CONTROL_STEP ||
              step === BLOCKING_CONTROL_STEP ||
              step === CONFIRMATION_STEP) && (
              <Button colorScheme="blue" onClick={handleBackToForm} mr={3}>
                Revenir à la saisie
              </Button>
            )}
            {step === NON_BLOCKING_CONTROL_STEP && (
              <Button
                colorScheme="blue"
                onClick={handleCreation}
                isLoading={loading}
                loadingText="Création en cours"
              >
                Créer quand-même
              </Button>
            )}
            {step === CONFIRMATION_STEP && (
              <Button
                colorScheme="blue"
                onClick={handleCreation}
                isLoading={loading}
                loadingText="Création en cours"
              >
                Créer
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);

export { CreationModalComponent };
