import { apiV1 } from './api';

const getCurrentUserProfile = () => apiV1.get('/users/show_current_user');
const logout = () => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `${process.env.REACT_APP_API_URL}/users/sign_out`;
  document.body.appendChild(form);
  form.submit();
};
const uploadPhoto = (peopleId, imagefile) => {
  const formData = new FormData();
  formData.append('image', imagefile);
  return apiV1.post(`/persons/${peopleId}/update_photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const cancelUploadPhoto = (peopleId) => {
  return apiV1.post(`/persons/${peopleId}/cancel_update_photo`);
};
const getUsersList = (params = {}) => apiV1.get('/users', { params });

const getUser = (userId) => apiV1.get(`/users/${userId}`);

const publishPerson = (personId) => apiV1.post(`/persons/${personId}/publish`);

export {
  getCurrentUserProfile,
  getUsersList,
  getUser,
  logout,
  uploadPhoto,
  cancelUploadPhoto,
  publishPerson,
};
