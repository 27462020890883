import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { clearErrors } from './errorsSlice';

export const Errors = () => {
  const error = useSelector((state) => state.errors);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error.type) {
      switch (error.type) {
        case 500:
        case 401:
        case 403:
        case 422:
          toast({
            ...error,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
            onCloseComplete: () => dispatch(clearErrors()),
          });
          if (error.type === 401) {
            localStorage.removeItem('user_sangam');
          }
          break;
        case 409:
          break;
        default:
          toast({
            ...error,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
            onCloseComplete: () => dispatch(clearErrors()),
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return null;
};
