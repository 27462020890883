import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { BiCheckCircle, BiExport, BiGitMerge, BiTrash, BiXCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaPlus } from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import { displayErrorToast } from '../../../common/utils/toasts-utils';
import {
  deleteManyPeople,
  deleteOnePeople,
  updateManyPeople,
  updateOnePeople,
} from '../peopleSlice';
import { commandSuccess } from '../../../common/utils/commands-utils';
import { createCommand } from '../../commands/commandsSlice';
import { DeduplicationModal as DeduplicationModalComponent } from '../../../common/components/DeduplicationModal';
import { CreationModalComponent } from '../../../common/components/CreationModal';
import { cleanEmptyEntries } from '../../../common/utils/hash-utils';

const attributesMap = {
  username: 'Identifiant',
  displayName: "Nom d'affichage",
  lastName: 'Nom de famille',
  firstName: 'Prénom',
  nativeName: "Nom langue d'origine",
  ldapUid: 'ldapUid',
  vivoId: 'Identifiant Vivo',
  externalId: "identifiant pour l'authentificaion",
  idhalI: 'Identifiant hal numérique',
  idhalS: 'Identifiant hal alphanumérique',
  description: 'Decription',
  researchDescription: 'Decription des recherches',
  teachingDescription: 'Decription des enseignements',
  deceased: 'Décédé',
  dateDeceased: 'Date de décès',
  retired: 'retraité',
  dateRetired: 'Date de retraite',
  photoPath: 'Photo',
};

export const useBulkActions = (initialState, { updatePersonsList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const { pending: commandLoading } = useSelector((state) => state.commands);
  const [selectedRows, setSelectedRows] = useState(initialState);
  const [toggledClearRows, setToggledClearRows] = useState(false);

  const clearSelectedRows = () => {
    setToggledClearRows(!toggledClearRows);
    setSelectedRows([]);
  };

  const handleMultiplePersonsUpdate = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(updateManyPeople(data.payload?.users));
        commandSuccess(data);
      })
      .catch(displayErrorToast);
  };

  const handleMultiplePersonsDelete = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(deleteManyPeople(data.payload?.users));
        commandSuccess(data);
      })
      .catch(displayErrorToast);
  };

  const handlePersonUpdate = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };

  const handlePersonDelete = (action) => {
    return dispatch(action)
      .then(unwrapResult)
      .then((data) => {
        clearSelectedRows();
        dispatch(deleteOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
      })
      .then(updatePersonsList)
      .catch(displayErrorToast);
  };

  const handleDeduplication = (commandParameters) => {
    return handlePersonUpdate(
      createCommand({
        code: 'merge_people',
        ...commandParameters,
      }),
    ).then(updatePersonsList);
  };

  const handlePersonCreate = (commandParameters) => {
    return dispatch(
      createCommand({
        code: 'create_user',
        ...cleanEmptyEntries(commandParameters),
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        commandSuccess(data.command);
        history.push({
          pathname: `/people/${data.command.payload.user.id}/identity`,
        });
      })
      .catch(displayErrorToast);
  };

  const handleValidation = (boolean, personId) => {
    handlePersonUpdate(
      createCommand({
        code: 'validate_user',
        newValue: boolean,
        userId: personId,
      }),
    );
  };

  const handleDeletion = (userId) => {
    return handlePersonDelete(
      createCommand({
        code: 'delete_user',
        userId,
      }),
    );
  };

  const handleMultipleValidation = (boolean, personIds) => {
    personIds.forEach((personId) => {
      handleValidation(boolean, personId);
    });
  };

  const handleMultipleDeletion = (personIds) => {
    personIds.forEach((personId) => {
      handleDeletion(personId);
    });
  };

  const handleClickValidation = (boolean, person) => {
    if (boolean === person.validated) {
      return;
    }
    handleValidation(boolean, person.id);
  };

  const handleClickDeletion = (person) => {
    return handleDeletion(person.id);
  };

  const handleClickBulkValidation = (boolean) => {
    handleMultipleValidation(
      boolean,
      selectedRows.filter((row) => boolean !== row.validated).map((row) => row.id),
    );
  };

  const handleClickBulkDeletion = () => {
    handleMultipleDeletion(selectedRows.map((row) => row.id));
  };

  const ContextActionsMenu = useMemo(
    () => (
      <Flex
        alignItems="center"
        direction="row"
        position="absolute"
        zIndex={10}
        w="calc(100% - 3rem)"
        h="56px"
        bg="gray.200"
        pl={2}
        style={{
          marginLeft: '3rem',
          marginTop: '56px',
          paddingRight: '3em',
        }}
      >
        <Text color="gray.500">{selectedRows.length} sélectionné(s)</Text>
        <Box mx={4} alignSelf="stretch" py={2}>
          <Divider orientation="vertical" borderColor="gray.400" />
        </Box>
        <HStack>
          <Tooltip shouldWrapChildren hasArrow label="Valider" bg="gray.600">
            <IconButton
              isDisabled={selectedRows.length === 0}
              aria-label="Valider"
              variant="action"
              icon={<BiCheckCircle />}
              onClick={() => handleClickBulkValidation(true)}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Invalider" bg="gray.600">
            <IconButton
              isDisabled={selectedRows.length === 0}
              aria-label="Invalider"
              variant="action"
              icon={<BiXCircle />}
              onClick={() => handleClickBulkValidation(false)}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Supprimer" bg="red.600">
            <IconButton
              isDisabled={selectedRows.length === 0}
              variant="action"
              icon={<BiTrash />}
              aria-label="Supprimer"
              onClick={handleClickBulkDeletion}
            />
          </Tooltip>
          <Tooltip shouldWrapChildren hasArrow label="Fusionner" bg="gray.600">
            <IconButton
              isDisabled={selectedRows.length < 2}
              variant="action"
              aria-label="Fusionner"
              icon={<BiGitMerge />}
              onClick={onOpen}
            />
          </Tooltip>

          <Tooltip shouldWrapChildren hasArrow label="Exporter" bg="gray.600">
            <IconButton isDisabled variant="action" icon={<BiExport />} aria-label="Exporter" />
          </Tooltip>
        </HStack>
        <Spacer />
        <Button
          variant="outline"
          leftIcon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpen2}
        >
          Créer une personne
        </Button>
      </Flex>
    ),
    [selectedRows],
  );

  const DeduplicationModal = () => (
    <DeduplicationModalComponent
      entitiesToMerge={selectedRows}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleDeduplication}
      loading={commandLoading}
      entityName="personnes"
      titleAttribute="displayName"
      attributesMap={attributesMap}
    />
  );

  const CreationModal = useMemo(() => {
    return (
      <CreationModalComponent
        isOpen={isOpen2}
        onClose={onClose2}
        onSubmit={handlePersonCreate}
        loading={commandLoading}
        entityType="person"
        structureTypes={[]}
      />
    );
  }, [isOpen2, onClose2, commandLoading]);

  return [
    toggledClearRows,
    ContextActionsMenu,
    DeduplicationModal,
    CreationModal,
    {
      setSelectedRows,
      clearSelectedRows,
      handleClickBulkValidation,
      handleClickValidation,
      handleClickDeletion,
    },
  ];
};
