import React, { useRef } from 'react';
import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { GiPhotoCamera } from 'react-icons/all';

const FileUpload = ({ name, placeholder, acceptedFileTypes, onChange }) => {
  const inputRef = useRef();
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon as={GiPhotoCamera} />
      </InputLeftElement>
      <input
        type="file"
        accept={acceptedFileTypes}
        name={name}
        ref={inputRef}
        onChange={onChange}
        style={{ display: 'none' }}
      />
      <Input
        type="button"
        value={placeholder}
        placeholder={placeholder || 'Your file ...'}
        onClick={() => inputRef.current.click()}
      />
    </InputGroup>
  );
};

export default FileUpload;
