import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, HStack, useToast } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import { clearById } from './notificationsSlice';
import { acceptCommandStep, rejectCommandStep } from '../messages/commandStepsSlice';
import { stripHtml } from '../../common/utils/string-utils';

const Notifications = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.notifications);

  const handleClickCommandStepAction = (action, commandStepId, messageId) => {
    dispatch(
      action === 'accept'
        ? acceptCommandStep({
            commandStepId,
            messageId,
          })
        : rejectCommandStep({
            commandStepId,
            messageId,
          }),
    )
      .then(unwrapResult)
      .then(() => {
        toast({
          title: 'Action effectuée',
          position: 'bottom-right',
          duration: 2000,
        });
      })
      .catch((err) => {
        if (err.errors || err.message) {
          toast({
            status: 'error',
            title: 'Erreur',
            position: 'top-right',
            description: err?.errors?.base || err.message,
          });
        }
      });
  };

  useEffect(() => {
    Object.values(messages).map((notif) => {
      if (!toast.isActive(`notification-${notif.id}`)) {
        return toast({
          id: `notification-${notif.id}`,
          title: notif.title_fr,
          description: (
            <p>
              {stripHtml(notif.text_fr)}
              <Divider orientation="horizontal" />
              {notif.command_step?.type !== 'Validation' && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  backgroundColor="blue.700"
                  mt={3}
                  onClick={() => window.location.reload()}
                >
                  Recharger la page
                </Button>
              )}
              {notif.command_step?.type === 'Validation' && (
                <HStack spacing={3}>
                  {' '}
                  <Button
                    size="sm"
                    colorScheme="green"
                    backgroundColor="green"
                    mt={3}
                    onClick={() => {
                      handleClickCommandStepAction('accept', notif.command_step.id, notif.id);
                      toast.close(`notification-${notif.id}`);
                    }}
                  >
                    Accepter
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="red"
                    backgroundColor="red"
                    mt={3}
                    onClick={() => {
                      handleClickCommandStepAction('reject', notif.command_step.id, notif.id);
                      toast.close(`notification-${notif.id}`);
                    }}
                  >
                    Refuser
                  </Button>
                </HStack>
              )}
            </p>
          ),
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          onCloseComplete: () => dispatch(clearById({ id: notif.id })),
        });
      }
      return null;
    });
  }, [messages, toast, dispatch]);

  return null;
};

export default Notifications;
