import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import { clear, createCommand } from '../../commands/commandsSlice';
import { capitalizeFirstLetter } from '../../../common/utils';
import { OrganisationsAutocomplete } from '../../organisations/components/OrganisationsAutocomplete';
import { updateOnePeople } from '../../people/peopleSlice';
import { DateChooser } from '../../../common/components/Calendar';
import {
  convertLocalToUTCDate,
  futureFlag,
  isFutureFlagFrozen,
  isPastFlagFrozen,
  pastFlag,
} from '../../../common/utils/date-utils';
import {
  relationshipClassLabels,
  relationshipTypelabels,
} from '../../../common/utils/relationships-utils';
import { commandSuccess } from '../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../common/utils/toasts-utils';
import { duplicateCheck } from '../../../api/duplicateCheck';
import { fetchMemberClasses } from '../../../api/memberClasses';

export const RelationshipFormModal = ({
  onClose,
  isOpen,
  person,
  entity,
  entityName,
  organisationType,
  organisationTypeLabel,
  organisation,
  relationTypeFetchFunction,
  mode = 'add',
}) => {
  const dispatch = useDispatch();
  const {
    entities: relationshipTypes,
    pending,
    error,
  } = useSelector((state) => {
    return state[`${entityName}Types`];
  });
  const { error: errorCommand } = useSelector((state) => state.commands);

  const [memberClasses, setMemberClasses] = useState([]);
  const [keepLabel, setKeepLabel] = useState(false);
  const [preventKeepLabel, setPreventKeepLabel] = useState(false);

  // Form

  const populateForm = () => ({
    relationshipTypeId: entity?.[`${entityName}TypeId`],
    longTitleFr: entity?.longTitleFr,
    longTitleEn: entity?.longTitleEn,
    startDate: entity?.startDate ? convertLocalToUTCDate(entity?.startDate) : null,
    endDate: entity?.endDate ? convertLocalToUTCDate(entity?.endDate) : null,
    past: entity?.past,
    future: entity?.future,
    organisationId: organisation?.id,
    userId: mode === 'add' ? person.id : undefined,
    id: mode === 'edit' ? entity.id : undefined,
    memberClass: entity?.memberClass,
  });

  const { register, getValues, watch, control, setValue, reset, formState } = useForm({
    defaultValues: populateForm(),
  });

  const {
    relationshipTypeId,
    organisationId,
    startDate,
    endDate,
    longTitleFr,
    past,
    future,
    memberClass,
  } = watch();

  useEffect(() => {
    if (mode === 'edit') {
      reset(populateForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (organisationId === undefined) {
      setMemberClasses([]);
      return;
    }
    fetchMemberClasses(organisationId)
      .then((data) => {
        setMemberClasses(data.data);
      })
      .catch((err, params) => {
        displayErrorToast(err, params);
      });
  }, [organisationId]);

  // Utils

  const resetTitles = useCallback(() => {
    setValue('longTitleFr', '');
    setValue('longTitleEn', '');
  }, [setValue]);

  const getOrganisationSelectValue = (org) =>
    organisation
      ? {
          label: `${org?.titleFr} (${org?.acronymFr})`,
          value: org?.id,
        }
      : null;

  const handleReset = (haveToReset = true) => {
    if (haveToReset) reset();
    onClose();
  };

  // Relationships

  useEffect(() => {
    dispatch(relationTypeFetchFunction());
  }, [dispatch, relationTypeFetchFunction]);

  const currentRelationShipType = useMemo(() => {
    if (isNaN(Number(relationshipTypeId))) {
      return null;
    }
    return relationshipTypes.filter((rt) => Number(rt.id) === Number(relationshipTypeId))[0];
  }, [relationshipTypeId, relationshipTypes]);

  const abstractRelationShipType = useMemo(() => {
    return currentRelationShipType?.abstract === true;
  }, [currentRelationShipType]);

  useEffect(() => {
    if (!currentRelationShipType) {
      setKeepLabel(false);
      setPreventKeepLabel(true);
      resetTitles();
    } else if (abstractRelationShipType) {
      setKeepLabel(false);
      setPreventKeepLabel(true);
    } else {
      setKeepLabel(true);
      setPreventKeepLabel(false);
    }
  }, [currentRelationShipType, abstractRelationShipType, resetTitles]);

  // Titles

  useEffect(() => {
    if (formState.dirtyFields.relationshipTypeId && keepLabel) {
      setValue('longTitleFr', currentRelationShipType.longNameFr);
      setValue('longTitleEn', currentRelationShipType.longNameEn);
    }
  }, [currentRelationShipType, setValue, keepLabel, formState.dirtyFields.relationshipTypeId]);

  // Dates

  useEffect(() => {
    if (formState.dirtyFields.startDate || formState.dirtyFields.endDate) {
      setValue('future', futureFlag({ startDate, endDate }));
      setValue('past', pastFlag({ startDate, endDate }));
    }
  }, [startDate, endDate, setValue, formState.dirtyFields]);

  const pastFlagFrozen = useMemo(
    () => isPastFlagFrozen({ startDate, endDate }),
    [startDate, endDate],
  );

  const futureFlagFrozen = useMemo(
    () => isFutureFlagFrozen({ startDate, endDate }),
    [startDate, endDate],
  );

  // Validation

  const formComplete = useMemo(() => {
    let complete = !!organisationId && !!relationshipTypeId;
    if (abstractRelationShipType) {
      complete = complete && !!longTitleFr;
    }
    return complete;
  }, [organisationId, relationshipTypeId, abstractRelationShipType, longTitleFr]);

  // Submission

  const commandCode = useMemo(
    () => (mode === 'add' ? `add_${entityName}_to_user` : `update_${entityName}_fields`),
    [mode, entityName],
  );
  const submitText = useMemo(() => (mode === 'add' ? 'Ajouter' : 'Sauvegarder'), [mode]);
  const modalTitle = useMemo(() => (mode === 'add' ? 'Ajouter' : 'Éditer'), [mode]);

  const handleAddRelationShip = () => {
    const formValues = getValues();
    dispatch(
      createCommand({
        code: commandCode,
        ...formValues,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        dispatch(updateOnePeople(data.command?.payload?.user));
        commandSuccess(data.command);
        handleReset(mode === 'add');
      })
      .catch(displayErrorToast);
  };

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Erreur</AlertTitle>
        <AlertDescription>Une erreur est survenue</AlertDescription>
      </Alert>
    );
  }

  if (pending) {
    return <Spinner />;
  }

  const relationshipClassLabel = relationshipClassLabels[entityName];
  const relationshipTypeLabel = relationshipTypelabels[entityName];

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={() => {
        dispatch(clear());
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modalTitle} {relationshipClassLabel} à {person?.displayName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert
            status="error"
            mb={5}
            display={errorCommand && errorCommand?.base ? 'flex' : 'none'}
          >
            <AlertIcon />
            <AlertTitle mr={2}>Erreur</AlertTitle>
            <AlertDescription>{errorCommand?.base}</AlertDescription>
          </Alert>
          <HStack alignItems="flex-start" spacing={5}>
            <VStack spacing={4} align="stretch" flex="0 0 48%">
              <Box>
                <FormLabel htmlFor="relationShipId" mb={0} requiredIndicator>
                  Statut
                </FormLabel>
                <Select {...register('relationshipTypeId')}>
                  <option value="">{relationshipTypeLabel}</option>
                  {relationshipTypes.map((relationshipType) => (
                    <option key={relationshipType.id} value={relationshipType.id}>
                      {capitalizeFirstLetter(relationshipType.longNameFr)}
                    </option>
                  ))}
                </Select>
              </Box>
              <Checkbox
                title="Conserver le libellé"
                aria-label="Conserver le libellé"
                defaultChecked={preventKeepLabel ? false : keepLabel}
                isChecked={preventKeepLabel ? false : keepLabel}
                disabled={preventKeepLabel}
                readOnly={preventKeepLabel}
                onChange={(event) => {
                  if (!preventKeepLabel) {
                    setKeepLabel(event.target.checked);
                  }
                }}
              >
                Conserver le libellé
              </Checkbox>
              <InputGroup size="sm">
                <InputLeftAddon fontFamily="mono">Fr</InputLeftAddon>
                <Input
                  placeholder="Titre du statut (français)"
                  isDisabled={keepLabel}
                  isRequired={abstractRelationShipType}
                  {...register('longTitleFr')}
                />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon fontFamily="mono">En</InputLeftAddon>
                <Input
                  placeholder="Titre du statut (anglais)"
                  isDisabled={keepLabel}
                  isRequired={abstractRelationShipType}
                  {...register('longTitleEn')}
                />
              </InputGroup>
              {entityName === 'membership' && (
                <Box>
                  <FormLabel htmlFor="relationShipId" mb={0} requiredIndicator>
                    Classe de membres
                  </FormLabel>
                  <Select {...register('memberClass')} disabled={memberClasses.length === 0}>
                    <option value="">Classe de membres</option>
                    {memberClasses.map((mc) => (
                      <option key={mc} value={mc}>
                        {capitalizeFirstLetter(mc)}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
            </VStack>

            <Grid gap={4}>
              <GridItem>
                <FormLabel htmlFor="startDate" mb={0}>
                  Du
                </FormLabel>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <DateChooser
                      ref={ref}
                      name={name}
                      defaultDate={value}
                      handleDateChange={onChange}
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <FormLabel htmlFor="endDate" mb={0}>
                  au
                </FormLabel>

                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <DateChooser
                      ref={ref}
                      name={name}
                      defaultDate={value}
                      handleDateChange={onChange}
                    />
                  )}
                />
              </GridItem>
              <GridItem gridColumn="1/span 2">
                <Flex justifyContent="space-between">
                  <Checkbox
                    title="Passée"
                    aria-label="Passée"
                    isDisabled={pastFlagFrozen}
                    isChecked={past}
                    {...register('past')}
                  >
                    Passée
                  </Checkbox>
                  <Checkbox
                    title="Future"
                    aria-label="Future"
                    isDisabled={futureFlagFrozen}
                    isChecked={future}
                    {...register('future')}
                  >
                    Future
                  </Checkbox>
                </Flex>
              </GridItem>
              <GridItem colSpan="2">
                <FormLabel htmlFor="organisationId" mb={0}>
                  {capitalizeFirstLetter(organisationTypeLabel)}
                </FormLabel>
                <Controller
                  control={control}
                  name="organisationId"
                  render={({ field: { onChange, name, ref } }) => {
                    return (
                      <OrganisationsAutocomplete
                        ref={ref}
                        name={name}
                        defaultValue={getOrganisationSelectValue(organisation)}
                        organisationType={organisationType}
                        handleSelectedItemsChange={onChange}
                        placeholder={`Choisissez une ${organisationTypeLabel}`}
                      />
                    );
                  }}
                />
              </GridItem>
            </Grid>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!formComplete}
            colorScheme="blue"
            mr={3}
            onClick={handleAddRelationShip}
          >
            {submitText}
          </Button>
          <Button variant="ghost" onClick={handleReset}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
