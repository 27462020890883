import React, { useRef } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';

export const RichTextEditor = ({ html, onSave, saveButtonEnabled, onContentChange }) => {
  const editorRef = useRef(null);
  const handleChange = () => {
    if (editorRef.current) {
      onContentChange();
    }
  };

  return (
    <>
      {saveButtonEnabled && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>N'oubliez pas d'enregistrer !</AlertTitle>
          <AlertDescription>Vos modifications en cours ne sont pas sauvegardées.</AlertDescription>
        </Alert>
      )}
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={html}
        onChange={handleChange}
        apiKey="k0uftnpt0fal7y6zypk2exngokkcf453l3nzti4zs9tfzmnd"
        init={{
          height: 500,
          menubar: false,
          setup: (editor) => {
            editor.ui.registry.addButton('saveButton', {
              text: 'Enregistrer',
              icon: 'save',
              onAction: () => onSave(editorRef.current.getContent()),
            });
          },
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help | saveButton',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  );
};
