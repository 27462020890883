import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

export const DirectValidationModal = ({
  messages,
  action,
  messageId,
  isOpen,
  onClose,
  handleDirectValidationCancel,
  handleDirectValidationConfirm,
}) => {
  const [messageToValidate, setMessageToValidate] = useState(null);
  useEffect(() => {
    const messagesById = messages.filter((m) => m.id === parseInt(messageId, 10));
    setMessageToValidate(messagesById[0]);
  }, [messages]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Confirmation {action === 'accept' ? "d'acceptation" : 'de refus'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="h3" fontWeight="bold">
              {' '}
              {messageToValidate?.titleFr}
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: messageToValidate?.textFr }} />
          </ModalBody>
          <ModalFooter>
            {action === 'accept' && (
              <Button colorScheme="green" mr={3} onClick={handleDirectValidationConfirm}>
                Confirmer l'acceptation
              </Button>
            )}
            {action === 'reject' && (
              <Button colorScheme="red" mr={3} onClick={handleDirectValidationConfirm}>
                Confirmer le refus
              </Button>
            )}
            <Button variant="ghost" onClick={handleDirectValidationCancel}>
              Abandonner
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
