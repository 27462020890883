/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as usersAPI from '../../api/users';

const user = JSON.parse(localStorage.getItem('user_sangam'));

const initialState = {
  user: user || {
    roles: [],
  },
  loggedIn: !!user,
  pending: false,
  error: null,
};

export const fetchCurrentUserProfile = createAsyncThunk(
  'users/fetchCurrentUserProfile',
  async (args, { rejectWithValue }) => {
    try {
      const response = await usersAPI.getCurrentUserProfile();
      localStorage.setItem('user_sangam', JSON.stringify(response.data.user));
      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  },
);

export const logout = createAsyncThunk('users/logout', async () => {
  localStorage.removeItem('user_sangam');
  await usersAPI.logout();
  return null;
});

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch current user profile
    builder
      .addCase(fetchCurrentUserProfile.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchCurrentUserProfile.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.pending = false;
        state.loggedIn = true;
      })
      .addCase(fetchCurrentUserProfile.rejected, (state, action) => {
        state.error = action.payload?.errors || action.error;
        state.pending = false;
        state.loggedIn = false;
      });

    // Logout
    builder
      .addCase(logout.fulfilled, () => {
        return initialState;
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload?.errors || action.error;
        state.pending = false;
        state.loggedIn = false;
      });
  },
});

export default currentUserSlice.reducer;
