import React from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { chakra, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { capitalizeFirstLetter } from '../../../../common/utils';
import { createCommand } from '../../../commands/commandsSlice';
import { updateOneUser } from '../../usersSlice';
import { ConfirmDialog } from '../../../../common/components/ConfirmDialog';
import { commandSuccess } from '../../../../common/utils/commands-utils';
import { displayErrorToast } from '../../../../common/utils/toasts-utils';

const roleTagColors = ['red', 'orange', 'yellow', 'green', 'teal', 'cyan', 'purple', 'pink'];

export const RoleTag = React.forwardRef(({ role, user, ...props }, ref) => {
  const dispatch = useDispatch();

  const handleRemoveRole = () => {
    dispatch(
      createCommand({
        code: 'remove_role_from_user',
        roleId: role.id,
        organisationId: role.organisationId,
        userId: user.id,
      }),
    )
      .then(unwrapResult)
      .then((data) => {
        if (data.command.finished) {
          dispatch(updateOneUser(data.command?.payload?.user));
        }
        commandSuccess(data.command);
      })
      .catch(displayErrorToast);
  };
  return (
    <Tag
      key={role.roleType.longNameFr}
      ref={ref}
      colorScheme={roleTagColors[role.roleType.id % 8]}
      variant="solid"
      {...props}
    >
      <TagLabel>{capitalizeFirstLetter(role.roleType.longNameFr)}</TagLabel>

      {role.organisation ? (
        <chakra.span whiteSpace="nowrap">&nbsp;{role.organisation.acronymFr}</chakra.span>
      ) : (
        ''
      )}
      {!role.auto && (
        <ConfirmDialog
          control={<TagCloseButton />}
          actionText="Supprimer"
          headerText="Suppression d'une permission"
          mainText={`Voulez-vous vraiment retirer la permission "${role.roleType.longNameFr}" à l'utilisateur·rice : ${user.displayName}`}
          callback={handleRemoveRole}
        />
      )}
    </Tag>
  );
});
