import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, useDisclosure } from '@chakra-ui/react';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { DataTable } from '../../../common/components/DataTable';
import { PageBody, PageContainer, PageHeader, PageTitle } from '../../../common/components/Page';
import { columns } from './columns';
import { fetchUsers, usersSelectors } from '../usersSlice';
import { RoleAddingModal } from './components/RoleAddingModal';
import { Metadata } from '../../../Metadata';

const initialValues = {
  query: '',
  page: 1,
  perPage: 50,
};

export const UsersIndex = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    meta: { total },
    pending,
  } = useSelector((state) => state.users);
  const users = useSelector((state) => usersSelectors.selectAll(state.users));
  const [editingUser, setEditingUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true,
      }),
    [location.search],
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters,
  });

  useEffect(() => {
    dispatch(fetchUsers(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true }),
    });
  }, [filters, history, location.pathname]);

  const debounced = useDebouncedCallback((value) => {
    setFilters(value);
  }, 500);

  const handlePageChange = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setFilters({ ...filters, perPage: newRowsPerPage });
  };

  const handleTextFiltering = (event) => {
    debounced({ ...filters, query: event.target.value, page: 1 });
  };

  const handleRolesModal = (user) => {
    setEditingUser(user);
    onOpen();
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Utilisateurs·rices</PageTitle>
      </PageHeader>
      <Metadata
        titleSuffix="Utilisateurs"
        descriptionSuffix="Liste des utilisateur·trices de l'application, avec leurs rôles."
      />
      <PageBody p="page.gutter">
        <Input
          placeholder="Rechercher"
          defaultValue={filters.query}
          mb={5}
          onInput={handleTextFiltering}
        />

        <DataTable
          columns={columns({ handleRolesModal })}
          data={users}
          selectableRows
          pagination
          paginationDefaultPage={Number(filters.page)}
          paginationServer
          paginationTotalRows={total}
          progressPending={pending}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
        <RoleAddingModal editingUser={editingUser} isOpen={isOpen} onClose={onClose} />
      </PageBody>
    </PageContainer>
  );
};
