import axios from 'axios';
import applyConverters from 'axios-case-converter';

const apiV1 = applyConverters(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
    withCredentials: true,
  }),
);

export { apiV1 };
