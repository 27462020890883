/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as streamsAPI from '../../api/streams';

export const fetchStreams = createAsyncThunk(
  'streams/fetchStreams',
  async (params = { page: 1, query: '' }) => {
    const response = await streamsAPI.getStreamsList(params);
    return response.data;
  },
);

export const fetchStreamById = createAsyncThunk(
  'streams/fetchStreamById',
  async (params = { limit: 10 }) => {
    const response = await streamsAPI.getStream(params);
    return response.data;
  },
);

export const deleteStreams = createAsyncThunk(
  'streams/deleteStreams',
  async (params, { rejectWithValue }) => {
    try {
      const response = await streamsAPI.deleteStreams({ params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

export const stopStreams = createAsyncThunk(
  'streams/stopStreams',
  async (params, { rejectWithValue }) => {
    try {
      const response = await streamsAPI.stopStreams({ params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  },
);

export const launchStream = createAsyncThunk(
  'streams/launchStreams',
  async (params = { source: '', type: '' }) => {
    const response = await streamsAPI.createStream(params);
    return response.data;
  },
);

export const streamsAdapter = createEntityAdapter();

export const streamsSlice = createSlice({
  name: 'streams',
  initialState: streamsAdapter.getInitialState({ pending: false, meta: {}, error: null }),
  reducers: {},
  extraReducers: {
    [fetchStreams.pending](state) {
      state.pending = true;
    },
    [fetchStreams.fulfilled](state, action) {
      state.pending = false;
      state.meta = action.payload.meta;
      streamsAdapter.setAll(state, action.payload.streams);
    },
    [fetchStreams.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [fetchStreamById.pending](state) {
      state.pending = true;
    },
    [fetchStreamById.fulfilled](state, action) {
      state.pending = false;
      streamsAdapter.setOne(state, action.payload.stream);
    },
    [fetchStreamById.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [launchStream.pending](state) {
      state.pending = true;
    },
    [launchStream.fulfilled](state) {
      state.pending = false;
    },
    [launchStream.rejected](state, action) {
      state.pending = false;
      state.error = action.payload?.errors || action.error;
    },
    [deleteStreams.pending](state) {
      state.pending = true;
    },
    [deleteStreams.fulfilled](state) {
      state.pending = false;
    },
    [deleteStreams.rejected](state, action) {
      state.error = action.payload?.errors || action.error;
      state.pending = false;
    },
    [stopStreams.pending](state) {
      state.pending = true;
    },
    [stopStreams.fulfilled](state) {
      state.pending = false;
    },
    [stopStreams.rejected](state, action) {
      state.error = action.payload?.errors || action.error;
      state.pending = false;
    },
  },
});

export const streamsSelectors = streamsAdapter.getSelectors();

export default streamsSlice.reducer;
