import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { Box } from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import { convertLocalToUTCDate } from '../../utils/date-utils';

registerLocale('fr', fr);

export const DateChooser = ({ defaultDate, handleDateChange, ...props }) => {
  const CalendarContainer = ({ className, children }) => {
    return (
      <div style={{ position: 'relative' }}>
        <Box className={className}>
          <div>{children}</div>
        </Box>
      </div>
    );
  };

  return (
    <DatePicker
      locale="fr"
      selected={defaultDate}
      onChange={(date) => handleDateChange(convertLocalToUTCDate(date))}
      calendarContainer={CalendarContainer}
      dateFormat="dd/MM/yyyy"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      isClearable
      placeholderText="--"
      {...props}
    />
  );
};
