import React from 'react';
import { IconButton, Link, Text, Tooltip, VisuallyHidden, Wrap, WrapItem } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { capitalizeFirstLetter } from '../../../common/utils';
import { RoleTag } from './components/RoleTag';
import { routes } from '../../../common/routes/routes';

export const columns = ({ handleRolesModal }) => [
  {
    name: '',
    cell: (user) => (
      <>
        <Text>{user.displayName}</Text>
        <Text as="small">{user.teachingDescription}</Text>
      </>
    ),
    sortable: true,
  },
  {
    name: 'Rôles',
    cell: (user) => (
      <Wrap
        as="div"
        align="center"
        w="100%"
        maxW="300px"
        css={{ 'ul, li, [class*=wrap]': { maxWidth: '100%' } }}
      >
        {user.roles?.length ? (
          <WrapItem>
            <Wrap as="div" shouldWrapChildren>
              {user.roles.map((role) => {
                if (role.organisation) {
                  return (
                    <Tooltip
                      hasArrow
                      label={`${capitalizeFirstLetter(role.roleType.longNameFr)} ${
                        role.organisation.titleFr
                      }`}
                      key={role.id}
                    >
                      <RoleTag role={role} user={user} key={role.id} />
                    </Tooltip>
                  );
                }
                return <RoleTag user={user} role={role} key={role.id} />;
              })}
            </Wrap>
          </WrapItem>
        ) : null}
        <WrapItem>
          <IconButton
            aria-label="Ajouter un rôle"
            size="sm"
            variant="action"
            rounded="full"
            icon={<MdAdd />}
            onClick={() => handleRolesModal(user)}
          >
            <VisuallyHidden>Ajouter un rôle</VisuallyHidden>
          </IconButton>
        </WrapItem>
      </Wrap>
    ),
  },
  {
    name: 'Fiche personne',
    button: true,
    cell: (user) => <Link href={`${routes.people}/${user.id}`}>Fiche personne</Link>,
  },
  {
    name: 'Dernière connexion',
    cell: (user) => <Text whiteSpace="nowrap">{user.lastSignInAt || 'Jamais'}</Text>,
    right: true,
    grow: 0.5,
  },
];
